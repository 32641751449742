import {
  Affix,
  Divider,
  PageHeader,
  Input,
  Modal,
  List,
  Typography,
  Collapse,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "aws-amplify";
import { ErrorHandlers } from "../../../../utils/index";
import _ from "lodash";
import "./qnaSections.scss";
import { useHistory } from "react-router";
import Highlighter from "react-highlight-words";
import latinize from 'latinize'
import DetailQuestionModal from './detailQuestionModal'

const { Panel } = Collapse;

function QuestionAndAnswerSections(props) {
  const { t, i18n } = useTranslation();
  const [listSections, setListSections] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [detailQuestionModalVisible, setDetailQuestionModalVisible] = useState({isOpen: false, selectedQuestion: null});

  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const history = useHistory();

  useEffect(() => {
    getListSection(pagination);
  }, []);

  const handleTableChange = (currPage, pageSize) => {
    const newPaginationPayload = {
      ...pagination,
      current: currPage,
      pageSize: pageSize,
    };
    setPagination(newPaginationPayload);
    if(searchString) {
      getSearchResult(
        newPaginationPayload,
        searchString
      );
    } else {
      getListSection(newPaginationPayload);
    }
  };

  const getListSection = (pagination) => {
    setLoadingList(true);
    API.get("unileverAPI", "/qna/sections", {
      queryStringParameters: {
        currentPage: pagination.current - 1,
        pageSize: pagination.pageSize,
        includeInactive: false,
      },
    })
      .then((res) => {
        setLoadingList(false);
        setListSections(res.result.data);
        setPagination({ ...pagination, total: res.result.total });
      })
      .catch((error) => {
        setLoadingList(false);
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const getSearchResult = (pagination, searchValue) => {
    setLoadingList(true);
    API.get("unileverAPI", "/qna/section/details", {
      queryStringParameters: {
        currentPage: pagination.current - 1,
        pageSize: pagination.pageSize,
        searchValue: searchValue,
        lang: i18n.language,
      },
    })
      .then((res) => {
        setLoadingList(false);
        setPagination({ ...pagination, total: res.result.total });
        setSearchResult(res.result.data);
      })
      .catch((error) => {
        setLoadingList(false);
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const resetPaging = () => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 100,
    });
  };

  const handleDetailQuestionModalShow = (question) => {
    setDetailQuestionModalVisible({
      isOpen: true,
      selectedQuestion: question
    })
  }

  const handleDetailQuestionModalCancel= () => {
    setDetailQuestionModalVisible({
      isOpen: false,
      selectedQuestion: null
    })
  }

  const handleSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearchString(value);
    if (value.length >= 3) {
      setIsSearching(true);
      getSearchResult(
        {
          ...pagination,
          current: 1,
        },
        value
      );
      resetPaging();
    } else if (value == "") {
      if (value == "") {
        setIsSearching(false);
        getListSection({
          ...pagination,
          current: 1,
        });
      }
    }
  }, 500);

  const currentDataSource = isSearching ? searchResult : listSections

  return (
    <div className="site-page-header-ghost-wrapper vendor-qna-sections-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t("vendor.qna.section.header")}
          subTitle=""
        ></PageHeader>
      </Affix>
      <Divider />
      <Input
        size="large"
        placeholder={t("vendor.qna.section.search.placeholder")}
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        allowClear
      />
      {isSearching && (
        <div className="search-result-lbl">{t('vendor.qna.section.searchResult')}</div>
      )}
      <List
        header={null}
        footer={null}
        size="large"
        className="list-sections"
        rowKey={(item) => item.uuid}
        bordered
        dataSource={currentDataSource}
        loading={loadingList}
        renderItem={(item) => {
          if (isSearching) {
            return (
              <Collapse bordered={false} defaultActiveKey={["1"]}>
                <Panel
                  header={ i18n.language === 'en' ? item.section_en : item.section_vi}
                  key="1"
                >
                  {item.qna.map((q) => {
                    return (
                      <List.Item
                        bordered
                        className="matching-qna-item"
                        onClick={() => handleDetailQuestionModalShow(q)}
                        className="search-item"
                      >
                        <div>
                          <p className="matching-question">
                            {t('vendor.qna.section.questionLabel')}
                            <Highlighter
                              highlightClassName="highlight-word"
                              searchWords={[searchString]}
                              autoEscape={false}
                              sanitize={latinize}
                              textToHighlight={i18n.language === 'en' ? q.question_en : q.question_vi}
                            />
                          </p>
                          <p className="matching-answer">
                            {t('vendor.qna.section.answerLabel')}
                            <Highlighter
                              highlightClassName="highlight-word"
                              searchWords={[searchString]}
                              autoEscape={false}
                              sanitize={latinize}
                              textToHighlight={i18n.language === 'en' ? q.answer_en : q.answer_vi}
                            />
                          </p>
                        </div>
                      </List.Item>
                    );
                  })}
                </Panel>
              </Collapse>
            );
          }
          return (
            <List.Item onClick={() => history.push(`/qna/${item.uuid}/list`)} className="section-title">
              <div>
                {i18n.language === "en" ? item.section_en : item.section_vi}
              </div>
            </List.Item>
          );
        }}
        pagination={
          currentDataSource.length > 0 ? {
            ...pagination,
            locale: { items_per_page: "" },
            showTotal: (total) => `${t("total")} ${pagination.total}`,
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: true,
            onChange: handleTableChange,
          } : null
        }
      />
      <DetailQuestionModal
        visible={detailQuestionModalVisible.isOpen}
        data={detailQuestionModalVisible.selectedQuestion}
        onCancel={handleDetailQuestionModalCancel}
      />
    </div>
  );
}

export default QuestionAndAnswerSections;
