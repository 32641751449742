import { Modal, Table } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { Helpers } from '../../../../utils'
import ColumnItem from './columnItem'
import './uploadVendor.scss'

function PreviewModal(props) {
  const { newVendorsData, vendorsForUpdatedData, availableNewVendorsData, availableVendorsForUpdatedData } = props
  const [isHavingErrorCell, setIsHavingErrorCell] = useState(false)
  const { t, i18n } = useTranslation()

  const columns = [
    {
      title: t('admin.uploadVendor.previewModal.columns.vendorSAPCode'),
      key: 'vd_sap_code',
      fixed: 'left',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'vd_sap_code')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadVendor.previewModal.columns.vendorName'),
      key: 'vd_name',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'vd_name')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadVendor.previewModal.columns.vendorTaxCode'),
      key: 'vd_tax_code',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'vd_tax_code', () => {
          // setIsHavingErrorCell(true)
        })
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadVendor.previewModal.columns.keyEmailAddress'),
      key: 'email',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'email')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadVendor.previewModal.columns.additionalEmailAddress'),
      key: 'additional_email',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'additional_email')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadVendor.previewModal.columns.bookingType'),
      key: 'booking_type',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'booking_type')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadVendor.previewModal.columns.apBooker'),
      key: 'ap_booker',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'ap_booker')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
  ]

  const hanldeResetModalParams = () => {
    setIsHavingErrorCell(false)
  }

  return (
    <Modal
      {...props}
      title={t('admin.uploadVendor.previewModal.title')}
      width={'80vw'}
      className="preview-vendor-modal"
      okButtonProps={{ disabled: availableNewVendorsData.concat(availableVendorsForUpdatedData).length == 0 }}
      afterClose={hanldeResetModalParams}
      okText={t('admin.uploadVendor.previewModal.yesBtn')}
      cancelText={t('admin.uploadVendor.previewModal.noBtn')}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="header-confirmation-msg">
        {t('admin.uploadVendor.previewModal.confirmHeaderMsg')}
      </div>
      <Table
        columns={columns}
        dataSource={newVendorsData.concat(vendorsForUpdatedData)}
        className="preview-vendor-table"
        rowClassName={(record, index) =>
          record.status === 'pending-update'
            ? 'updated-vendor-row'
            : 'new-vendor-row'
        }
        rowKey={() => uuidv4()}
        pagination={{
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        scroll={{ y: '50vh' }}
      />
      <div className="incorrect-records-msg">
        {t('admin.uploadVendor.previewModal.invalidFormatMsg')}
      </div>
    </Modal>
  )
}

export default PreviewModal
