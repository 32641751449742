import { Modal, Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { Helpers } from '../../utils/index'
import ColumnItem from './columnItem'
import './index.scss'

function PreviewModal(props) {
  const { data, originalPayload } = props
  const { t, i18n } = useTranslation()

  const columns = [
    {
      title: t("admin.viewInvoiceTracking.columnHeader.vendorId.title"),
      sorter: true,
      dataIndex: "vd_sap_code",
      fixed: "left",
      editable: true,
      children: [
        {
          width: 150,
          dataIndex: "vd_sap_code",
          fixed: "left",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "vd_sap_code");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.vendorName.title"),
      dataIndex: "vd_name",
      sorter: true,
      editable: true,
      children: [
        {
          width: 350,
          dataIndex: "vd_name",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "vd_name");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.vendorTaxCode.title'),
      dataIndex: "vd_tax_code",
      sorter: true,
      editable: true,
      children: [
        {
          width: 180,
          dataIndex: "vd_tax_code",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "vd_tax_code");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.invoiceNumber.title'),
      dataIndex: "invoice_number",
      sorter: true,
      editable: true,
      children: [
        {
          width: 180,
          dataIndex: "invoice_number",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "invoice_number");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.scanId.title'),
      dataIndex: "scan_id",
      sorter: true,
      editable: true,
      children: [
        {
          width: 220,
          dataIndex: "scan_id",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "scan_id");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.invoiceDate.title'),
      dataIndex: "invoice_date",
      sorter: true,
      editable: true,
      children: [
        {
          width: 280,
          dataIndex: "invoice_date",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "invoice_date");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue ? moment(cellData.displayValue).format("DD/MM/YYYY") : ""}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.dueDate.title'),
      dataIndex: "due_date",
      sorter: true,
      editable: true,
      children: [
        {
          width: 280,
          dataIndex: "due_date",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "due_date");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue ? moment(cellData.displayValue).format("DD/MM/YYYY") : ""}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.invoiceAmount.title'),
      dataIndex: "invoice_amount",
      inputType: "number",
      sorter: true,
      editable: true,
      children: [
        {
          width: 200,
          dataIndex: "invoice_amount",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "invoice_amount");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.currency.title'),
      dataIndex: "currency",
      sorter: true,
      editable: true,
      children: [
        {
          width: 150,
          dataIndex: "currency",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "currency");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.assignedTo.title'),
      dataIndex: "assigned_to",
      sorter: true,
      editable: true,
      children: [
        {
          width: 100,
          dataIndex: "assigned_to",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "assigned_to");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.invoiceStatus.title'),
      dataIndex: "invoice_status",
      sorter: true,
      editable: true,
      children: [
        {
          width: 200,
          dataIndex: "invoice_status",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "invoice_status_id");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={originalPayload.invoice_status}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: "Reason",
      dataIndex: "invoice_reason",
      sorter: true,
      editable: true,
      children: [
        {
          width: 200,
          dataIndex: "invoice_reason",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "invoice_reason_id");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={originalPayload.invoice_reason}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.statusDate.title'),
      dataIndex: "status_date",
      sorter: true,
      editable: true,
      children: [
        {
          width: 280,
          dataIndex: "status_date",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "status_date");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue ? moment(cellData.displayValue).format("DD/MM/YYYY") : ""}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.poNumber.title'),
      dataIndex: "po_number",
      editable: true,
      sorter: true,
      children: [
        {
          width: 120,
          dataIndex: "po_number",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "po_number");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.notes.title'),
      dataIndex: "notes",
      sorter: true,
      editable: true,
      children: [
        {
          width: 200,
          dataIndex: "notes",
          render(text, record) {
            const cellData = Helpers.generateCellDataOfPreviewInvoice(record, "notes");
            return {
              props: {
                className: cellData.cellStyle,
              },
              children: (
                <ColumnItem
                  cellData={cellData.displayValue}
                  type={record.status}
                />
              ),
            };
          },
        },
      ],
    }
  ]

  return (
    <Modal
      {...props}
      title={t('admin.viewInvoiceTracking.editItemModal.title')}
      width={'80vw'}
      className="preview-invoice-modal"
      okText={t('admin.uploadInvoice.previewModal.yesBtn')}
      cancelText={t('admin.uploadInvoice.previewModal.noBtn')}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="header-confirmation-msg">
        {t('admin.viewInvoiceTracking.editItemModal.confirmHeaderMsg')}
      </div>
      <div className="header-confirmation-msg">
        {t('admin.viewInvoiceTracking.editItemModal.confirmHeaderMsg2')}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        className="preview-duplicated-invoice-table"
        rowKey={() => uuidv4()}
        pagination={{
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        scroll={{ y: '50vh' }}
      />
    </Modal>
  )
}

export default PreviewModal
