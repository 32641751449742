import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Parser from "html-react-parser";
import "./qnaSections.scss";

function DetailQuesionModal(props) {
  const { t, i18n } = useTranslation();
  const { data } = props;
  return (
    <Modal
      {...props}
      width={"70vw"}
      bodyStyle={{ minHeight: "50vh", maxHeight: "70vh", overflow: "auto"}}
      footer={null}
      maskClosable={false}
      title={t("vendor.qna.section.detailQuestionModal.title")}
      className="detail-question-modal"
    >
      <div className="matching-question">
        {data &&
          Parser(
            i18n.language === "en"
              ? data?.question_en_full
              : data?.question_vi_full
          )}
      </div>
      <div className="matching-answer" id="content-wrapper">
        {data &&
          Parser(
            i18n.language === "en" ? data?.answer_en_full : data?.answer_vi_full
          )}
      </div>
    </Modal>
  );
}

export default DetailQuesionModal;
