import {
  Affix,
  Descriptions,
  Divider,
  PageHeader,
  Radio,
  Input,
  Button,
  Space,
  Modal,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChangeKeyEmailModal from "./changeKeyEmailModal";
import ChangeAdditionalEmailModal from "./changeAdditionalEmailModal";
import { API } from "aws-amplify";
import _ from "lodash";
import { ErrorHandlers } from "../../../../utils/index";

import "./Settings.scss";

function Settings({ language, updateLanguage }) {
  const { t, i18n } = useTranslation();
  const [changeKeyEmailModalVisible, setChangeKeyEmailModalVisible] =
    useState(false);
  const [
    changeAdditionalEmailModalVisible,
    setChangeAdditionalEmailModalVisible,
  ] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    API.get("unileverAPI", "/accounts/getCurrentEmail")
      .then((res) => {
        const { username, additionalEmail, email } = res;
        setUserData({
          username,
          additionalEmail,
          email: email,
        });
      })
      .catch((error) => {
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const onUpdateKeyEmail = (payload) => {
    if (payload.emailToUpdate === userData.email) {
      Modal.error({
        centered: true,
        content: t("vendor.settings.keyEmail.changeModal.duplicatedEmailMsg"),
      });
    } else {
      handleChangeKeyEmailModalCancel();
      setIsLoading(true);
      API.post("unileverAPI", "/accounts/submitChangeMail", {
        body: { ...payload, username: userData.username },
      })
        .then((res) => {
          setIsLoading(false);
          Modal.success({
            centered: true,
            content: t("vendor.settings.keyEmail.changeSuccessfully"),
          });
        })
        .catch((error) => {
          setIsLoading(false);
          const errorCode = _.get(error, "response.data.errorCode", undefined);
          const errorMessage = errorCode
            ? ErrorHandlers.generateErrorMessage(
                error,
                t(`vendor.errorCode.changeEmail.${errorCode}`)
              )
            : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
          Modal.error({
            centered: true,
            content: errorMessage,
          });
        });
    }
  };

  const onUpdateAdditionalEmail = (payload) => {
    if (payload.emailToUpdate === userData.additionalEmail) {
      Modal.error({
        centered: true,
        content: t("vendor.settings.keyEmail.changeModal.duplicatedEmailMsg"),
      });
    } else {
      handleChangeAdditionalEmailModalCancel();
      setIsLoading(true);
      API.post("unileverAPI", "/accounts/submitChangeMail", {
        body: { ...payload, username: userData.username },
      })
        .then((res) => {
          setIsLoading(false);
          Modal.success({
            centered: true,
            content: t("vendor.settings.additionalEmail.changeSuccessfully"),
          });
        })
        .catch((error) => {
          setIsLoading(false);
          const errorCode = _.get(error, "response.data.errorCode", undefined);
          const errorMessage = errorCode
            ? ErrorHandlers.generateErrorMessage(
                error,
                t(`vendor.errorCode.changeEmail.${errorCode}`)
              )
            : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
          Modal.error({
            centered: true,
            content: errorMessage,
          });
        });
    }
  };

  const handleChangeKeyEmailModalShow = () => {
    setChangeKeyEmailModalVisible(true);
  };

  const handleChangeKeyEmailModalCancel = () => {
    setChangeKeyEmailModalVisible(false);
  };

  const handleChangeAdditionalEmailModalShow = () => {
    setChangeAdditionalEmailModalVisible(true);
  };

  const handleChangeAdditionalEmailModalCancel = () => {
    setChangeAdditionalEmailModalVisible(false);
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t("admin.settings.title")}
          subTitle=""
        ></PageHeader>
      </Affix>
      <Divider />
      {isLoading ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <Descriptions
          size="middle"
          title=""
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          loading={true}
        >
          <Descriptions.Item label={t("admin.settings.languageLabel")}>
            <Radio.Group
              size="small"
              buttonStyle="solid"
              value={language}
              onChange={(e) => updateLanguage(e.target.value)}
            >
              <Radio.Button key="en" value="en">
                {t("admin.settings.englishLabel")}
              </Radio.Button>
              <Radio.Button key="vn" value="vi">
                {t("admin.settings.vietnameseLabel")}
              </Radio.Button>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item label={t("vendor.settings.keyEmail.title")}>
            <Space
              direction="horizontal"
              align="center"
              className="key-email-right-col"
            >
              <Input
                disabled
                placeholder={t("vendor.settings.keyEmail.placeholder")}
                value={userData?.email}
              />
              <Button onClick={handleChangeKeyEmailModalShow}>
                {t("vendor.settings.keyEmail.changeBtn")}
              </Button>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={t("vendor.settings.additionalEmail.title")}>
            <Space
              direction="horizontal"
              align="center"
              className="key-email-right-col"
            >
              <Input
                disabled
                placeholder={t("vendor.settings.additionalEmail.placeholder")}
                value={userData?.additionalEmail}
              />
              <Button onClick={handleChangeAdditionalEmailModalShow}>
                {t("vendor.settings.additionalEmail.changeBtn")}
              </Button>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      )}
      <ChangeKeyEmailModal
        visible={changeKeyEmailModalVisible}
        onCancelCb={handleChangeKeyEmailModalCancel}
        onSubmitCb={onUpdateKeyEmail}
      />
      <ChangeAdditionalEmailModal
        visible={changeAdditionalEmailModalVisible}
        onCancelCb={handleChangeAdditionalEmailModalCancel}
        onSubmitCb={onUpdateAdditionalEmail}
      />
    </div>
  );
}

export default Settings;
