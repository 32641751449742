import { SettingOutlined, UserOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

function LeftMenu() {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const getOpenKeys = (pathname) => {
    switch (pathname) {
      case '/': return ['vendors'];
      case '/vendors/upload': return ['vendors'];
      case '/vendors/missing-invoice-request': return ['vendors', ['missing-invoice-request']];
      default: return [];
    }
  }
  return (
    <>
      <div className="logo" />
      <Menu
        inlineIndent="12"
        theme="dark"
        selectedKeys={[location.pathname == '/' ? '/invoice/tracking': location.pathname]}
        defaultOpenKeys={getOpenKeys(location.pathname)}
        mode="inline"
      >
        <Menu.Item key="/qna/section" icon={<QuestionCircleOutlined />} onClick={() => history.push('/qna/section')}>
          {t('vendor.leftMenu.qna')}
        </Menu.Item>
        <Menu.Item key="/invoice/tracking" icon={<SettingOutlined />} onClick={() => history.push('/invoice/tracking')}>
          {t('vendor.leftMenu.invoiceTracking')}
        </Menu.Item>
        <Menu.Item
            onClick={() => history.push('/vendors/missing-invoice-request')}
            key="/vendors/missing-invoice-request"
            icon={<UserOutlined />}
          >
          {t('vendor.leftMenu.missingInvoiceRequest')}
          </Menu.Item>
        <Menu.Item key="/settings" icon={<SettingOutlined />} onClick={() => history.push('/settings')}>
          {t('admin.leftMenu.settings')}
        </Menu.Item>
      </Menu>
    </>
  )
}

export default LeftMenu
