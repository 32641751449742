import _ from "lodash";
import { useEffect, useRef } from "react";

export function useUtils() {
  
    const strippedString = (str) =>
      typeof str === "string" ? str.replace(/(<([^>]+)>)/gi, "") : str;

    function htmlDecode(input) {
      var e = document.createElement("textarea");
      e.innerHTML = input;
      // handle case of empty input
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
  
    /**
     * 
     * @param {*} str  HTML string 
     * @param {*} identity  identity is string, filer your wanted url : abc.com or example.com
     */
    function extractLinkFromHtmlString(str,identity) {
      let id = Date.now();
      var e = document.createElement("div");
      e.setAttribute("id", id);
      e.innerHTML = str;

      var elementImg = e.getElementsByTagName("img");
      var elementA = e.getElementsByTagName("a");
  
      let arr = [];
      for (let i = 0; i < elementImg.length; i++) {
          arr.push(elementImg[i].getAttribute("src"));
        }
      for (let i = 0; i < elementA.length; i++) {
        arr.push(elementA[i].getAttribute("href"));
      }
      return arr.filter((img) => img.includes(identity));
    }
    function arr_diff(a1, a2) {
      var a = [],
        diff = [];
  
      for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
      }
  
      for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
          delete a[a2[i]];
        } else {
          a[a2[i]] = true;
        }
      }
  
      for (var k in a) {
        diff.push(k);
      }
  
      return diff;
    }
    function sort2fieldAscending(arr, path1, path2, text) {
      return arr.sort(function (a, b) {
        if (_.get(a, path1) == _.get(b, path1)) {
          // text is only important when qna_section.order_number are the same
          if (_.get(a, text) == _.get(b, text)) {
            // qna order is only important when text are the same
            return _.get(a, path2) - _.get(b, path2);
          }
          return _.get(a, text) > _.get(b, text) ? 1 : -1;
        }
        return _.get(a, path1) - _.get(b, path1);
    
      });
    }
    function truncString(str, n){
      return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
    };

    /**
     * This will logs all changing props.
     * helping with optimizing props passed to child components
     */
    function useTraceUpdate(location = '',props) {
        
        const prev = useRef(props);
        useEffect(() => {
          const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
              ps[k] = [prev.current[k], v];
            }
            return ps;
          }, {});
          if (Object.keys(changedProps).length > 0) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // dev code
            } else {
                // production code
            }
          }
          prev.current = props;
        });
      }

      /***
       * 
       * Strings in the editor are encoded into XML.
       * In order to replace string in the editot, we need to decode them first
       */
      function escapeXml(unsafe) {
        return unsafe.replace(/[<>&'"]/g, function (c) {
            switch (c) {
                case '<': return '&lt;';
                case '>': return '&gt;';
                case '&': return '&amp;';
                case '\'': return '&apos;';
                case '"': return '&quot;';
            }
        });
      
    }

    function getQueryVariable(variable)
    {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
            }
            return(false);
    }

    function customEncode (string) {
      return escape(btoa(string))
    }
    function customDecode (string) {
      return atob(unescape(string))
    }
    let findDuplicatesObjectsBy = (objectArr, attrList = []) => {
      let objectArrButOnlyRequireAttr = objectArr;
      let getUniqKey = (attr, attributeList, obj) => attr + '_' + _.map(attributeList, key => _.get(obj, key)).join('_');
      const extractDuplicates =  (array, attributeList) => {
        // create a dictionary 
        const lookup = array.reduce((a, e) => {
          _.each(attributeList, attr => {
            //get uniq value by object key        
            let uniqKey= getUniqKey(attr, attributeList, e);
            a[uniqKey + e[attr]] = ++a[uniqKey + e[attr]] || 0;        
          })
          return a;
        }, {});
        let duplicates = array.filter(obj => {
          let predicate = attributeList.reduce((final, attr) => {
            let uniqKey= getUniqKey(attr, attributeList, obj);
            return final && lookup[uniqKey + obj[attr]];
          })
          return predicate
        });
        return {duplicates}
      }
      let {duplicates}  = extractDuplicates(objectArrButOnlyRequireAttr, attrList);
      let uniqueDuplicated = _.uniqBy(duplicates, v => _.map(attrList, key=> v[key]).join())
      let uniqueNotDuplicated = _.difference(objectArrButOnlyRequireAttr, duplicates)
      return {uniqueDuplicated, uniqueNotDuplicated, duplicates}
    }
    return { strippedString, htmlDecode, extractLinkFromHtmlString, arr_diff , truncString, sort2fieldAscending, useTraceUpdate, escapeXml, getQueryVariable, customEncode, customDecode, findDuplicatesObjectsBy};
  }