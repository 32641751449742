/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:cb0887ec-81e3-4a84-8b3a-fa200d008360",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_c72G6zL7S",
    "aws_user_pools_web_client_id": "1uuv0c145kcn2a4idb6jtle656",
    "oauth": {},
    "aws_user_files_s3_bucket": "unileverbucket104909-prodenv",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "unileverAPI",
            "endpoint": "https://yhevlyu46m.execute-api.ap-southeast-1.amazonaws.com/prodenv",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
