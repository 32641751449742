import { DatabaseOutlined, QuestionCircleOutlined, ReconciliationOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

function LeftMenu() {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const getOpenKeys = (pathname) => {
    switch (pathname) {
      case '/vendors/upload': return ['vendors'];
      case '/invoice/status/upload': return ['vendors'];
      case '/invoice/reason/upload': return ['vendors'];
      case '/': return ['/invoice/tracking'];
      case '/invoice/tracking/upload': return ['/invoice/tracking'];
      case '/invoice/tracking': return ['/invoice/tracking'];
      default: return [];
    }
  }
  return (
    <>
      <div className="logo" />
      <Menu
        inlineIndent="12"
        theme="dark"
        selectedKeys={[location.pathname == '/' ? '/invoice/tracking/upload': location.pathname]}
        defaultOpenKeys={getOpenKeys(location.pathname)}
        mode="inline"
      >
        {/* <Menu.Item key="/qna/section" icon={<QuestionCircleOutlined />} onClick={() => history.push('/qna/section')}>
          {t('admin.leftMenu.qna')}
        </Menu.Item> */}
        <Menu.SubMenu
          key="questionAndAnswer"
          icon={<QuestionCircleOutlined />}
          title={`${t('admin.leftMenu.questionAndAnswer')}`}
        >
          <Menu.Item
            onClick={() => history.push('/qna/section')}
            key="/qna/section"
          >
            {t('admin.leftMenu.qaSection')}
          </Menu.Item>
          <Menu.Item
            onClick={() => history.push('/qna/list')}
            key="/qna/list"
          >
            {t('admin.leftMenu.qaList')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="/invoice/tracking"
          icon={<ReconciliationOutlined />}
          title={`${t('admin.leftMenu.invoiceTracking')}`}
        >
          <Menu.Item key="/invoice/tracking/upload" onClick={() => history.push('/invoice/tracking/upload')}>
            {t('admin.leftMenu.invoiceTracking.uploadInvoice')}
          </Menu.Item>
          <Menu.Item key="/invoice/tracking" onClick={() => history.push('/invoice/tracking')}>
            {t('admin.leftMenu.invoiceTracking.viewInvoice')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="/invoice/missing" icon={<SearchOutlined />} onClick={() => history.push('/invoice/missing')}>
          {t('admin.leftMenu.invoiceMissing')}
        </Menu.Item>
        <Menu.SubMenu
          key="vendors"
          icon={<DatabaseOutlined />}
          title={`${t('admin.leftMenu.masterDataManagement')}`}
        >
          <Menu.Item
            onClick={() => history.push('/vendors/upload')}
            key="/vendors/upload"
          >
            {t('admin.leftMenu.vendorMasterData')}
          </Menu.Item>
          <Menu.Item
            onClick={() => history.push('/invoice/status/upload')}
            key="/invoice/status/upload"
          >
            {t('admin.leftMenu.invoiceStatusMasterData')}
          </Menu.Item>
          <Menu.Item
            onClick={() => history.push('/invoice/reason/upload')}
            key="/invoice/reason/upload"
          >
            {t('admin.leftMenu.invoiceReasonMasterData')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="/settings" icon={<SettingOutlined />} onClick={() => history.push('/settings')}>
          {t('admin.leftMenu.settings')}
        </Menu.Item>
      </Menu>
    </>
  )
}

export default LeftMenu
