import { DownloadOutlined } from '@ant-design/icons'
import { Affix, Button, Divider, Modal, PageHeader, Space } from 'antd'
import { API, Storage } from 'aws-amplify'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropZone from './dropZone'
import PreviewModal from './previewModal'
import './uploadMissingInvoiceRequest.scss'


function UploadMissingInvoiceRequest({ user }) {
  const [fileList, setFileList] = useState([])
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [newInvoiceStatusData, setNewInvoiceStatusData] = useState([])
  const [invoiceStatusForUpdatedData, setInvoiceStatusForUpdatedData] = useState([])
  const [availableNewInvoiceStatusData, setAvailableNewInvoiceStatusData] = useState([])
  const [
    availableInvoiceStatusForUpdatedData,
    setAvailableInvoiceStatusForUpdatedData,
  ] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (fileList.length > 0) {
      handleUpload()
    }
  }, [fileList])

  const handleSelectFile = (files) => {
    setFileList(files)
  }

  const submitRequest = () => {
    if (!submitting) {
      setSubmitting(true)
      const request = {
        body: {
          username: user.username,
          type: 'invoice_missing'
        },
      }
      if (availableNewInvoiceStatusData.length > 0) {
        //transform 
        request.body.newItems = availableNewInvoiceStatusData.map(invoice => {
          return {
            vendor_id: invoice.vendor_id.value,
            vendor_name: invoice.vendor_name.value,
            vendor_tax_code: invoice.vendor_tax_code.value,
            invoice_number: invoice.invoice_number.value,
            scan_id: invoice.scan_id.value,
            invoice_date: invoice.invoice_date.value,
            due_date: invoice.due_date.value,
            invoice_amount: invoice.invoice_amount.value,
            currency: invoice.currency.value,
            assigned_to: invoice.assigned_to.value,
            invoice_status_id: null,
            invoice_reason_id: null,
            status_date: invoice.status_date.value,
            po_number: invoice.po_number.value,
            notes: invoice.notes.value,
          }
        });
      }
      // if (availableInvoiceStatusForUpdatedData.length > 0) {
      //   request.body.itemsForUpdated = availableInvoiceStatusForUpdatedData
      // }
      return API.post('unileverAPI', '/invoices/tracking/submitInvoice', request)
        .then(() => {
          Modal.success({
            centered: true,
            content: t('vendor.uploadMissingInvoice.submitSuccessfullyMsg'),
          });
          setIsFileUploaded(false)
          setSubmitting(false)
        })
        .catch((err) => {
          setSubmitting(false)
          Modal.error({
            centered: true,
            content: <div id='dangerhtml' dangerouslySetInnerHTML={{__html:`${getCustomErrorSubmitMessage(err)}`}}/>,
          });
        })
    }
  }
  const getCustomErrorSubmitMessage = (err) => {
    let message = _.get(err, 'response.data.message');
    let final;
    if (_.includes(message, 'Missing master data')) {
      final = t('vendor.uploadMissingInvoiceRequest.uploadFailedMsg.missingMasterData');
    }
    else {
      final = t('admin.uploadInvoiceStatus.submitFailedMsg');
    }
    return final
  }
  
  const columns = {
    missingInvoice: [
      'vendor_id',
      'vendor_name',
      'vendor_tax_code',
      'scan_id',
      'assigned_to',
      'invoice_status_id',
      'invoice_reason_id',
      'invoice_status',
      'invoice_reason',
      'status_date',
      'invoice_number',
      'invoice_date',
      'due_date',
      'invoice_amount',
      'currency',
      'po_number',
      'vendor_id',
      'vendor_name',
      'vendor_tax_code',
      'scan_id',
      'assigned_to',
      'invoice_status_id',
      'invoice_reason_id',
      'invoice_status',
      'invoice_reason',
      'status_date',
      'notes',
    ]
  }
  const mapData = (item, columns, status = 'new') => {
    let newItem = {};
    _.each(columns, key => {

      // {value: "", validate: "error"}
      if (typeof item[key] === 'object' && _.get(item[key], 'validate') === 'error') {
        newItem[key] = item[key]
      }
      else {
        // item[key] = string 
        newItem[key] = {
          value: item[key],
          status: status
        }
      }
    })
    return newItem;
  }

  const getUserAttribute = () => {
    let userAttribute;
    if (user) {
      if (user.attributes) {
        userAttribute = user.attributes;
      }
      if (user.challengeParam && user.challengeParam.userAttributes) {
        userAttribute = user.challengeParam.userAttributes;
      }
    } else {
      userAttribute = null;
    }
    return { userAttribute }
  }
  const handleUpload = () => {
    setUploading(true)
    Storage.put(`vendorMasterData/${fileList[0].name}`, fileList[0], {
      contentType: fileList[0].type,
      level: 'public',
    })
      .then((result) => {

        let { userAttribute } = getUserAttribute();

        let vendor = {
          vendor_id: user.username,
          vendor_name: _.get(userAttribute, 'custom:vd_name'),
          vendor_tax_code: _.get(userAttribute, 'custom:vd_tax_code'),
        };
        const request = {
          body: {
            bucket: Storage._config.AWSS3.bucket,
            key: `public/${result.key}`,
            type: 'invoice_missing',
            vendor: vendor,
            lang: i18n.language
          },
        }
        return API.post(
          'unileverAPI',
          '/invoices/missing/validate',
          request
        )
      })
      .then((result) => {
        setFileList([])
        setUploading(false)
        let { newItems, itemsForUpdated } = result.data
        newItems = _.map(newItems, item => mapData(item, columns.missingInvoice, 'new'))
        itemsForUpdated = _.map(itemsForUpdated, item => mapData(item, columns.missingInvoice, 'updated'))

        setNewInvoiceStatusData(_.size(newItems) !== 0 ? newItems : []);
        setInvoiceStatusForUpdatedData(_.size(itemsForUpdated) !== 0 ? itemsForUpdated : []);
        let aviNewInvoiceStatusData = [];
        let aviInvoiceStatusForUpdateData = [];
        if (newItems && newItems.length !== 0) {
          for (let item of newItems) {
            if (checkValidRow(item)) {
              aviNewInvoiceStatusData.push(item);
            }
          }
          setAvailableNewInvoiceStatusData(aviNewInvoiceStatusData)
        }
        if (itemsForUpdated && itemsForUpdated.length !== 0) {
          for (let item of itemsForUpdated) {
            if (checkValidRow(item)) {
              aviInvoiceStatusForUpdateData.push(item)
            }
          }
          setAvailableInvoiceStatusForUpdatedData(aviInvoiceStatusForUpdateData)
        } else {
          setAvailableInvoiceStatusForUpdatedData([])

        }
        setIsFileUploaded(true)
      })
      .catch((err) => {
        setUploading(false)
        Modal.error({
          centered: true,
          content: <div dangerouslySetInnerHTML={{ __html: `${getCustomErrorMessage(err)}` }} />,
        });
      })
  }

  const getCustomErrorMessage = (err) => {
    let message = _.get(err, 'response.data.message');
    let final;
    if (_.includes(message, 'Invalid header value or order of column') ||
      _.includes(message, 'Invalid amount of column')) {
      final = t('vendor.uploadMissingInvoice.uploadFailedMsg.wrongHeader');
    }
    else if (_.includes(message, 'duplicate(s) occurred')) {
      message = message.replace('Error: ', '');
      let jsonMes = JSON.parse(message);
      let uniqueDuplicate = _.get(jsonMes, 'uniqueDuplicate');
      let list =
        `<div> 
        <br/>
        ${uniqueDuplicate.map((data, index) => `<strong> ${index + 1}. Invoice Number : ${_.get(data, 'invoice_number')} - Invoice Date: ${_.get(data, 'invoice_date')} </strong>`).join(' <br/>')}
      </div>`
      final = t('vendor.uploadMissingInvoice.uploadFailedMsg.duplicateOccured', {
        list: list,
      });
    }
    else {
      final = t('vendor.uploadMissingInvoice.uploadFailedMsg');
    }
    return final
  }

  const checkValidRow = (item) => {
    if (
      ((item.vendor_id && !item.vendor_id.validate) || !item.vendor_id) &&
      ((item.vendor_name && !item.vendor_name.validate) || !item.vendor_name) &&
      ((item.vendor_tax_code && !item.vendor_tax_code.validate) || !item.vendor_tax_code) &&
      ((item.invoice_number && !item.invoice_number.validate) || !item.invoice_number) &&
      ((item.scan_id && !item.scan_id.validate) || !item.scan_id) &&
      ((item.invoice_date && !item.invoice_date.validate) || !item.invoice_date) &&
      ((item.due_date && !item.due_date.validate) || !item.due_date) &&
      ((item.invoice_amount && !item.invoice_amount.validate) || !item.invoice_amount) &&
      ((item.currency && !item.currency.validate) || !item.currency) &&
      ((item.assigned_to && !item.assigned_to.validate) || !item.assigned_to) &&
      ((item.invoice_status && !item.invoice_status.validate) || !item.invoice_status) &&
      ((item.invoice_reason && !item.invoice_reason.validate) || !item.invoice_reason) &&
      ((item.status_date && !item.status_date.validate) || !item.status_date) &&
      ((item.po_number && !item.po_number.validate) || !item.po_number)
    ) {
      return true
    } else {
      return false
    }
  }
  const downloadTemplate = async () => {
    setDownloadLoading(true)
    let apiName = 'unileverAPI'
    let path = '/invoices/missing/download'
    let params = {
      responseType: 'arrayBuffer',
      response: true,
      queryStringParameters: {
        lang: i18n.language
      }
    }
    await API.get(apiName, path, params)
      .then((response) => {
        var raw = window.atob(response.data)
        var uInt8Array = new Uint8Array(raw.length)
        for (var i = 0; i < raw.length; ++i) {
          uInt8Array[i] = raw.charCodeAt(i)
        }
        const link = document.createElement('a')
        const blob = new Blob([uInt8Array], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `InvoiceMissing_${Date.now()}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setDownloadLoading(false)
      })
      .catch((error) => {
        Modal.error({
          centered: true,
          content: t('admin.uploadInvoiceStatus.downloadFailedMsg'),
        });
        setDownloadLoading(false)
      })
  }

  return (
    <div className="uploadInvoiceStatus-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t('vendor.uploadMissingInvoice.title')}
        ></PageHeader>
      </Affix>
      <Divider />
      <DropZone
        selectFileCallBack={(file) => handleSelectFile(file)}
        isUploading={uploading}
      />
      <Space direction="horizontal" align="end" className="action-buttons">
        <Button
          loading={downloadLoading}
          onClick={downloadTemplate}
          size="large"
          type="primary"
        >
          <DownloadOutlined /> {t('admin.uploadInvoiceStatus.downloadTemplateBtn')}
        </Button>
      </Space>
      <PreviewModal
        visible={isFileUploaded}
        onOk={submitRequest}
        onCancel={() => setIsFileUploaded(false)}
        newInvoiceStatusData={newInvoiceStatusData}
        invoiceStatusForUpdatedData={invoiceStatusForUpdatedData}
        availableNewInvoiceStatusData={availableNewInvoiceStatusData}
        availableInvoiceStatusForUpdatedData={availableInvoiceStatusForUpdatedData}
      />
    </div>
  )
}

export default UploadMissingInvoiceRequest
