import { Form, Input, InputNumber, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  handleSave,
  record,
  ...restProps
}) => {
  const { t, i18n } = useTranslation();

  const generateInputNode = () => {
    if (dataIndex === "is_active") {
      return (
        <Switch
          defaultChecked={record.is_active === 1}
          onChange={(isChecked) => {
            if (handleSave) {
              handleSave(isChecked);
            }
          }}
        />
      );
    }
    if (dataIndex === "order_number") {
      return (
        <InputNumber
          style={{ width: "100%" }}
          step="1"
          stringMode
          formatter={(value) => {
            if (value >= 0) {
              return Math.round(value);
            }
            return Math.abs(value);
          }}
        />
      );
    }
    if (dataIndex === "section_en" || dataIndex === "section_vi") {
      return <Input style={{ width: "100%" }} maxLength={500} />;
    }
    return <Input style={{ width: "100%" }} />;
  };

  const generateNonEditableField = () => {
    return children;
  };
  const generateFormItem = () => {
    return (
      <Form.Item
        enableReinitialize
        name={dataIndex}
        style={{
          margin: 0,
        }}
        rules={[
          {
            required: true,
            message: t("admin.qna.addSectionModal.requiredMsg"),
          },
        ]}
        shouldUpdate
      >
        {generateInputNode()}
      </Form.Item>
    );
  };
  return (
    <td {...restProps}>
      {editing ? generateFormItem() : generateNonEditableField()}
    </td>
  );
};

export default EditableCell;
