
export default class MyUploadAdapter {
    constructor(loader, storage, namePrefix, t, handleMessageSvc) {
      this.loader = loader;
      this.storage = storage;
      this.namePrefix = namePrefix;
      this.t = t;
      this.handleMessageSvc = handleMessageSvc;
    }
  
    upload() {
      return new Promise((resolve, reject) => {
        this._sendRequest(resolve, reject);
      });
    }
  
    abort() {}
  
    _sendRequest(resolve, reject) {
      this.loader.file
        .then((file) => {
          // Prepare the form data.
          const storage = this.storage;

          const formatFileName = (filename) => {
            const extenstion = filename.substring(filename.lastIndexOf('.'));
            return filename.substring(0,filename.lastIndexOf('.')).replace(/[^a-z0-9]/gi, '_').toLowerCase()+ extenstion
            }

          const filename = this.namePrefix + Date.now()+formatFileName(file.name);
          const extension = file.name.slice(file.name.lastIndexOf(".") + 1);
          
          if (!['png', 'jpeg', 'jpg'].includes(extension.toLowerCase())) {
            // alert("Only allow png and jpeg");
            this.handleMessageSvc.handleMessage("IMAGE_EXTENSION");
            // return ;
            reject()

            // throw Error("IMAGE_EXTENSION Only allow png and jpeg");
          } else if (file.size > 1 * 1024 * 1024)
          {
            //   -	Picture: 1MB
            // alert("Attachment file allow 1MB")
            this.handleMessageSvc.handleMessage("IMAGE_SIZE");
            // return ;
            // throw ; //Error("IMAGE_SIZE Attachment file allow 1MB");
            reject()
        }          
          let mimeType;
          // switch (extension) {
          //   case "svg":
          //     mimeType = "image/svg+xml";
          //     break;
          //   case "jpg":
          //     mimeType = "image/jpeg";
          //     break;
          //   case "png":
          //     mimeType = "image/png";
          //     break;
          // }
          storage
            .put(filename, file, {
              contentType: file.type,
              ACL: "public-read-write",
              level: "public",
            })
            .then((result) => {
              const key = result.key;
              storage.get(key, { expire: 60 * 60 * 24 * 3 }).then((data) => {
                const uri = data;
                resolve({
                  default: uri,
                });
              });
            });
        })
        .catch(reject);
    }
  }