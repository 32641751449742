import { AuthState } from '@aws-amplify/ui-components';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';

const CustomCognitoSignIn = (props) => {
  const {authState, setFormState, setAuthState, onFinishSignIn, loading} = props;

  const [errorMessage, setErrorMessage] = useState(null)
  const [form] = Form.useForm();

  return (
    <>
      {errorMessage && 
        <div className="ant-alert ant-alert-error" style={{position: 'fixed', top: 0, width: '100%'}}>
          <span className="anticon anticon-close-circle ant-alert-icon">
            <svg viewBox="64 64 896 896" 
            focusable="false" data-icon="close-circle" width="1em" height="1em" 
                fill="currentColor" aria-hidden="true">
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
            </svg>
          </span>
          <div className="ant-alert-content">
            <div className="ant-alert-message">
              {errorMessage}
            </div>
            <div className="ant-alert-description"></div>
          </div>
        </div>
      }
      <Form
        form={form}
        name="register"
        onFinish={onFinishSignIn}
        style={{
          backgroundColor: '#fff',
          padding: '35px 40px',
          borderRadius: '6px',
          width: '460px',
        }}
        layout="vertical"
        scrollToFirstError>
        <div className="form-section-header">
          <h1 className="header" style={{textAlign: 'center', 'marginBottom': '20px'}}> <span style={{fontSize: '45px'}}> Unilever </span> <br/> <span style={{fontSize: '25px','display': 'block', 'marginTop' : '-15px'}}>Vendor Portal</span></h1>
          <h3 className="header" style={{textAlign: 'center', 'marginBottom': '20px'}}>Sign In to Continue <br/> Đăng Nhập để Tiếp Tục</h3>
          <div className="subtitle"></div>
        </div>
        <div className="ant-col ant-form-item-label">
          <label className="ant-form-item-required">User ID/ Tên Đăng Nhập</label>
          <div style={{fontSize: 13}}>If you are a Vendor, Please input your Vendor SAP Code</div>
          <div style={{fontSize: 13}}>Nếu bạn là Nhà Cung Cấp, vui lòng nhập mã SAP</div>
        </div>
        <Form.Item
          name="username"
          rules={[{ 
            required: true, 
            message: 'Please input your User ID! Vui lòng nhập Tên đăng nhập!' 
          }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password/ Mật khẩu"
          rules={[
            {
              required: true,
              message: 'Please input your password! Vui lòng nhập Mật khẩu!',
            },
          ]}>
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item style={{textAlign: 'center'}}>
          <Button loading={loading} type="primary" size="large" htmlType="submit" style={{minWidth: '153px'}}>
            Sign In/ Đăng Nhập
          </Button>
        </Form.Item>
        <div style={{textAlign: 'center'}}>
        <a onClick={() => {
          setFormState('requestForgotPassword')
          setAuthState(AuthState.ForgotPassword)
          }}>Forgot Your Password?/ Quên Mật Khẩu?</a></div>
      </Form>
    </>
  )
}

export default CustomCognitoSignIn
