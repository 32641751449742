import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Alert, Modal } from 'antd';
import Amplify, { API, Auth, Hub } from 'aws-amplify';
import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import awsconfig from './aws-exports';
import CustomCognitoConfirmForgotPassword from './customCognitoConfirmForgotPassword';
import CustomCognitoRequestForgotPassword from './customCognitoRequestForgotPassword';
import CustomCognitoRequireNewPassword from './customCognitoRequireNewPassword';
import CustomCognitoSignIn from './customCognitoSignIn';
import CustomCognitoVerifyEmail from './customCognitoVerifyEmail';
import './i18n';
import { useUtils } from './utils/hooks';
import APAdminView from './views/apadmin';
import APManagerView from './views/apmanager';
import VendorView from './views/vendor';

Amplify.configure(awsconfig)

const listener = (data) => {
  if (data.payload.event === "tokenRefresh_failure") {
    localStorage.clear();
    deleteAllCookies();
    window.location.reload();
  }
};
Hub.listen("auth", listener);

function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

const AuthStateApp = () => {
  const [authState, setAuthState] = useState()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()
  const [role, setRole] = useState()
  const [alertMessage, setAlertMessage] = useState('');
  const [sentToEmail, setSentToEmail] = useState('');
  const [isLogOut, setIsLogOut] = useState(false);
  const [codeAndUserForgotPassword, setCodeAndUserForgotPassword] = useState({});
  const language = useSelector(state => _.get(state,'auth.language'));
  const {getQueryVariable : qs, customEncode, customDecode} = useUtils();

  const formStates = {
    onVerify : 'ON_VERIFY',
    onInputCode : 'ON_INPUT_CODE',
    onSignIn: 'ON_SIGN_IN',
    onChallenge: 'ON_CHALLENGE',
    requestForgotPassword: 'requestForgotPassword',
    requestForgotPasswordSuccess: 'requestForgotPasswordSuccess',
    confirmForgotPassword: 'confirmForgotPassword'
  }
  const [formState, setFormState] = useState(formStates.onSignIn);
  function setErrorMessage(errorMessage, isVendor) {
    let param = {
      payload : {message : errorMessage, isVendor: isVendor},
    };
    
    let message = handleToastErrors(param)
    Modal.error({
      title: 'Error / Lỗi',
      content: <div dangerouslySetInnerHTML={{__html:`${message}`}}/>,
      centered: true,
      maskClosable: true
    });
  }
  
  const checkUserIsVendor =  (userName) => {
    setLoading(true);
    
    let apiName = 'unileverAPI'
    let path = '/users/getUserGroup'
    let params = {
      responseType: 'application/json',
      response: true,
      queryStringParameters: {
        userName : userName,
        groupToCheck: 'vendor'
      }
   
    }
    return API.get(apiName, path, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        Modal.error({
          centered: true,
          content: error,
        });
      })
  }
  const onFinishChangeNewPass = (values) => {
    setLoading(true);
    Auth.completeNewPassword(user, values.password)
      .then(user => {
        if (_.get(user, 'attributes.email_verified') === undefined ) {

          setFormState(formStates.onVerify);
        }
          // put the state back to sign in , in case user logout / BUT only when User's Email doest need to be verified
          if (_.get(user, 'challengeParam.userAttributes.email_verified')== null || _.get(user, 'challengeParam.userAttributes.email_verified')== undefined || _.get(user, 'challengeParam.userAttributes.email_verified') ) {
            setFormState(formStates.onSignIn);
          }
          
        setLoading(false);
        
      }).catch(e => {
        setErrorMessage(e.message);
        setLoading(false);
      });
  };

  const onFinishSignIn = (values) => {
    setLoading(true);
    Auth.signIn(values.username, values.password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setFormState(formStates.onChallenge);
          setUser(user);
        } else if (_.get(user, 'attributes.email_verified') === undefined ) {
          setFormState(formStates.onVerify);
        }        
      }).catch(e => {
        let isVendor;
        // api get user group here 
        checkUserIsVendor(values.username)
          .then((response)=> {
            isVendor = _.get(response,'groupToCheck', true);
          })
          .finally(()=>{
            setErrorMessage(e.message, isVendor);
          })

      })
      .finally(()=>{
        setLoading(false);
      });
  };

  const onVerifyEmail = (values) => {
      setLoading(true);
      // since the system verify email only, in this case we do hardcode: email
      Auth.verifyCurrentUserAttribute('email')
        .then(user => {
        setFormState(formStates.onInputCode);
          setLoading(false);
          
        }).catch(e => {
          setErrorMessage(e.message);
          setLoading(false);
        });
  };
  
  const onSubmitCode = (values) => {
    setLoading(true);
    Auth.verifyCurrentUserAttributeSubmit('email', values.code)
      .then(data => {
        // setFormState(formStates.onInputCode);
        // setUser(user);
        if (data === 'SUCCESS') {
          setAuthState(AuthState.SignedIn);
          // put the state back to sign in , in case user logout
          setFormState(formStates.onSignIn);
        }
        setLoading(false);
        
      })
      .catch(e => {
        setErrorMessage(e.message);
        setLoading(false);
      });
  };
  // HANDLE ERRORS
  const handleToastErrors = ({ payload }) => {
    if (payload.message) {
      //"PreAuthentication failed with error Your account has been locked for 12 hours due to having five login attempt failures. An email notification has been sent to you for further instructions.."
      if (_.includes(payload.message, 'PreAuthentication failed with error')  ||
        _.includes(payload.message, 'Email address is not verified. The following identities failed the check in region')
      ) {
        let mes = "Your account has been locked for 12 hours due to having five login attempt failures. An email notification has been sent to you for further instructions. <br/> <br/> Tài khoản của bạn đã bị khóa trong vòng 12 giờ tiếp theo do có năm lần đăng nhập không thành công. Hệ thống đã gửi thông báo đến email của bạn với hướng dẫn đính kèm."
        _.set(payload, 'message', mes);
      }

      if (_.includes(payload.message, 'User is disabled')) {
        let mes = 'Your account is in the 12-hour lock period due to having five login attempt failures. You will not be able to use Forgot Password function during this period. Please try again when your account is unlocked. <br/> <br/> Tài khoản của bạn đang trong khoảng thời gian bị khóa 12 giờ do có năm lần đăng nhập không thành công. Bạn sẽ không thể sự dụng chức năng Quên Mật Khẩu trong lúc này. Vui lòng thử lại khi tài khoản của bạn đã được mở khóa.';
        _.set(payload, 'message', mes);
        
      }

      //The User ID and Password you entered did not match our records. Please double-check and try again.

      // PreAuthentication failed with error Error: Message failed: 554 Message rejected: Email address is not verified. The following identities failed the check in region AP-SOUTHEAST-1: notverified@gmail.com.

      
      if (_.includes(payload.message, 'Incorrect username or password')) {
        // Incorrect username or password.
        let mes;
        
        if (!payload.isVendor) {
          mes = 'The User ID and Password you entered did not match our records. Please double-check and try again. <br/> <br/> Tên đăng nhập và mật khẩu không đúng. Vui lòng kiểm tra và thử lại.';

        } else {
          mes = 'The User ID and Password you entered did not match our records. Please double-check and try again. <br/> Please note that your account will be locked after 5 consecutive login attempt failures. <br/> <br/> Tên đăng nhập và mật khẩu không đúng. Vui lòng kiểm tra và thử lại. <br/> Xin lưu ý rằng tài khoản của bạn sẽ bị khóa sau 5 lần đăng nhập liên tiếp không thành công. ';
        }
        _.set(payload, 'message', mes);
      }

      // Password does not conform to policy
      if (_.includes(payload.message, 'Password does not conform to policy')) {
        // Incorrect username or password.
        let mes = 'Your password does not meet the requirements. <br/> Mật khẩu không đáp ứng đủ các yêu cầu.';
        _.set(payload, 'message', mes);
      }
      
      
      if (_.includes(payload.message, 'User does not exist.')) {
        let mes = 'User does not exist. Please re-check your user ID. <br/> Tài khoản không tồn tại. Vui lòng kiểm tra lại tên đăng nhập.';
        _.set(payload, 'message', mes);
      }
      
      // Mã không chính xác. Vui lòng thử lại.
      if (_.includes(payload.message, 'Invalid verification code provided, please try again.')) {
        let mes = 'Incorrect code. Please try again. <br/> Mã không chính xác. Vui lòng thử lại.';
        _.set(payload, 'message', mes);
      }


      // - ENG: Invalid code provided, please request a code again
      if (_.includes(payload.message, 'Invalid code provided, please request a code again')) {
        let mes = 'Invalid code provided, please request a code again <br/> Mã xác nhận đã hết hạn, vui lòng yêu cầu mã mới!';
        _.set(payload, 'message', mes);
      }


      if (_.includes(payload.message, 'Invalid session for the user, session is expired')) {
        let mes = 'Invalid session for the user, session is expired, please sign in again <br/> Phiên đã hết hạn, vui lòng đăng nhập lại!';
        _.set(payload, 'message', mes);
      }

      // setErrorMessage(payload.message);

      return payload.message;
    }
  };

  const onSubmitUserSAP = (formdata) => {
    if(loading === false){
      setLoading(true);
    
      return Auth.forgotPassword( _.get(formdata,'usersap'))
      .then(data => {
        setFormState(formStates.requestForgotPasswordSuccess)
        setSentToEmail(_.get(data,'CodeDeliveryDetails.Destination'))
      })
      .catch(err => {
        let final = "Something went wrong."
        if(_.includes(_.get(err,'code'), 'UserNotFoundException')) {
          final = 'The User ID entered does not exists. Please recheck and try again.</br> Tên tài khoản không tồn tại. Vui lòng kiểm tra và thử lại.'
        }
        if(_.includes(_.get(err,'message'), 'User is disabled')) {
          final = 'Your account is in the 12-hour lock period due to having five login attempt failures. You will not be able to use Forgot Password function during this period. Please try again when your account is unlocked. <br/> <br/> Tài khoản của bạn đang trong khoảng thời gian bị khóa 12 giờ do có năm lần đăng nhập không thành công. Bạn sẽ không thể sự dụng chức năng Quên Mật Khẩu trong lúc này. Vui lòng thử lại khi tài khoản của bạn đã được mở khóa.';
        }
        if(_.includes(_.get(err,'message'), 'User password cannot be reset in the current state')) {
          // final = 'User password cannot be reset in the current state. </br> Mật khẩu không thể được thiết lập lại ở trạng thái hiện tại.';
          final = 'Please use the temporary credentials in Welcome Email to login. </br> Vui lòng sử dụng thông tin đăng nhập tạm thời trong email Chào Mừng để đăng nhập.';
        }
        Modal.error({
          title: 'Error / Lỗi',
          content: <div dangerouslySetInnerHTML={{__html:`${final}`}}/>,
          centered: true,
          maskClosable: true
        });
      })
      .finally(()=>{
        setLoading(false);
      });
    }
  }

  const onSubmitCodePassword = (formdata) => {
    if(loading === false){
      setLoading(true);
      setIsLogOut(true)
      let user = _.get(codeAndUserForgotPassword,'user');
        return Auth.forgotPasswordSubmit(user, _.get(codeAndUserForgotPassword, 'code'),_.get(formdata,'password'))
      .then(data => {
        return Auth.signIn(user, _.get(formdata,'password'))
      })
      .then((user)=>{
        setUser(user)
        return Auth.signOut({global: true})
      })
      .then(()=>{
        setFormState(formStates.onSignIn)
        Modal.success({
          centered: true,
          content: "You have successfully changed your password, please sign in./ Thay đổi mật khẩu thành công, hãy đăng nhập lại.",
        });
      })
      .catch(err => {
        let final = "Something went wrong."
        if(_.includes(_.get(err,'message'), 'Invalid code provided, please request a code again.')) {
          final = 'Invalid reset password link provided, please request a link again. </br> Đường dẫn thiết lập lại khẩu không đúng, hãy yêu cầu lại một đường dẫn mới.';
        }
        Modal.error({
          title: 'Error / Lỗi',
          content: <div dangerouslySetInnerHTML={{__html:`${final}`}}/>,
          centered: true,
          maskClosable: true
        });
      })
      .finally(()=>{
        window.history.replaceState( {} , _, '/' );
        setLoading(false);
        setIsLogOut(false)

      });
    }
  }

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      if (authData) {
        setUser(authData)
        if (nextAuthState == AuthState.SignedIn && 
          (authData.attributes.email_verified == null || authData.attributes.email_verified == undefined || authData.attributes.email_verified == false)) {
          localStorage.clear();
          deleteAllCookies();
          window.location.reload();
        } else if (
          authData.signInUserSession &&
          authData.signInUserSession.accessToken &&
          authData.signInUserSession.accessToken.payload['cognito:groups'] &&
          authData.signInUserSession.accessToken.payload['cognito:groups'][0]
        ) {
          setRole(authData.signInUserSession.accessToken.payload['cognito:groups'][0])
        }
      }
      if (nextAuthState !== 'signin') {
        setAlertMessage(null);
      }
      if (nextAuthState === 'verifyContact') {
        setFormState(formStates.onVerify);
      }
    })
  }, [])
  
  if (_.isNil(authState) && _.includes(window.location.href, "/login")) {
    Auth.signOut()
    localStorage.clear();
    setRole(undefined);
    window.history.replaceState( {} , _, '/' );
  }

  if (!_.has(codeAndUserForgotPassword, 'user') && !_.has(codeAndUserForgotPassword, 'code') && _.includes(window.location.href, "/new-password") && qs(customEncode('user')) && qs(customEncode('code'))) {
    setCodeAndUserForgotPassword({user: customDecode(qs(customEncode('user'))), code : qs(customEncode('code'))})
    setFormState(formStates.confirmForgotPassword)
    setAuthState(AuthState.ForgotPassword)
  }
  
  return authState === AuthState.SignedIn && user && !isLogOut ? (
    <Suspense fallback={null}>
      {role === 'apadmin' ? (
        <APAdminView user={user} setRole={setRole} />
      ) : role === 'apmanager' ? (
        <APManagerView user={user} setRole={setRole} />
      ) : role === 'vendor' ? (
        <VendorView user={user} setRole={setRole} />
      ) : <></>}
    </Suspense>
  ) : (
    <div>
      {alertMessage && (<Alert style message={alertMessage} showIcon type="error" />)}
      {/* <button onClick={mockAPI}>BUTTON </button> */}
    <AmplifyAuthenticator hideToast>      
      
      <div 
        className="CognitoContainer custom-signin" 
        slot="sign-in" 
        style={{
          backgroundImage: 'url(/assets/images/bg.jpeg)',
        }}>
        {formState === formStates.onSignIn && 
            <CustomCognitoSignIn loading={loading} onFinishSignIn={onFinishSignIn} setAuthState={setAuthState} setFormState={setFormState}/>
        }
        {formState === formStates.requestForgotPassword && 
            <CustomCognitoRequestForgotPassword loading={loading} onSubmitUserSAP={onSubmitUserSAP} formStates={formStates} setAuthState={setAuthState} setFormState={setFormState} user={user} />
        }
        {formState === formStates.requestForgotPasswordSuccess && 
            
            <div 
              style={{
                backgroundColor: '#fff',
                padding: '35px 40px',
                borderRadius: '6px',
                width: '460px',
                textAlign:'center'
              }} 
            >
              <h3>An email notification with a password reset link has been sent to {sentToEmail}</h3>
              <h3>Một email có chứa đường dẫn thiết lập lại mật khẩu đã được gửi đến địa chỉ email {sentToEmail}</h3>
              <div style={{textAlign: 'center'}}>
              <a onClick={() => {
                setFormState(formStates.onSignIn)
                setAuthState(AuthState.signIn)
                }}>Back to Login/ Quay lại Đăng Nhập</a></div>
            </div>
            
        }
        {formState === formStates.confirmForgotPassword && 
            <CustomCognitoConfirmForgotPassword loading={loading} onSubmitCodePassword={onSubmitCodePassword} formStates={formStates} user={user} />
        }
        
        {/* CHALLENGES IN HERE */}
        {formState === formStates.onChallenge && 
          <div 
          className="CognitoContainer custom-require-new-password" 
          slot="require-new-password"
          style={{
            backgroundImage: 'url(/assets/images/bg.jpeg)'
          }}> 
          <CustomCognitoRequireNewPassword onFinishChangeNewPass={onFinishChangeNewPass} loading={loading} setFormState={setFormState} formStates={formStates} user={user} />
        </div>
        }
        {/* <AmplifySignIn hideSignUp></AmplifySignIn> */}
      </div>
      
      <div 
        className="CognitoContainer custom-require-new-password" 
        slot="verify-contact"
        style={{
          backgroundImage: 'url(/assets/images/bg.jpeg)'
        }}> 
        <CustomCognitoVerifyEmail loading={loading} formState={formState} formStates={formStates} onVerifyEmail={onVerifyEmail} onSubmitCode={onSubmitCode} user={user} />
      </div>
    </AmplifyAuthenticator>
    </div>
  )
}

export default AuthStateApp
