import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Switch, Table } from "antd";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import EditableCell from "./EditableCell";

const SectionsTable = forwardRef((props, ref) => {
  const { pagination } = props;
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [editingKey, setEditingKey] = useState("");

  const handleTableChange = (pagination) => {
    props.onPaginationChangeCb(pagination);
  };

  const columns = [
    {
      title: t('admin.qna.table.order.title'),
      dataIndex: "order_number",
      fixed: "left",
      editable: true,
      width: 50,
      fixed: "left",
      render: (_, record) => {
        if (record.is_active === 1) {
          return <div className="active-cell">{record.order_number}</div>;
        }
        return <div className="inactive-cell">{record.order_number}</div>;
      },
    },
    {
      title: t('admin.qna.table.sectionEn.title'),
      dataIndex: "section_en",
      editable: true,
      width: 250,
      render: (_, record) => {
        if (record.is_active === 1) {
          return <div className="active-cell">{record.section_en}</div>;
        }
        return <div className="inactive-cell">{record.section_en}</div>;
      },
    },
    {
      title: t('admin.qna.table.sectionVn.title'),
      dataIndex: "section_vi",
      editable: true,
      width: 250,
      render: (_, record) => {
        if (record.is_active === 1) {
          return <div className="active-cell">{record.section_vi}</div>;
        }
        return <div className="inactive-cell">{record.section_vi}</div>;
      },
    },
    {
      title: t('admin.qna.table.active.title'),
      dataIndex: "is_active",
      editable: true,
      width: 50,
      render: (_, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <Switch
              defaultChecked={record.is_active === 1}
            />
          );
        }
        return (
          <Switch
              checked={record.is_active === 1}
              disabled={true}
            />
        );
      },
    },
    {
      editable: false,
      width: 100,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button type="primary" onClick={handleSubmitValidateEdit}>
              {t('admin.qna.table.saveBtn')}
            </Button>
            <Button type="danger" onClick={handleCancelEdit}>
              {t('admin.qna.table.cancelBtn')}
            </Button>
          </>
        ) : (
          <>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => edit(record)}
            />
          </>
        );
      },
    },
  ];

  const isEditing = (record) => record.uuid === editingKey;

  useImperativeHandle(ref, () => ({
    handleCancelEdit() {
      setEditingKey("");
    }
  }));

  const handleCancelEdit = () => {
    setEditingKey("");
  };

  const handleSubmitValidateEdit = async () => {
    const row = await form.validateFields();
    const payload = {
      section: { ...row },
    };
    if (props.onUpdateSectionCb) {
      props.onUpdateSectionCb(editingKey, payload);
    }
    setEditingKey("");
  };

  const edit = (record) => {
    form.setFieldsValue({
      order_number: "",
      section_vi: "",
      section_en: "",
      is_active: "",
      ...record,
    });
    setEditingKey(record.uuid);
  };

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }

    if (col.dataIndex === "is_active") {
      const newCol = {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          handleSave: (isChecked) => {
            form.setFieldsValue({
              is_active: isChecked ? 1 : 0,
            });
          },
        }),
      };
      return newCol;
    }

    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };

    return newCol;
  };

  const mergedColumns = columns.map(mapColumns);
  return (
    <Form form={form} component={false} enableReinitialize>
      <Table
        className="view-invoice-table"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        scroll={{ x: 1500 }}
        bordered
        columns={mergedColumns}
        rowKey={(record) => record.uuid}
        rowClassName="editable-row"
        dataSource={[...props.data]}
        loading={props.loading}
        pagination={{
          ...pagination,
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${pagination.total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </Form>
  );
})

export default SectionsTable;
