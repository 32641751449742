import { Affix, Button, Divider, Form, Input, Modal, PageHeader, Table } from 'antd'
import { API } from 'aws-amplify'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './adminAccountManagement.scss'
const { TextArea } = Input
function AdminAccountManagement() {
  const [form] = Form.useForm();
  const [formPasswod] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');

  const [data, setData] = useState([])
  const [emailModalState, setEmailModalState] = useState({
    visible : false,
    data: {}
  })
  const [passwordModalState, setPasswordModalState] = useState({
    visible : false,
    data: {}
  })

  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const columns = [
    {
      title: t('manager.apadminAccount.columns.username'),
      dataIndex: 'username',
      width: 150,
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{row.username}</div>
        }
      },
      fixed: 'left',
    },
    {
      title: t('manager.apadminAccount.columns.email'),
      dataIndex: 'email',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'email', _.get(row,'email', 'email.Value'))}</div>
        }
      },
      width: 400,
    },
    {
      title: t('manager.apadminAccount.columns.action'),
      dataIndex: 'action',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div> 
            <Button onClick={()=>setEmailModalState({visible: true, data : row})}>{t('manager.apadminAccount.changeEmailBtn')}</Button>
            <Button style={{marginLeft: '10px'}} onClick={()=>setPasswordModalState({visible: true, data : row})}>{t('manager.apadminAccount.changePasswordBtn')}</Button>
            </div>
        }
      },
      width: 250,
    },

  ]

  const fetchData = (params = {}) => {
    setLoading(true)
    API.get('unileverAPI', 
    `/accounts/getAdminList`)
      .then((result) => {
        setLoading(false)
        setData(result)
      })
      .catch(() => {
        setLoading(false)
        Modal.error({
          centered: true,
          content: t('manager.vendorMasterData.pendingRequest.fetchDataFailedMsg'),
        });
      })
  }
  useEffect(() => {
    fetchData()
  }, [])

  const updateEmailSubmit = (formdata) => {
    if(loading === false){
      setLoading(true)
      const request = {
        body: {
          username : emailModalState.data.username,
          emailToUpdate: formdata.email
        },
      }
      API.post('unileverAPI', '/accounts/submitChangeMail', request)
        .then((r) => {
          if (r.message) {
            Modal.warning({
              centered: true,
              content: r.message,
            });
          } else {
            Modal.success({
              centered: true,
              content: t('manager.apadminAccount.sendEmailSuccess'),
            });
          }
          setEmailModalState({data: {}, visible: false})
          setLoading(false)
          fetchData({
            current: 1,
            pageSize: 10,
          })
        })
        .catch((err) => {
          setLoading(false)
          let final = t('manager.vendorMasterData.pendingRequest.rejectFailedMsg')
          
          if (_.get(err,'response.data.errorCode') === "NOT_ALLOW_DUPLICATE_WITH_CURRENT_EMAIL") {
            final = t('manager.errorCode.changeEmail.NOT_ALLOW_DUPLICATE_WITH_CURRENT_EMAIL')
          }
          Modal.error({
            centered: true,
            content: final,
          });
        })
    }
  }
  const updatePasswordSubmit = (formdata) => {
    if(loading === false){
      setLoading(true)
      const request = {
        body: {
          adminUserName: _.get(passwordModalState, 'data.username'), 
          adminNewPassword: formdata.password
        },
      }
      API.post('unileverAPI', '/accounts/submitChangePassAdmin', request)
        .then((r) => {
          if (r.message) {
            Modal.warning({
              centered: true,
              content: r.message,
            });
          } else {
            Modal.success({
              centered: true,
              content: t('manager.apadminAccount.sendPasswordSuccess'),
            });
          }
          setPasswordModalState({data: {}, visible: false})
          setLoading(false)
          fetchData({
            current: 1,
            pageSize: 10,
          })
        })
        .catch(() => {
          setLoading(false)
          Modal.error({
            centered: true,
            content: t('manager.vendorMasterData.pendingRequest.rejectFailedMsg'),
          });
        })
    }
  }

  const formItemLayout =
  formLayout === 'horizontal'
    ? {
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
      }
    : null;
  return (
    <div className="site-page-header-ghost-wrapper" id="admin-account-management">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={`${t('manager.apadminAccount.title')}`}
          subTitle={`${t('manager.apadminAccount.subTitle')}`}
        ></PageHeader>
      </Affix>
      <Divider />
      <Table
        size="small"
        scroll={{ x: 1500 }}
        bordered
        columns={columns}
        rowKey={(record) => _.get(record, 'username')}
        dataSource={data}
        loading={loading}
        footer={false}
        pagination={false}
      />

      <Modal
        width={700}
        maskClosable={false}
        title={t('manager.apadminAccount.modal.emailTitle')} // Change AP Admin Email Address
        onCancel={() => {
          setEmailModalState({data: {}, visible: false})
          form.resetFields();
        }}
        afterClose={() => {
          setEmailModalState({data: {}, visible: false})
          form.resetFields();
        }}
        visible={emailModalState.visible}
        footer={false}
      >
        <Form
        className="section" role="application"
        form={form}
        {...formItemLayout}
        layout={formLayout}
        initialValues={{
          layout: formLayout,
        }}
        name="email-admin"
        onFinish={updateEmailSubmit}
        style={{
          backgroundColor: '#fff',
          padding: '35px 40px',
          borderRadius: '6px',
        }}
        scrollToFirstError>
        <Form.Item 
          className="formItem"
          name="username" 
          label={t('manager.apadminAccount.modal.labelUserId')}
          >
        <span>{_.get(emailModalState, 'data.username')}</span>
        </Form.Item>
        <Form.Item 
          className="formItem"
          name="email" 
          label={t('manager.apadminAccount.modal.labelNewEmail')}
          rules={[
            {
              required: true,
              message: t('manager.apadminAccount.modal.labelNewEmail.required'),
            },
            {
              type: 'email',
              message: t('manager.apadminAccount.modal.labelNewEmail.invalid'),
            }
          ]}
          >
        <Input type="email" style={{marginRight: '10px'}} placeholder="" />
        </Form.Item>
        <div style={{textAlign: 'right'}}>
        <Button 
          onClick={() => {
            setEmailModalState({data: {}, visible: false})
            form.resetFields();
          }}
          type="se" 
          size="large" 
          style={{width: '123px', display :  'inline-block',fontSize: '1em'}}>
        {t('manager.apadminAccount.cancel')} 
        </Button>


      <Form.Item shouldUpdate style={{textAlign:'center', display :  'inline-block', marginLeft : 10}}>
          {()=> (
            <Button 
            disabled={
              !form.isFieldTouched('email') ||
              form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
            }
            loading={loading} type="primary" size="large" htmlType="submit" style={{width: '123px',fontSize: '1em'}}>
            {t('manager.apadminAccount.submit')}  
          </Button>
          )}
        </Form.Item>
        </div>
        <div className="incorrect-records-msg" style={{ fontSize: '1em'}}>
          {t("vendor.settings.keyEmail.changeModal.footerMsg1")}
        </div>
      </Form>
      </Modal>

      {/* 
      *
      *
      *
      *
      *
      *          PASSWORD MODAL 
      *
      *
      *
      *
      */}

      <Modal
        width={700}
        maskClosable={false}
        title={t('manager.apadminAccount.modal.passwordTitle')}
        onCancel={() => {
          setPasswordModalState({data: {}, visible: false})
          formPasswod.resetFields();
        }}
        afterClose={() => {
          setPasswordModalState({data: {}, visible: false})
          formPasswod.resetFields();
        }}
        visible={passwordModalState.visible}
        footer={false}
      >
      <div style={{width:'fit-content', margin:'auto'}}>
      <div>{t('manager.apadminAccount.modal.mess1')} :</div>
          <ul>
            <li>{t('manager.apadminAccount.modal.mess2')}</li>
            <li>{t('manager.apadminAccount.modal.mess3')}</li>
            <li>{t('manager.apadminAccount.modal.mess4')}</li>
          </ul>
      </div>
        <Form
        className="section" role="application"
        form={formPasswod}
        {...formItemLayout}
        layout={formLayout}
        initialValues={{
          layout: formLayout,
        }}
        name="password-admin"
        onFinish={updatePasswordSubmit}
        style={{
          backgroundColor: '#fff',
          padding: '35px 40px',
          borderRadius: '6px',
        }}
        scrollToFirstError>
        <Form.Item 
          className="formItem"
          name="username" 
          label={t('manager.apadminAccount.modal.labelUserId')}
          >
        <span >{_.get(passwordModalState, 'data.username')}</span>
        </Form.Item>
        <Form.Item
          className="formItem"
          name="password"
          label={t('manager.apadminAccount.modal.labelNewPass')}
          rules={[
            {
              min: 10,
              message: t('manager.apadminAccount.modal.labelNewPass.min'),
            },
            {
              required: true,
              message: t('manager.apadminAccount.modal.labelNewPass.required'),
            },
            () => ({
              validator(_, value) {
                if (value && (value.search(/[a-z]/) < 0 || value.search(/[A-Z]/) < 0)) {
                  return Promise.reject(new Error(t('manager.apadminAccount.modal.labelNewPass.mixture')));
                }
                return Promise.resolve();
              },
            }),
            () => ({
              validator(_, value) {
                if (value && !(/[*@!#%&()^~{}]+/.test(value))) {
                  return Promise.reject(new Error(t('manager.apadminAccount.modal.labelNewPass.specialChar')));
                }
                return Promise.resolve();
              },
            }),
          ]}
          hasFeedback>
          <Input.Password width={400} size="large" />
        </Form.Item>
        <Form.Item
          className="formItem"
          name="confirm"
          label={t('manager.apadminAccount.modal.labelConfirmPass')}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('manager.apadminAccount.modal.labelConfirmPass.required'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('manager.apadminAccount.modal.labelConfirmPass.match')));
              },
            }),
          ]}>
          <Input.Password width={400} size="large" />
        </Form.Item>
        <div style={{textAlign: 'right'}}>
        <Button 
          onClick={() => {
            setPasswordModalState({data: {}, visible: false})
            formPasswod.resetFields();
          }}
          type="se" 
          size="large" 
          style={{width: '123px', display :  'inline-block',fontSize: '1em'}}>
        {t('manager.apadminAccount.cancel')}  
        </Button>


      <Form.Item 
        shouldUpdate 
        style={{textAlign:'center', display :  'inline-block', marginLeft : 10}}>
          {()=> (
            <Button 
            disabled={
              !formPasswod.isFieldsTouched(['password','confirm']) ||
              formPasswod.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
            }
            loading={loading} type="primary" size="large" htmlType="submit" style={{width: '123px', fontSize: '1em'}}>
            {t('manager.apadminAccount.submit')}  
          </Button>
          )}
        </Form.Item>
      </div>
      <div className="incorrect-records-msg" style={{ fontSize: '1em'}}>
          {t("vendor.settings.keyEmail.changeModal.footerMsg1")}
        </div>
      </Form>
      
      </Modal>

      </div>
  )
}

export default AdminAccountManagement
