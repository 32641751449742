import _ from 'lodash';
import moment from 'moment';
const Helpers = {
  generateCellData: (record, fieldName) => {
    const parsedCellData = {}
    const rowType = record.status;
    const cellData = record[fieldName];
    if (rowType === 'pending-create') {
      parsedCellData.type = 'new'
      if (typeof cellData === 'object') {
        parsedCellData.cellStyle = 'error-cell'
      } else {
        parsedCellData.cellStyle = 'inherit-cell'
      }
    } else {
      parsedCellData.type = 'updated'
      if (cellData?.validate) {
        parsedCellData.validationStatus = cellData.validate
        parsedCellData.cellStyle = 'error-cell'
      } else if (cellData.type === 'unchanged') {
        parsedCellData.cellStyle = 'inherit-cell'
      } else if (cellData.type === 'created') {
        parsedCellData.type = 'new'
        parsedCellData.cellStyle = 'inherit-cell'
      } else {
        parsedCellData.cellStyle = 'updated-cell'
      }
    }
    parsedCellData.displayValue =
      typeof cellData === 'object' ? cellData.value : cellData
    return parsedCellData
  },
  generateCellDataOfPreviewInvoice: (record, fieldName) => {
    const parsedCellData = {}
    const rowType = record.status;
    const cellData = record[fieldName];
    if (rowType === 'pending-create') {
      parsedCellData.type = 'new'
      if (typeof cellData === 'object') {
        parsedCellData.cellStyle = 'error-cell'
      } else {
        parsedCellData.cellStyle = 'inherit-cell'
      }
    } else {
      parsedCellData.type = 'updated'
      if (cellData?.validate) {
        parsedCellData.validationStatus = cellData.validate
        parsedCellData.cellStyle = 'error-cell'
      } else if (cellData.type === 'unchanged') {
        parsedCellData.cellStyle = 'inherit-cell'
      } else if (cellData.type === 'created') {
        parsedCellData.type = 'new'
        parsedCellData.cellStyle = 'created-cell'
      } else {
        parsedCellData.cellStyle = 'updated-cell'
      }
    }
    parsedCellData.displayValue =
      typeof cellData === 'object' ? cellData.value : cellData
    return parsedCellData
  },
  generateCellDataHistory: (record, fieldName, subField) => {
    const parsedCellData = {}
    const cellData = record[fieldName];

    parsedCellData.displayValue = typeof cellData === 'object' ?
      (subField ?_.get(record[fieldName], subField) : cellData.value ) :  
      cellData
    if (fieldName === 'updated_date' && _.get(cellData, 'validate') !== 'error' && !_.isNil(_.get(parsedCellData, 'displayValue'))) {
      parsedCellData.displayValue = moment(parsedCellData.displayValue , 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD-MM-YYYY h:mm:ss A');
    }
    return parsedCellData //{displayValue: 'parsedCellData'}
  },
  generateCellDataForMissingInvoice: (record, fieldName, subField) => {
    const parsedCellData = {}
    const cellData = record[fieldName]
    if ( _.get(cellData, 'status') === 'new') {
      parsedCellData.type = 'new'
      parsedCellData.cellStyle = 'inherit-cell'
    } else {
      parsedCellData.type = 'updated'
      parsedCellData.cellStyle = 'error-cell'
    }
    parsedCellData.displayValue =
      typeof cellData === 'object' ? (subField ?_.get(record[fieldName],'value.'+subField) : cellData.value ) : cellData

    if (fieldName === 'invoice_amount' && _.get(cellData, 'validate') !== 'error') {
        parsedCellData.displayValue = toTwoDecimal(parsedCellData.displayValue)
    }
    if (fieldName === 'status_date' && _.get(cellData, 'validate') !== 'error') {
      parsedCellData.displayValue = moment(parsedCellData.displayValue, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
    }
    return parsedCellData
  },
}
const toTwoDecimal = num => (Math.round( num * 100) / 100).toFixed(2);


export default Helpers
