import { Form, Input, InputNumber, Select, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";

const { Option } = Select;

let timeout;
let currentValue;
function fetchVendor(field, value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function callApi() {
    let body = {
      invoiceType: "vendor",
      vendorField: field,
      searchString: value,
    };
    return API.post("unileverAPI", "/invoices/masterData/search", {
      body,
    }).then((d) => {
      if (currentValue === value) {
        const { data } = d;
        callback(data);
      }
    });
  }
  timeout = setTimeout(callApi, 300);
}

function fetchInvoiceMasterData(type, value, lang, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function callApi() {
    let body = {
      invoiceType: type,
      searchString: value,
      lang: lang,
      searchBy: 'sys_lang'
    };
    return API.post("unileverAPI", "/invoices/masterData/search", {
      body,
    }).then((d) => {
      if (currentValue === value) {
        const { data } = d;
        callback(data);
      }
    });
  }
  timeout = setTimeout(callApi, 300);
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  handleSave,
  record,
  ...restProps
}) => {
  const [vendorSAPCodeOptions, setVendorSAPCodeOptions] = useState([]);
  const [invoiceReasonOptions, setInvoiceReasonOptions] = useState([]);
  const [invoiceStatusOptions, setInvoiceStatusOptions] = useState([]);
  const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState(null);
  const { t, i18n } = useTranslation();

  const isRequiredField = (dataIndex) => {
    if (dataIndex === "notes" || dataIndex === "po_number") {
      return false;
    }
    return true;
  };
  const generateInputNode = () => {
    if (dataIndex === "vd_sap_code") {
      return (
        <Select
          style={{ width: "100%" }}
          showSearch
          allowClear
          placeholder={t(
            "admin.viewInvoiceTracking.editableCell.vendorId.placeholder"
          )}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(value) => {
            if (value) {
              fetchVendor("vd_sap_code", value, (data) =>
                setVendorSAPCodeOptions(data)
              );
            } else {
              setVendorSAPCodeOptions([]);
            }
          }}
          onClear={() => {
            setVendorSAPCodeOptions([]);
          }}
          onChange={(value) => {
            if (handleSave) {
              handleSave(
                _.find(vendorSAPCodeOptions, function (o) {
                  return o.vd_sap_code == value;
                })
              );
            }
          }}
          notFoundContent={[]}
        >
          {vendorSAPCodeOptions &&
            vendorSAPCodeOptions.map((i) => (
              <Option key={i.vd_sap_code}>{i.vd_sap_code}</Option>
            ))}
        </Select>
      );
    } else if (dataIndex === "invoice_reason") {
      return (
        <Select
          style={{ width: "100%" }}
          showSearch
          allowClear
          placeholder={t(
            "admin.viewInvoiceTracking.editableCell.reason.placeholder"
          )}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(value) => {
            if (value) {
              fetchInvoiceMasterData(
                "invoice_reason",
                value,
                i18n.language,
                (data) => setInvoiceReasonOptions(data)
              );
            } else {
              setInvoiceReasonOptions([]);
            }
          }}
          onChange={(value) => {
            if (handleSave) {
              handleSave(
                value,
                _.find(invoiceReasonOptions, function (o) {
                  return o.uuid == value;
                })?.sys_lang
              );
            }
          }}
          onClear={() => {
            setInvoiceReasonOptions([]);
          }}
          notFoundContent={[]}
        >
          {invoiceReasonOptions.map((i) => (
            <Option key={i.uuid}>{i.sys_lang}</Option>
          ))}
        </Select>
      );
    } else if (dataIndex === "invoice_status") {
      return (
        <Select
          style={{ width: "100%" }}
          showSearch
          allowClear
          placeholder={t(
            "admin.viewInvoiceTracking.editableCell.invoiceStatus.placeholder"
          )}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(value) => {
            if (value) {
              fetchInvoiceMasterData(
                "invoice_status",
                value,
                i18n.language,
                (data) => setInvoiceStatusOptions(data)
              );
            } else {
              setInvoiceStatusOptions([]);
            }
          }}
          onChange={(value) => {
            if (handleSave) {
              handleSave(
                value,
                _.find(invoiceStatusOptions, function (o) {
                  return o.uuid == value;
                })?.sys_lang
              );
            }
          }}
          onClear={() => {
            setInvoiceStatusOptions([]);
          }}
          notFoundContent={[]}
        >
          {invoiceStatusOptions.map((i) => (
            <Option key={i.uuid}>{i.sys_lang}</Option>
          ))}
        </Select>
      );
    } else if (dataIndex === "invoice_amount") {
      return (
        <InputNumber
          disabled={dataIndex === "vd_tax_code" || dataIndex === "vd_name"} style={{ width: "80%"}}
          value={selectedInvoiceAmount}
          precision={2}
          step="0.01"
          stringMode
          onChange={(v) => {
            const value = v != null ? (Math.round(v * 100) / 100).toFixed(2) : null;
            setSelectedInvoiceAmount(value);
          }}
        />
      );
    }
    return inputType === "number" ? (
      <InputNumber
        disabled={dataIndex === "vd_tax_code" || dataIndex === "vd_name"} style={{ width: "80%"}}
      />
    ) : (
      <Input
        disabled={dataIndex === "vd_tax_code" || dataIndex === "vd_name"} style={{ width: "80%"}}
      />
    );
  };

  const generateNonEditableField = () => {
    return children;
  };
  const generateFormItem = () => {
    if (
      dataIndex === "invoice_date" ||
      dataIndex === "status_date" ||
      dataIndex === "due_date"
    ) {
      return (
        <DatePicker
          onChange={(date) => {
            if (handleSave) {
              handleSave(date);
            }
          }}
          format="DD/MM/YYYY"
          defaultValue={moment.utc(record[dataIndex])}
          allowClear={false}
        />
      );
    }
    return (
      <Form.Item
        name={dataIndex}
        style={{
          margin: 0,
        }}
        rules={[
          {
            required: isRequiredField(dataIndex),
            message: t("admin.viewInvoiceTracking.editableCell.requiredMsg"),
          },
        ]}
        shouldUpdate
      >
        {generateInputNode()}
      </Form.Item>
    );
  };
  return (
    <td {...restProps}>
      {editing ? generateFormItem() : generateNonEditableField()}
    </td>
  );
};

export default EditableCell;
