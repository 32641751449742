import { Affix, Divider, Modal, PageHeader } from 'antd'
import _ from 'lodash'
import * as XLSX from "xlsx";
import moment from "moment";
import { API, Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropZone from './dropZone'
import PreviewModal from './previewModal'
import { ErrorHandlers } from '../../../../utils'
import './uploadInvoice.scss'

function UploadInvoice({user}) {
  const [fileList, setFileList] = useState([])
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [newInvoiceData, setNewInvoiceData] = useState([])
  const [invoiceForUpdatedData, setInvoiceForUpdatedData] = useState([])
  const [availableNewInvoiceData, setAvailableNewInvoiceData] = useState([])
  const [
    availableInvoiceForUpdatedData,
    setAvailableInvoiceForUpdatedData,
  ] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (fileList.length > 0) {
      handleUpload()
    }
  }, [fileList])

  const handleSelectFile = (files) => {
    setFileList(files)
  }
  
  const submitRequest = () => {
    if (!submitting) {
      setSubmitting(true)
      const request = {
        body: {
          username: user.username,
          type: 'invoice_tracking'
        },
      }
      if (availableNewInvoiceData.length > 0) {
        request.body.newItems = availableNewInvoiceData
      }
      if (availableInvoiceForUpdatedData.length > 0) {
        request.body.itemsForUpdated = availableInvoiceForUpdatedData
      }
      return API.post('unileverAPI', '/invoices/tracking/submitInvoice', request)
        .then((rs) => {
          Modal.success({
            centered: true,
            content: t('admin.uploadInvoice.submitSuccessfullyMsg'),
          });
          setIsFileUploaded(false)
          setSubmitting(false)
        })
        .catch((err) => {
          setSubmitting(false)
          Modal.error({
            centered: true,
            content: t('admin.uploadInvoice.submitFailedMsg'),
          });
        })
    }
  }

  const handleUpload = (headerAtRowTh = 1) => {
    setUploading(true);
    let isValidTemplate = true;
    var f = fileList[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const sheet_header = { 
        header: [
          'invoice_number',
          'cc',
          'plant',
          'type',
          'scan_id',
          'scan_date',
          'due_date',
          'invoice_date',
          'assigned_to',
          'invoice_status_id',
          'invoice_reason_id',
          'status_date',
          'invoice_amount',
          'currency',
          'po_number',
          'vendor_id',
          'vd_name',
          'vd_tax_code',
          'notes'
        ],
        headerValue: {
          invoice_number: 'Document number',
          cc: 'CC',
          plant: 'Plant',
          type: 'Type',
          scan_id: 'Scan ID',
          scan_date: 'Scan date',
          due_date: 'Due Date',
          invoice_date: 'Doc Date',
          assigned_to: 'Assigned To',
          invoice_status_id: 'Action',
          invoice_reason_id: 'Reason',
          status_date: 'Status date',
          invoice_amount: 'Total Amount',
          currency: 'Currency',
          po_number: 'PO Number',
          vendor_id: 'Vendor ID',
          vd_name: 'Vendor Name',
          vd_tax_code: 'Vendor VAT',
          notes: 'Notes for AP Admin',
        },
        blankrows: false,
        raw: false
      }
      const dataListJson = XLSX.utils.sheet_to_json(ws, sheet_header);
      let headers = dataListJson[headerAtRowTh-1];
      if (headers) {
        if (Object.keys(headers).length !== sheet_header.header.length, Object.keys(headers).length !== Object.keys(sheet_header.headerValue).length) {
          setFileList([]);
          setUploading(false);
          isValidTemplate = false;
        }
        Object.keys(headers).forEach(key =>{
          if (headers[key] !== sheet_header.headerValue[key]) {
            setFileList([]);
            setUploading(false);
            isValidTemplate = false;
          }
        })
      }
      if(!isValidTemplate) {
        Modal.error({
          centered: true,
          content: t(`admin.errorCode.uploadInvoice.INVALID_HEADER_COLUMNS`)
        });
        return;
      }

      for (let i = 0; i< headerAtRowTh; i++) {
        dataListJson.shift();
      }
      const sorted = dataListJson.sort(function(a,b){
        const dateB = new Date(b.status_date).getTime();
        const dateA = new Date(a.status_date).getTime();
        return dateB < dateA ? 1 : -1;
      });
      const map = new Map();
      sorted.filter(item => !'Rejected By AP-IP Clerk (E200)'.includes(item.invoice_status_id)).forEach(item => {
        let newItem = _.pick(item, [
          'vendor_id',
          'vd_name',
          'vd_tax_code',
          'invoice_number',
          'scan_id',
          'invoice_date',
          'due_date',
          'invoice_amount',
          'currency',
          'assigned_to',
          'invoice_status_id',
          'invoice_reason_id',
          'status_date',
          'po_number',
          'notes'
        ])
        map.set(`${newItem.vendor_id?.toString()}-${newItem.invoice_number}-${moment(newItem.invoice_date, 'MM/DD/YYYY').format('YYYY-MM-DD')}`, newItem);
      });
      let dataFiltered =  [...new Map(map).values()];
      let functionItem = [];
      const arrayLoop = _.chunk(dataFiltered, 200);
      arrayLoop.map((item) => {
        functionItem.push(
          API.post('unileverAPI', '/invoices/tracking/validateInvoice', {
            body: {
              jsonData: item,
              type: 'invoice_tracking'
            },
          })
        )
      })
      return Promise.all(functionItem)
        .then((result) => {
          let newItemsFinal = [];
          let itemsForUpdatedFinal = [];
          for (let r of result) {
            const { newItems, itemsForUpdated } = r.data;
            newItemsFinal = newItemsFinal.concat(newItems);
            itemsForUpdatedFinal = itemsForUpdatedFinal.concat(itemsForUpdated);
          }
          setFileList([]);
          setUploading(false);
          setNewInvoiceData(newItemsFinal);
          setInvoiceForUpdatedData(itemsForUpdatedFinal);
          let aviNewInvoiceData = [];
          let aviInvoiceForUpdateData = [];
          for (let item of newItemsFinal) {
            if (checkValidRow(item)) {
              aviNewInvoiceData.push(item);
            }
          }
          for (let item of itemsForUpdatedFinal) {
            if (checkValidRow(item)) {
              aviInvoiceForUpdateData.push(item)
            }
          }
          setAvailableNewInvoiceData(aviNewInvoiceData)
          setAvailableInvoiceForUpdatedData(aviInvoiceForUpdateData)
          setIsFileUploaded(true)
        }).catch((error) => {
          setFileList([]);
          const errorCode = _.get(error, 'response.data.errorCode', undefined)
          const errorMessage = errorCode ? ErrorHandlers.generateErrorMessage(error, t(`admin.errorCode.uploadInvoice.${errorCode}`)) : _.get(error, 'message', t('admin.uploadInvoice.submitFailedMsg'))
          setUploading(false)
          Modal.error({
            centered: true,
            content: errorMessage
          });  
        });
    };
    reader.readAsBinaryString(f);
  }

  const checkValidRow = (item) => {
    if (
      ((item.vendor_id && !item.vendor_id.validate) || !item.vendor_id) &&
      ((item.vd_name &&        !item.vd_name.validate) || !item.vd_name) &&
      ((item.vd_tax_code &&    !item.vd_tax_code.validate) || !item.vd_tax_code) &&
      ((item.invoice_number && !item.invoice_number.validate) || !item.invoice_number) &&
      ((item.scan_id &&     !item.scan_id.validate) || !item.scan_id) &&
      ((item.invoice_date &&     !item.invoice_date.validate) || !item.invoice_date) &&
      ((item.due_date &&     !item.due_date.validate) || !item.due_date) &&
      ((item.invoice_amount &&     !item.invoice_amount.validate) || !item.invoice_amount) &&
      ((item.currency &&     !item.currency.validate) || !item.currency) &&
      ((item.assigned_to &&     !item.assigned_to.validate) || !item.assigned_to) &&
      ((item.invoice_status_id &&     !item.invoice_status_id.validate) || !item.invoice_status_id) &&
      ((item.invoice_reason_id &&     !item.invoice_reason_id.validate) || !item.invoice_reason_id) &&
      ((item.status_date &&     !item.status_date.validate) || !item.status_date) &&
      ((item.po_number &&     !item.po_number.validate) || !item.po_number)
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="uploadInvoice-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t('admin.uploadInvoice.title')}
          subTitle={t('admin.uploadInvoice.subTitle')}
        ></PageHeader>
      </Affix>
      <Divider />
      <DropZone
        selectFileCallBack={(file) => handleSelectFile(file)}
        isUploading={uploading}
      />
      <PreviewModal
        visible={isFileUploaded}
        onOk={submitRequest}
        onCancel={() => setIsFileUploaded(false)}
        newInvoiceData={newInvoiceData}
        invoiceForUpdatedData={invoiceForUpdatedData}
        availableNewInvoiceData={availableNewInvoiceData}
        availableInvoiceForUpdatedData={availableInvoiceForUpdatedData}
      />
    </div>
  )
}

export default UploadInvoice
