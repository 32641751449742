import { EditOutlined, HistoryOutlined } from "@ant-design/icons";
import { Affix, Button, DatePicker, Divider, Form, Input, Modal, PageHeader, Select, Table } from "antd";
import { API } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorHandlers } from "../../utils/index";
import HistoryModal from "./../historyModel/historyModal";
import EditableCell from "./EditableCell";
import "./index.scss";
import PreviewModal from "./previewModal";

const { Option } = Select;
const { RangePicker } = DatePicker;

let timeout;
let currentValue;

function fetchInvoiceMasterData(type, value, lang, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function callApi() {
    let body = {
      invoiceType: type,
      searchString: value,
      lang: lang,
    };
    return API.post("unileverAPI", "/invoices/masterData/search", {
      body,
    }).then((d) => {
      if (currentValue === value) {
        const { data } = d;
        callback(data);
      }
    });
  }
  timeout = setTimeout(callApi, 300);
}

function fetchVendor(field, value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function callApi() {
    let body = {
      invoiceType: "vendor",
      vendorField: field,
      searchString: value,
    };
    return API.post("unileverAPI", "/invoices/masterData/search", {
      body,
    }).then((d) => {
      if (currentValue === value) {
        const { data } = d;
        callback(data);
      }
    });
  }
  timeout = setTimeout(callApi, 300);
}

function InvoiceTracking({ language, editTable }) {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState([]);
  const [selectedInvoiceStatusOptions, setSelectedInvoiceStatusOptions] =
  useState([]);
  const [selectedInvoiceReason, setSelectedInvoiceReason] = useState([]);
  const [selectedInvoiceReasonOptions, setSelectedInvoiceReasonOptions] =
  useState([]);
  const [selectedVendorSAPCode, setSelectedVendorSAPCode] = useState([]);
  const [selectedVendorName, setSelectedVendorName] = useState([]);
  const [selectedVendorSAPCodeOptions, setSelectedVendorSAPCodeOptions] =
    useState([]);
  const [selectedVendorNameOptions, setSelectedVendorNameOptions] = useState(
    []
  );

  const [selectedVendorTaxCode, setSelectedVendorTaxCode] = useState([]);
  const [selectedVendorTaxCodeOptions, setSelectedVendorTaxCodeOptions] =
    useState([]);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(null);
  const [selectedScanID, setSelectedScanID] = useState(null);
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState();
  const [selectedDueDate, setSelectedDueDate] = useState();
  const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
  const [selectedStatusDate, setSelectedStatusDate] = useState();
  const [selectedPONumber, setSelectedPONumber] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [editingRowParams, setEditingRowParams] = useState({});

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  });
  const [sorter, setSorter] = useState(null);
  const [filter, setFilter] = useState({
    invoice_type: ["invoice_tracking", "invoice_missing"],
  });
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [historyModalIsOpenState, setHistoryModalIsOpenState] = useState(false);
  const [historyDataState, setHistoryDataState] = useState([]);
  const [duplicatedCombination, setDuplicatedCombination] = useState([]);

  const resetPaging = () => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 100,
    })
  }

  const edit = (record) => {
    form.setFieldsValue({
      vd_sap_code: "",
      vd_name: "",
      vd_tax_code: "",
      invoice_number: "",
      scan_id: "",
      invoice_date: "",
      due_date: "",
      invoice_amount: "",
      currency: "",
      assigned_to: "",
      invoice_status: "",
      invoice_reason: "",
      status_date: "",
      notes: "",
      ...record,
    });
    setEditingKey(record.uuid);
    setEditingRowParams({
      ...editingRowParams,
      invoice_status_id: record.invoice_status_id,
      invoice_reason_id: record.invoice_reason_id,
      uuid: record.uuid,
      invoice_date: record.invoice_date,
      due_date: record.due_date,
      status_date: record.status_date,
    });
  };

  const columns = [
    {
      title: t("admin.viewInvoiceTracking.columnHeader.vendorId.title"),
      sorter: true,
      dataIndex: "vd_sap_code",
      fixed: "left",
      editable: true,
      children: [
        {
          title: (
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              showSearch
              value={selectedVendorSAPCode}
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.vendorId.placeholder"
              )}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => {
                handleCancelEdit()
                if (value && value.trim() && value.trim().length >= 3) {
                  fetchVendor("vd_sap_code", value.trim(), (data) =>
                    setSelectedVendorSAPCodeOptions(data)
                  );
                } else {
                  setSelectedVendorSAPCodeOptions([]);
                }
              }}
              onChange={(value) => {
                setSelectedVendorSAPCode(value);
                let currentFilter = {
                  ...filter,
                  vd_sap_code: value.length == 0 ? null : value,
                };
                resetPaging();
                fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
                setFilter(currentFilter);
              }}
            >
              {selectedVendorSAPCodeOptions.map((i) => (
                <Option key={i.vd_sap_code}>{i.vd_sap_code}</Option>
              ))}
            </Select>
          ),
          width: 150,
          dataIndex: "vd_sap_code",
          fixed: "left",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.vendorName.title"),
      dataIndex: "vd_name",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              showSearch
              value={selectedVendorName}
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.vendorName.placeholder"
              )}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => {
                handleCancelEdit()
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  fetchVendor("vd_name", value.trim(), (data) =>
                    setSelectedVendorNameOptions(data)
                  );
                } else {
                  setSelectedVendorNameOptions([]);
                }
              }}
              onChange={(value) => {
                setSelectedVendorName(value);
                let currentFilter = {
                  ...filter,
                  vd_name: value.length == 0 ? null : value,
                };
                resetPaging();
                fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
                setFilter(currentFilter);
              }}
            >
              {selectedVendorNameOptions.map((i) => (
                <Option key={i.vd_name}>{i.vd_name}</Option>
              ))}
            </Select>
          ),
          width: 350,
          dataIndex: "vd_name",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.vendorTaxCode.title"),
      dataIndex: "vd_tax_code",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              showSearch
              value={selectedVendorTaxCode}
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.vendorTaxCode.placeholder"
              )}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => {
                handleCancelEdit()
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  fetchVendor("vd_tax_code", value.trim(), (data) => {
                    let arrayItems = _.uniq(data.map((i) => i.vd_tax_code ));
                    setSelectedVendorTaxCodeOptions(arrayItems)
                  });
                } else {
                  setSelectedVendorTaxCodeOptions([]);
                }
              }}
              onChange={(value) => {
                setSelectedVendorTaxCode(value);
                let currentFilter = {
                  ...filter,
                  vd_tax_code: value.length == 0 ? null : value,
                };
                resetPaging();
                fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
                setFilter(currentFilter);
              }}
            >
              {selectedVendorTaxCodeOptions.map((i) => (
                <Option key={i}>{i}</Option>
              ))}
            </Select>
          ),
          width: 180,
          dataIndex: "vd_tax_code",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.invoiceNumber.title"),
      dataIndex: "invoice_number",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Input
              allowClear
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.invoiceNumber.placeholder"
              )}
              value={selectedInvoiceNumber}
              onChange={(e) => {
                handleCancelEdit()
                const { value } = e.target;
                setSelectedInvoiceNumber(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;

                  function callApi() {
                    let currentFilter = _.merge(filter, {
                      invoice_number: value.trim(),
                    });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 180,
          dataIndex: "invoice_number",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.scanId.title"),
      dataIndex: "scan_id",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Input
              allowClear
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.scanId.placeholder"
              )}
              value={selectedScanID}
              onChange={(e) => {
                handleCancelEdit()
                const { value } = e.target;
                setSelectedScanID(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;
                  function callApi() {
                    let currentFilter = _.merge(filter, { scan_id: value.trim() });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 220,
          dataIndex: "scan_id",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.invoiceDate.title"),
      dataIndex: "invoice_date",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <RangePicker
              value={selectedInvoiceDate}
              onChange={(value) => {
                handleCancelEdit()
                setSelectedInvoiceDate(value);
                if (timeout) {
                  clearTimeout(timeout);
                  timeout = null;
                }
                currentValue = value;

                function callApi() {
                  let currentFilter = _.merge(filter, {
                    invoice_date_form:
                      value && value[0] ? value[0].format("YYYY-MM-DD") : null,
                    invoice_date_to:
                      value && value[1] ? value[1].format("YYYY-MM-DD") : null,
                  });
                  setFilter(currentFilter);
                  resetPaging();
                  if (currentValue === value) {
                    fetchData(
                      { current: 1, pageSize: 10 },
                      sorter,
                      currentFilter
                    );
                  }
                }
                timeout = setTimeout(callApi, 300);
              }}
              format="DD/MM/YYYY"
            />
          ),
          width: 280,
          dataIndex: "invoice_date",
          render: (item) =>
            `${item != null ? moment(item).format("DD/MM/YYYY") : ""}`,
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.dueDate.title"),
      dataIndex: "due_date",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <RangePicker
              value={selectedDueDate}
              onChange={(value) => {
                handleCancelEdit()
                setSelectedDueDate(value);
                if (timeout) {
                  clearTimeout(timeout);
                  timeout = null;
                }
                currentValue = value;

                function callApi() {
                  let currentFilter = _.merge(filter, {
                    due_date_form:
                      value && value[0] ? value[0].format("YYYY-MM-DD") : null,
                    due_date_to:
                      value && value[1] ? value[1].format("YYYY-MM-DD") : null,
                  });
                  setFilter(currentFilter);
                  resetPaging();
                  if (currentValue === value) {
                    fetchData(
                      { current: 1, pageSize: 10 },
                      sorter,
                      currentFilter
                    );
                  }
                }
                timeout = setTimeout(callApi, 300);
              }}
              format="DD/MM/YYYY"
            />
          ),
          width: 280,
          dataIndex: "due_date",
          render: (item) =>
            `${item != null ? moment(item).format("DD/MM/YYYY") : ""}`,
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.invoiceAmount.title"),
      dataIndex: "invoice_amount",
      inputType: "number",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Input
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.invoiceAmount.placeholder"
              )}
              style={{ width: "100%" }}
              value={selectedInvoiceAmount}
              type="number"
              onChange={(e) => {
                handleCancelEdit()
                let { value } = e.target;
                setSelectedInvoiceAmount(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;

                  function callApi() {
                    let currentFilter = _.merge(filter, {
                      invoice_amount: value.trim(),
                    });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 200,
          dataIndex: "invoice_amount",
          render: (item) =>
            `${item != null ? item.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}`
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.currency.title"),
      dataIndex: "currency",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Input
              allowClear
              value={selectedCurrency}
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.currency.placeholder"
              )}
              onChange={(e) => {
                handleCancelEdit()
                const { value } = e.target;
                setSelectedCurrency(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;

                  function callApi() {
                    let currentFilter = _.merge(filter, { currency: value.trim() });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 150,
          dataIndex: "currency",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.assignedTo.title"),
      dataIndex: "assigned_to",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Input
              allowClear
              value={selectedAssignedTo}
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.assignedTo.placeholder"
              )}
              onChange={(e) => {
                handleCancelEdit()
                const { value } = e.target;
                setSelectedAssignedTo(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;

                  function callApi() {
                    let currentFilter = _.merge(filter, { assigned_to: value });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 100,
          dataIndex: "assigned_to",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.invoiceStatus.title"),
      dataIndex: "invoice_status",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              showSearch
              value={selectedInvoiceStatus}
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.invoiceStatus.placeholder"
              )}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => {
                handleCancelEdit()
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  fetchInvoiceMasterData(
                    "invoice_status",
                    value.trim(),
                    language,
                    (data) => {
                      let arrayItems = _.uniq(data.map((i) => language == "en" ? i.en : i.vi ));
                      setSelectedInvoiceStatusOptions(arrayItems)
                    }
                  );
                } else {
                  setSelectedInvoiceStatusOptions([]);
                }
              }}
              onChange={(value) => {
                setSelectedInvoiceStatus(value);
                let currentFilter = {
                  ...filter,
                  invoice_status_id: value.length == 0 ? null : value,
                };
                resetPaging();
                fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
                setFilter(currentFilter);
              }}
            >
              {selectedInvoiceStatusOptions.map((i) => (
                <Option key={i}>{i}</Option>
              ))}
            </Select>
          ),
          width: 200,
          dataIndex: "invoice_status",
        },
      ],
    },
    {
      title: t('admin.viewInvoiceTracking.columnHeader.reason.title'),
      dataIndex: "invoice_reason",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              showSearch
              value={selectedInvoiceReason}
              placeholder={"Reason"}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => {
                handleCancelEdit()
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  fetchInvoiceMasterData(
                    "invoice_reason",
                    value.trim(),
                    language,
                    (data) => {
                      let arrayItems = _.uniq(data.map((i) => language == "en" ? i.en : i.vi ));
                      setSelectedInvoiceReasonOptions(arrayItems)
                    }
                  );
                } else {
                  setSelectedInvoiceReasonOptions([]);
                }
              }}
              onChange={(value) => {
                setSelectedInvoiceReason(value);
                let currentFilter = {
                  ...filter,
                  invoice_reason_id: value.length == 0 ? null : value,
                };
                resetPaging();
                fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
                setFilter(currentFilter);
              }}
            >
              {selectedInvoiceReasonOptions.map((i) => (
                <Option key={i}>{i}</Option>
              ))}
            </Select>
          ),
          width: 200,
          dataIndex: "invoice_reason",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.statusDate.title"),
      dataIndex: "status_date",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <RangePicker
              value={selectedStatusDate}
              onChange={(value) => {
                handleCancelEdit()
                setSelectedStatusDate(value);
                if (timeout) {
                  clearTimeout(timeout);
                  timeout = null;
                }
                currentValue = value;

                function callApi() {
                  let currentFilter = _.merge(filter, {
                    status_date_form:
                      value && value[0] ? value[0].format("YYYY-MM-DD") : null,
                    status_date_to:
                      value && value[1] ? value[1].format("YYYY-MM-DD") : null,
                  });
                  setFilter(currentFilter);
                  resetPaging();
                  if (currentValue === value) {
                    fetchData(
                      { current: 1, pageSize: 10 },
                      sorter,
                      currentFilter
                    );
                  }
                }
                timeout = setTimeout(callApi, 300);
              }}
              format="DD/MM/YYYY"
            />
          ),
          width: 280,
          dataIndex: "status_date",
          render: (item) =>
            `${item != null ? moment.utc(item).format("DD/MM/YYYY") : ""}`,
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.poNumber.title"),
      dataIndex: "po_number",
      editable: true,
      sorter: true,
      children: [
        {
          title: (
            <Input
              allowClear
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.poNumber.placeholder"
              )}
              value={selectedPONumber}
              onChange={(e) => {
                handleCancelEdit()
                const { value } = e.target;
                setSelectedPONumber(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;

                  function callApi() {
                    let currentFilter = _.merge(filter, { po_number: value.trim() });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 120,
          dataIndex: "po_number",
        },
      ],
    },
    {
      title: t("admin.viewInvoiceTracking.columnHeader.notes.title"),
      dataIndex: "notes",
      sorter: true,
      editable: true,
      children: [
        {
          title: (
            <Input
              allowClear
              placeholder={t(
                "admin.viewInvoiceTracking.columnHeader.notes.placeholder"
              )}
              value={selectedNotes}
              onChange={(e) => {
                handleCancelEdit()
                const { value } = e.target;
                setSelectedNotes(value);
                if (value != null && (value.trim().length >= 3 || value == '')) {
                  if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  currentValue = value;

                  function callApi() {
                    let currentFilter = _.merge(filter, { notes: value.trim() });
                    setFilter(currentFilter);
                    resetPaging();
                    if (currentValue === value) {
                      fetchData(
                        { current: 1, pageSize: 10 },
                        sorter,
                        currentFilter
                      );
                    }
                  }
                  timeout = setTimeout(callApi, 300);
                }
              }}
            />
          ),
          width: 200,
          dataIndex: "notes",
        },
      ],
    },
    {
      editable: false,
      width: 200,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button type="primary" onClick={handleSubmitValidateEdit}>
              {t('admin.viewInvoiceTracking.save')}
            </Button>
            <Button type="danger" onClick={handleCancelEdit}>
              {t('admin.viewInvoiceTracking.cancel')}
            </Button>
          </>
        ) : (
          <>
            {editTable && 
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            }
            <Button
              onClick={() => handleOpenHistoryModal(record)}
              shape="circle"
              icon={<HistoryOutlined />}
            />
          </>
        );
      },
    },
  ];

  const isEditing = (record) => record.uuid === editingKey;

  const handleSubmitValidateEdit = async () => {
    const row = await form.validateFields();
    setEditingKey("");
    const updatedBody = {
      ...row,
      ...editingRowParams,
    };

    setEditingRowParams(updatedBody)

    API.post("unileverAPI", "/invoices/tracking/validateForEditing", {
      body: _.omit(updatedBody, "invoice_reason", "invoice_status"),
    }).then((res) => {
      if (res.isDuplicateCombo) {
        setDuplicatedCombination([res.compareResult]);
      } else {
        handleCancelEdit();
        fetchData(pagination, sorter, _.merge(filter, { lang: language }));
        Modal.success({
          centered: true,
          content: t("admin.viewInvoiceTracking.editItemModal.successMsg"),
        });
      }
    })
    .catch ((error) => {
      const errorCode = _.get(error, "response.data.errorCode", undefined);
      const errorMessage = errorCode
        ? ErrorHandlers.generateErrorMessage(
            error,
            t(`admin.errorCode.editInvoiceTracking.${errorCode}`)
          )
        : _.get(
            error,
            "message",
            t("admin.viewInvoiceTracking.editItem.errorMsg")
          );
      Modal.error({
        centered: true,
        content: errorMessage,
      });
    })
  };

  const handleSubmitEdit = async () => {
    API.post("unileverAPI", "/invoices/tracking/editInvoice", {
      body: duplicatedCombination[0],
    }).then((res) => {
      handleCancelEdit();
      fetchData(pagination, sorter, _.merge(filter, { lang: language }));
      Modal.success({
        centered: true,
        content: t("admin.viewInvoiceTracking.editItemModal.successMsg"),
      });
    })
    .catch ((error) => {
      const errorCode = _.get(error, "response.data.errorCode", undefined);
      const errorMessage = errorCode
        ? ErrorHandlers.generateErrorMessage(
            error,
            t(`admin.errorCode.uploadInvoice.${errorCode}`)
          )
        : _.get(
            error,
            "message",
            t("admin.viewInvoiceTracking.editItem.errorMsg")
          );
      Modal.error({
        centered: true,
        content: errorMessage,
      });
    })
  };

  const handleCancelEdit = () => {
    setEditingKey("");
    setEditingRowParams({});
    setDuplicatedCombination([]);
  };

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }

    const newCol = {
      ...col,
    };
    if (col.children) {
      if (col.dataIndex === "vd_sap_code") {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              handleSave: (selectedVendor) => {
                form.setFieldsValue({
                  vd_name: selectedVendor.vd_name,
                  vd_tax_code: selectedVendor.vd_tax_code,
                });
              },
            }),
          },
        ];
      } else if (col.dataIndex === "invoice_date") {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              handleSave: (selectedDate) => {
                setEditingRowParams({
                  ...editingRowParams,
                  invoice_date: `${moment(selectedDate).format(
                    "YYYY-MM-DDT00:00:00.000"
                  )}Z`,
                });
              },
            }),
          },
        ];
      } else if (col.dataIndex === "status_date") {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              handleSave: (selectedDate) => {
                setEditingRowParams({
                  ...editingRowParams,
                  status_date: `${moment(selectedDate).format(
                    "YYYY-MM-DDT00:00:00.000"
                  )}Z`,
                });
              },
            }),
          },
        ];
      } else if (col.dataIndex === "due_date") {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              handleSave: (selectedDate) => {
                setEditingRowParams({
                  ...editingRowParams,
                  due_date: `${moment(selectedDate).format(
                    "YYYY-MM-DDT00:00:00.000"
                  )}Z`,
                });
              },
            }),
          },
        ];
      } else if (col.dataIndex === "invoice_status") {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              handleSave: (selectedStatusID, selectedStatusMsg) => {
                setEditingRowParams({
                  ...editingRowParams,
                  invoice_status_id: selectedStatusID,
                  invoice_status: selectedStatusMsg
                });
              },
            }),
          },
        ];
      } else if (col.dataIndex === "invoice_reason") {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              handleSave: (selectedReasonID, selectedReasonMsg) => {
                setEditingRowParams({
                  ...editingRowParams,
                  invoice_reason_id: selectedReasonID,
                  invoice_reason: selectedReasonMsg
                });
              },
            }),
          },
        ];
      } else {
        newCol.children = [
          {
            ...col.children[0],
            onCell: (record) => ({
              record,
              inputType: col.dataIndex === "invoice_amount" ? "number" : "text",
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
            }),
          },
        ];
      }
    }
    return newCol;
  };

  const mergedColumns = columns.map(mapColumns);

  const handleTableChange = (pagination, filters, currentSorter) => {
    setSorter(currentSorter);
    fetchData(pagination, currentSorter, filter);
    setSelectedRow([]);
  };

  const fetchData = (params = {}, sorter = null, filter = null) => {
    setLoading(true);
    let body = {
      currentPage: params.current - 1,
      pageSize: params.pageSize,
    };
    if (sorter != null && sorter.field && sorter.order) {
      body.sortField = sorter.field;
      body.sortOrder = sorter.order == "ascend" ? "asc" : "desc";
    }
    if (filter != null) {
      body = _.merge(body, filter);
    }
    API.post("unileverAPI", "/invoices/getList", { body })
      .then((r) => {
        setLoading(false);
        setData(r.data);
        setPagination({ ...params, total: r.total });
      })
      .catch((e) => {
        setLoading(false);
        Modal.error({
          centered: true,
          content: t(
            "manager.vendorMasterData.pendingRequest.fetchDataFailedMsg"
          ),
        });
      });
  };

  const download = async (invoiceIds = []) => {
    setDownloadLoading(true);
    let body = {};
    if (invoiceIds.length == 0) {
      body = {
        sortField: sorter != null && sorter.field ? sorter.field : null,
        sortOrder:
          sorter != null && sorter.order
            ? sorter.order == "ascend"
              ? "asc"
              : "desc"
            : null,
        invoice_type: ["invoice_tracking", "invoice_missing"],
        lang: language,
        vd_sap_code:
          selectedVendorSAPCode.length == 0 ? null : selectedVendorSAPCode,
        vd_name: selectedVendorName.length == 0 ? null : selectedVendorName,
        vd_tax_code:
          selectedVendorTaxCode.length == 0 ? null : selectedVendorTaxCode,
        invoice_number: selectedInvoiceNumber,
        scan_id: selectedScanID,
        invoice_date_form:
          selectedInvoiceDate && selectedInvoiceDate[0]
            ? selectedInvoiceDate[0].format("YYYY-MM-DD")
            : null,
        invoice_date_to:
          selectedInvoiceDate && selectedInvoiceDate[1]
            ? selectedInvoiceDate[1].format("YYYY-MM-DD")
            : null,
        due_date_form:
          selectedDueDate && selectedDueDate[0]
            ? selectedDueDate[0].format("YYYY-MM-DD")
            : null,
        due_date_to:
          selectedDueDate && selectedDueDate[1]
            ? selectedDueDate[1].format("YYYY-MM-DD")
            : null,
        invoice_amount: selectedInvoiceAmount,
        currency: selectedCurrency,
        assigned_to: selectedAssignedTo,
        invoice_status_id:
          selectedInvoiceStatus.length == 0 ? null : selectedInvoiceStatus,
        invoice_reason_id:
          selectedInvoiceReason.length == 0 ? null : selectedInvoiceReason,
        status_date_form:
          selectedStatusDate && selectedStatusDate[0]
            ? selectedStatusDate[0].format("YYYY-MM-DD")
            : null,
        status_date_to:
          selectedStatusDate && selectedStatusDate[1]
            ? selectedStatusDate[1].format("YYYY-MM-DD")
            : null,
        po_number: selectedPONumber,
        notes: selectedNotes,
      };
    } else {
      body = {
        lang: language,
        invoice_ids: invoiceIds,
      };
    }

    await API.post("unileverAPI", "/invoices/download", {
      responseType: "arrayBuffer",
      response: true,
      body,
    })
      .then((response) => {
        var raw = window.atob(response.data);
        var uInt8Array = new Uint8Array(raw.length);
        for (var i = 0; i < raw.length; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        const link = document.createElement("a");
        const blob = new Blob([uInt8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `invoice_tracking_${moment().format("YYYYMMDD")}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadLoading(false);
      })
      .catch((error) => {
        Modal.error({
          centered: true,
          content: t("admin.uploadVendor.downloadFailedMsg"),
        });
        setDownloadLoading(false);
      });
  };

  useEffect(() => {
    fetchData(pagination, sorter, _.merge(filter, { lang: language }));
  }, [language]);

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys);
    },
  };
  const handleOpenHistoryModal = (record) => {
    let body = { uuid: _.get(record, "uuid") };
    return API.post("unileverAPI", "/invoices/tracking/getHistory", { body })
      .then((r) => {
        setLoading(false);
        let { data } = r;
        data = data.map((d, index) => {
          return {
            scan_id: _.get(d, "scan_id"),
            invoice_status_id: atob(_.get(d, "invoice_status_id")).replace(
              "_invoice_status",
              ""
            ),
            invoice_reason_id: atob(_.get(d, "invoice_reason_id")).replace(
              "_invoice_reason",
              ""
            ),
            invoice_status: _.get(d, "invoice_status"),
            invoice_reason:_.get(d, "invoice_reason"),
            notes: _.get(d, "notes"),
            updated_date: _.get(d, "updated_date"),
          };
        });
        data =  _.orderBy(data, ['updated_date'], ['desc']);
        data = data.map((d, index) => {
          return { ...d, version: data.length - index,}
        });
        setHistoryModalIsOpenState(true);
        setHistoryDataState(data);
      })
      .catch((e) => {
        setLoading(false);
        Modal.error({
          centered: true,
          content: t(
            "manager.vendorMasterData.pendingRequest.fetchDataFailedMsg"
          ),
        });
      });
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t("admin.viewInvoiceTracking.title")}
          extra={[
            <Button
              key="1"
              primary
              disabled={selectedRow.length == 0 || loading || downloadLoading}
              onClick={() => download(selectedRow)}
            >
              {t('admin.viewInvoiceTracking.download')}
            </Button>,
            <Button
              key="2"
              primary
              disabled={downloadLoading}
              onClick={() => download()}
            >
              {t('admin.viewInvoiceTracking.downloadAll')}
            </Button>,
          ]}
        ></PageHeader>
      </Affix>
      <Divider />
      <div>
        <div>
          <div style={{'width': '130px', 'display': 'inline-block'}}>{t("admin.viewInvoiceTracking.filter.vendorId.title")}</div>
          <Select
            style={{ width: "20%", marginLeft: 20 }}
            showSearch
            mode="multiple"
            value={selectedVendorSAPCode}
            placeholder={t(
              "admin.viewInvoiceTracking.columnHeader.vendorId.placeholder"
            )}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => {
              handleCancelEdit()
              if (value != null && (value.trim().length >= 3 || value == '')) {
                fetchVendor("vd_sap_code", value.trim(), (data) =>
                  setSelectedVendorSAPCodeOptions(data)
                );
              } else {
                setSelectedVendorSAPCodeOptions([]);
              }
            }}
            onChange={(value) => {
              setSelectedVendorSAPCode(value);
              let currentFilter = {
                ...filter,
                vd_sap_code: value.length == 0 ? null : value,
              };
              resetPaging();
              fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
              setFilter(currentFilter);
            }}
          >
            {selectedVendorSAPCodeOptions.map((i) => (
              <Option key={i.vd_sap_code}>{i.vd_sap_code}</Option>
            ))}
          </Select>
        </div>
        <div style={{ marginTop: 10, marginBottom: 30 }}>
          <div style={{'width': '130px', 'display': 'inline-block'}}>{t("admin.viewInvoiceTracking.filter.vendorName.title")}</div>
          <Select
            style={{ width: "20%", marginLeft: 20 }}
            mode="multiple"
            showSearch
            value={selectedVendorName}
            placeholder={t(
              "admin.viewInvoiceTracking.columnHeader.vendorName.placeholder"
            )}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => {
              handleCancelEdit()
              if (value != null && (value.trim().length >= 3 || value == '')) {
                fetchVendor("vd_name", value.trim(), (data) =>
                  setSelectedVendorNameOptions(data)
                );
              } else {
                setSelectedVendorNameOptions([]);
              }
            }}
            onChange={(value) => {
              setSelectedVendorName(value);
              let currentFilter = {
                ...filter,
                vd_name: value.length == 0 ? null : value,
              };
              resetPaging();
              fetchData({ current: 1, pageSize: 10 }, sorter, currentFilter);
              setFilter(currentFilter);
            }}
          >
            {selectedVendorNameOptions.map((i) => (
              <Option key={i.vd_name}>{i.vd_name}</Option>
            ))}
          </Select>
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          className="view-invoice-table"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          size="small"
          scroll={{ x: 1500 }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          bordered
          columns={mergedColumns}
          rowKey={(record) => record.uuid}
          rowClassName="editable-row"
          dataSource={data}
          loading={loading}
          pagination={{
            ...pagination, 
            locale: { items_per_page: "" },
            showTotal: (total) => `${t("total")} ${total}`,
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </Form>
      <HistoryModal
        visible={historyModalIsOpenState}
        onCancel={() => setHistoryModalIsOpenState(false)}
        historyData={historyDataState}
      />
      <PreviewModal
        visible={duplicatedCombination.length > 0}
        onOk={handleSubmitEdit}
        onCancel={handleCancelEdit}
        data={duplicatedCombination}
        originalPayload={editingRowParams}
      />
    </div>
  );
}

export default InvoiceTracking;
