
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Modal } from "antd";
import Text from "antd/lib/typography/Text";
import { Storage } from "aws-amplify";
import Editor from "ckeditor5-custom-build";
import Parser from "html-react-parser";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUtils } from "../../utils/hooks";
import { S3_TEMP_BASE } from "./../../configs/constants";
import "./ckeditor.scss";
import MyUploadAdapter from "./CustomImageUploadAdapter";
// import InsertFile from "./customPlugins/insertFile";

export const MyCKEditor = (props) => {
  const { useTraceUpdate } = useUtils();
  useTraceUpdate("ckeditor", props);

  const {
    height,
    width,
    readOnly,
    model = "",
    setModel,
    workingDocId,
    maxCharacters,
    setShouldDisable
  } = props;
  const namePrefix = S3_TEMP_BASE + "/" + workingDocId + "/";
  const { t, i18n } = useTranslation();
  const [_characters, setCharacters] = useState(0);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [isCloseToLimit, setIsCloseToLimit] = useState(false);
  // const [_characters, setCharacters] = useState(0);

  const handleMessage = (messCode) => {

    let final ;

    switch (messCode) {
      case "FILE_EXTENSION":
        final = t('admin.questionAndAnswerModal.FILE_EXTENSION')
        break;
      case "FILE_SIZE":
        final = t('admin.questionAndAnswerModal.FILE_SIZE')
        break;
      case "IMAGE_EXTENSION":
        final = t('admin.questionAndAnswerModal.IMAGE_EXTENSION')
        break;
      case "IMAGE_SIZE":
        final = t('admin.questionAndAnswerModal.IMAGE_SIZE')
        break;
      default:
        final = t('admin.qna.addSection.errorMsg')
        break;
    }
    Modal.error({
      centered: true,
      content: final,
    });
  }


  function MyCustomUploadAdapterPlugin(editor)  {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      let storage = Storage;
      
      return new MyUploadAdapter(loader, storage, namePrefix, editor.t, {handleMessage:handleMessage});
    };
  }



  return (
    <div id="my-ckeditor">
      {!readOnly ? (
        <div onDrop={(e)=>{}} >
        <CKEditor
          style={{ height: height }}
          editor={Editor}
          data={_.isNil(model) ? "" : model}
          config={{
            extraPlugins: [MyCustomUploadAdapterPlugin ],
            link: {
              defaultProtocol: 'https://',
              decorators: {
                  openInNewTab: {
                    mode: 'manual',
                    label: 'Open in a new tab',
                    defaultValue: true,			
                    attributes: {
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    }
                  }
                }
            },
            wordCount: {
              displayCharacters : true,
              onUpdate: stats => {
                  // Prints the current content statistics.
                  setIsLimitExceeded(stats.characters > maxCharacters)
                  setIsCloseToLimit(!isLimitExceeded && stats.characters > maxCharacters * .8)
                  setCharacters(stats.characters)
                }
            },

            InsertFile:{
              storage: Storage,
              namePrefix : namePrefix,
              handleMessageSvc: {handleMessage : handleMessage}
            },
            indentBlock: {
              offset: 1,
              unit: 'em'
            },
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "|",
                "outdent",
                "indent",
                '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
                "|",
                "uploadImage",
                'insertFile',
                "blockQuote",
                "undo",
                "redo",
              ],
            },
            image: {
              toolbar: [
                // "imageStyle:full",
                // "|",
                "imageTextAlternative",
              ],
            },
            table: {
              contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
          }}
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                height,
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            let _data = editor.getData();
              setShouldDisable(isLimitExceeded) ;
              setTimeout(setModel(_data), 2000);

          }}
          onBlur={(event, editor) => {
          }}
          onFocus={(event, editor) => {
          }}
          
        />
        {/* <Progress percent={_characters/maxCharacters*100} status={_characters>maxCharacters ? 'exception' : 'active'} width={80} /> */}
        {_characters>maxCharacters?  <Text type="danger">
        {t('admin.questionAndAnswerModal.maxSize', {
          max: maxCharacters,
        })}
        </Text>: ''}
        </div>
      ) : (
        <div
          // dangerouslySetInnerHTML={{ __html: `${}` }}
          disabled
          style={{
            padding: "10px",
            height: height,
            overflow: "scroll",
            border: "1px solid gray",
          }}
        > {Parser(model)}</div>
      )}
    </div>
  );
};
