import { Modal } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AntInput } from "./createAntFields";


const ChangeKeyEmailForm = ({ handleSubmit, values, submitCount }) => {
  const { t, i18n } = useTranslation();

  const validateNewEmail = (value) => {
    let errors;
    if (!value || value.trim().length == 0) {
      errors = t("vendor.settings.keyEmail.changeModal.missingKeyEmail");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errors = t('vendor.settings.keyEmail.changeModal.invalidEmailMsg')
    }

    return errors;
  };

  return (
    <Form className="form-container" onSubmit={handleSubmit}>
      <Field
        component={AntInput}
        name="newEmail"
        type="text"
        label={t("manager.settings.email.changeModal.newEmail")}
        validate={validateNewEmail}
        submitCount={submitCount}
        hasFeedback
        maxLength={500}
      />
    </Form>
  );
};

function ChangeKeyEmailModal(props) {
  const { t, i18n } = useTranslation();
  const formikRef = useRef();

  const initialValues = {
    newEmail: "",
  };

  const handleSubmit = (formProps) => {
    const updatedBody = {
      emailToUpdate: formProps.newEmail,
      isAdditionalEmail: false
    }
    props.onSubmitCb(updatedBody);
    setTimeout(() => {
      formikRef.current?.resetForm();
    }, 500);
  };

  const handleOkClick = () => {
    formikRef.current?.handleSubmit();
  };

  const handleCancelClick = () => {
    formikRef.current?.resetForm();
    props.onCancelCb();
  };
  return (
    <Modal
      {...props}
      destroyOnClose={true}
      width={"70vw"}
      title={t("manager.settings.email.changeModal.title")}
      onOk={handleOkClick}
      okText={t("vendor.settings.keyEmail.changeModal.submitBtn")}
      cancelText={t("vendor.settings.keyEmail.changeModal.cancelBtn")}
      onCancel={handleCancelClick}
      maskClosable={false}
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={ChangeKeyEmailForm}
      />
      <div className="incorrect-records-msg"  style={{ fontSize: '1.1em'}}>
        {t("vendor.settings.keyEmail.changeModal.footerMsg1")}
      </div>
    </Modal>
  );
}

export default ChangeKeyEmailModal;
