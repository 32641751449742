import {
  Affix,
  Divider,
  PageHeader,
  Input,
  Modal,
  List,
  Typography,
  Card,
  Collapse,
  Space,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "aws-amplify";
import { ErrorHandlers } from "../../../../utils/index";
import _ from "lodash";
import "./qnaList.scss";
import { useHistory } from "react-router";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router";
import Parser from "html-react-parser";
import latinize from "latinize";
import DetailQuestionModal from "./detailQuestionModal";

const { Panel } = Collapse;

function QuestionAndAnswerList(props) {
  const { t, i18n } = useTranslation();
  let { sectionID } = useParams();
  const [detailQuestionModalVisible, setDetailQuestionModalVisible] = useState({
    isOpen: false,
    selectedQuestion: null,
  });
  const [selectedSection, setSelectedSection] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const history = useHistory();

  useEffect(() => {
    getSectionData();
    getSearchResult(pagination);
  }, []);

  const handleTableChange = (currPage, pageSize) => {
    const newPaginationPayload = {
      ...pagination,
      current: currPage,
      pageSize: pageSize,
    };
    setPagination(newPaginationPayload);
    getSearchResult(newPaginationPayload);
  };

  const getSectionData = () => {
    setLoadingList(true);
    API.get("unileverAPI", "/qna/sections", {
      queryStringParameters: {
        currentPage: 0,
        pageSize: 1,
        sectionID: sectionID,
      },
    })
      .then((res) => {
        if (res.result.data.length > 0) {
          setSelectedSection(res.result.data[0]);
        }
      })
      .catch((error) => {
        setLoadingList(false);
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const getSearchResult = (pagination, searchValue) => {
    setLoadingList(true);
    API.get("unileverAPI", "/qna/section/details", {
      queryStringParameters: {
        currentPage: pagination.current - 1,
        pageSize: pagination.pageSize,
        ...(searchValue && { searchValue: searchValue }),
        lang: i18n.language,
        sectionID: sectionID,
      },
    })
      .then((res) => {
        setLoadingList(false);
        setPagination({ ...pagination, total: res.result.total });
        setSearchResult(res.result.data);
      })
      .catch((error) => {
        setLoadingList(false);
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const resetPaging = () => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 100,
    });
  };

  const handleDetailQuestionModalShow = (question) => {
    setDetailQuestionModalVisible({
      isOpen: true,
      selectedQuestion: question,
    });
  };

  const handleDetailQuestionModalCancel = () => {
    setDetailQuestionModalVisible({
      isOpen: false,
      selectedQuestion: null,
    });
  };

  const handleSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearchString(value);
    if (value.length >= 3 || value == "") {
      setIsSearching(true);
      getSearchResult(
        {
          ...pagination,
          current: 1,
        },
        value
      );
      resetPaging();
      if (value == "") {
        if (value == "") {
          setIsSearching(false);
        }
      }
    }
  }, 500);

  return (
    <div className="site-page-header-ghost-wrapper vendor-qna-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          onBack={() => window.history.back()}
          title={
            i18n.language === "en"
              ? selectedSection?.section_en
              : selectedSection?.section_vi
          }
          subTitle=""
        ></PageHeader>
      </Affix>
      <Divider />
      <Input
        size="large"
        placeholder={t("vendor.qna.section.search.placeholder")}
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        allowClear
      />
      {isSearching && (
        <div className="search-result-lbl">
          {t("vendor.qna.section.searchResult")}
        </div>
      )}
      <List
        header={null}
        footer={null}
        size="large"
        className="list-qna"
        rowKey={(item) => item.uuid}
        bordered
        dataSource={searchResult}
        loading={loadingList}
        renderItem={(item) => {
          return (
            <div>
              {item.qna.map((q) => {
                if (isSearching) {
                  return (
                    <Collapse bordered={false} activeKey={["1"]}>
                      <Panel
                        header={
                          <p className="matching-question">
                            <Highlighter
                              highlightClassName="highlight-word"
                              searchWords={[searchString]}
                              autoEscape={false}
                              sanitize={latinize}
                              textToHighlight={
                                i18n.language === "en"
                                  ? q.question_en
                                  : q.question_vi
                              }
                            />
                          </p>
                        }
                        key="1"
                      >
                        <List.Item
                          bordered
                          className="matching-qna-item qna-item"
                          onClick={() => handleDetailQuestionModalShow(q)}
                        >
                          <div>
                            <p className="matching-search-answer">
                              <Highlighter
                                highlightClassName="highlight-word"
                                searchWords={[searchString]}
                                autoEscape={false}
                                sanitize={latinize}
                                textToHighlight={
                                  i18n.language === "en"
                                    ? q.answer_en
                                    : q.answer_vi
                                }
                              />
                            </p>
                          </div>
                        </List.Item>
                      </Panel>
                    </Collapse>
                  );
                }
                return (
                  <Collapse bordered={false}>
                    <Panel
                      header={
                        Parser(
                          i18n.language === "en"
                            ? q.question_en_full
                            : q.question_vi_full
                        )
                      }
                    >
                      <div className="matching-answer qna-item" id="content-wrapper">
                        {Parser(
                          i18n.language === "en"
                            ? q.answer_en_full
                            : q.answer_vi_full
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          );
        }}
        pagination={
          searchResult.length > 0
            ? {
                ...pagination,
                locale: { items_per_page: "" },
                showTotal: (total) => `${t("total")} ${pagination.total}`,
                pageSizeOptions: ["10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: handleTableChange,
              }
            : null
        }
      />
      <DetailQuestionModal
        visible={detailQuestionModalVisible.isOpen}
        data={detailQuestionModalVisible.selectedQuestion}
        onCancel={handleDetailQuestionModalCancel}
      />
    </div>
  );
}

export default QuestionAndAnswerList;
