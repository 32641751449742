import { DownloadOutlined } from '@ant-design/icons';
import { Affix, Button, Divider, Modal, PageHeader, Space } from 'antd';
import { API } from 'aws-amplify';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from "xlsx";
import DropZone from './dropZone';
import PreviewModal from './previewModal';
import './uploadVendor.scss';

function findDuplicatesObjects(objectArr, attr = 'sys_lang') {
  let attrValues = objectArr.map(i => _.get(i, attr))

  let uniqueDuplicated = attrValues.filter((item, index) => attrValues.indexOf(item) != index)
  let uniqueNotDuplicated = _.difference(attrValues, uniqueDuplicated)

  // toObject
  uniqueDuplicated = objectArr.filter(obj => _.includes(uniqueDuplicated, _.get(obj, attr)))
  uniqueDuplicated = _.uniqBy(uniqueDuplicated, attr)
  // toObject
  uniqueNotDuplicated = objectArr.filter(obj => _.includes(uniqueNotDuplicated, _.get(obj, attr)))

  return {uniqueDuplicated, uniqueNotDuplicated}
}

function UploadVendor({user}) {
  const [fileList, setFileList] = useState([])
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [newVendorsData, setNewVendorsData] = useState([])
  const [vendorsForUpdatedData, setVendorsForUpdatedData] = useState([])
  const [availableNewVendorsData, setAvailableNewVendorsData] = useState([])
  const [
    availableVendorsForUpdatedData,
    setAvailableVendorsForUpdatedData,
  ] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (fileList.length > 0) {
      handleUpload()
    }
  }, [fileList])

  const handleSelectFile = (files) => {
    setFileList(files)
  }
  
  const submitRequest = () => {
    if (!submitting) {
      setSubmitting(true)
      const request = {
        body: {
          username: user.username,
        },
      }
      if (availableNewVendorsData.length > 0) {
        request.body.newVendors = availableNewVendorsData
      }
      if (availableVendorsForUpdatedData.length > 0) {
        request.body.vendorsForUpdated = availableVendorsForUpdatedData
      }
      return API.post('unileverAPI', '/vendors/submitMasterData', request)
        .then(() => {
          Modal.success({
            centered: true,
            content: t('admin.uploadVendor.submitSuccessfullyMsg'),
          });
          setIsFileUploaded(false)
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          Modal.error({
            centered: true,
            content: t('admin.uploadVendor.submitFailedMsg'),
          });
        })
    }
  }

  const handleUpload = (headerAtRowTh = 1) => {
    setUploading(true)
    let isValidTemplate = true;
    var f = fileList[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const sheet_header = {
        header: [
            'vd_sap_code',
            'vd_name',
            'vd_tax_code',
            'email',
            'additional_email',
            'booking_type',
            'ap_booker'
        ],
        headerValue: {
          vd_sap_code: 'Vendor SAP code',
          vd_name: 'Vendor name',
          vd_tax_code: 'Vendor tax code',
          email: 'Key Email Address',
          additional_email: 'Additional Email Address',
          booking_type: 'Booking Type',
          ap_booker: 'AP Booker'
        },
        blankrows: false,
      }
      const dataListJson = XLSX.utils.sheet_to_json(ws, sheet_header);
      let headers = dataListJson[headerAtRowTh-1];
      if (headers) {
        if (Object.keys(headers).length !== sheet_header.header.length, Object.keys(headers).length !== Object.keys(sheet_header.headerValue).length) {
          setFileList([]);
          setUploading(false);
          isValidTemplate = false;
        }
        Object.keys(headers).forEach(key =>{
          if (headers[key] !== sheet_header.headerValue[key]) {
            setFileList([]);
            setUploading(false);
            isValidTemplate = false;
          }
        })
      }
      if(!isValidTemplate) {
        Modal.error({
          centered: true,
          content: t(`admin.errorCode.uploadInvoice.INVALID_HEADER_COLUMNS`)
        });
        return;
      }

      for (let i = 0; i< headerAtRowTh; i++) {
        dataListJson.shift();
      }
      let listVendorForProcess, listVendorDuplicate;
      let {uniqueDuplicated, uniqueNotDuplicated} = findDuplicatesObjects(dataListJson, 'vd_sap_code')
      listVendorDuplicate = uniqueDuplicated;
      listVendorForProcess = uniqueNotDuplicated;

      if (listVendorDuplicate.length > 0) {
        setFileList([]);
        setUploading(false);
        let list =      
        `<div> 
          <br/>
          ${listVendorDuplicate.map((data, index) => `<strong> ${index+1}. ${ _.get(data, 'vd_sap_code')} </strong>`).join(' <br/>')}
        </div>`
        let final = t('admin.uploadInvoiceReason.uploadFailedMsg.duplicateOccured.vendor', {
          list: list,
        });
        Modal.error({
          centered: true,
          content: <div dangerouslySetInnerHTML={{__html:`${final}`}}/>
        });
        return;
      }
      let functionItem = [];
      const arrayLoop = _.chunk(listVendorForProcess, 200);
      arrayLoop.map((item) => {
        functionItem.push(
          API.post('unileverAPI', '/vendors/validateMasterData', {
            body: {
              jsonData: item,
            },
          })
        )
      })
      return Promise.all(functionItem)
        .then((result) => {
          let newVendorsFinal = [];
          let vendorsForUpdatedFinal = [];
          for (let r of result) {
            const { newVendors, vendorsForUpdated } = r.data
            newVendorsFinal = newVendorsFinal.concat(newVendors);
            vendorsForUpdatedFinal = vendorsForUpdatedFinal.concat(vendorsForUpdated);
          }
          setFileList([])
          setUploading(false)
          setNewVendorsData(newVendorsFinal);
          setVendorsForUpdatedData(vendorsForUpdatedFinal);
          let aviNewVendorData = [];
          let aviVendorForUpdateData = [];
          for (let item of newVendorsFinal) {
            if (checkValidRow(item)) {
              aviNewVendorData.push(item);
            }
          }
          for (let item of vendorsForUpdatedFinal) {
            if (checkValidRow(item)) {
              aviVendorForUpdateData.push(item)
            }
          }
          setAvailableNewVendorsData(aviNewVendorData)
          setAvailableVendorsForUpdatedData(aviVendorForUpdateData)
          setIsFileUploaded(true)
        }).catch((err) => {
          setFileList([]);
          setUploading(false);
          Modal.error({
            centered: true,
            content: <div dangerouslySetInnerHTML={{__html:`${getCustomErrorMessage(err)}`}}/>,
          });
        });
    };
    reader.readAsBinaryString(f);
  }

  const getCustomErrorMessage = (err) => {
    let message = _.get(err, 'response.data.message');
    let final;
    if (_.includes(message, 'Invalid header value or order of column')||
        _.includes(message, 'Invalid amount of column')) {
      final = t('admin.uploadVendor.uploadFailedMsg.wrongHeader');
    }
    else if(_.includes(message, 'duplicate(s) occurred')) {
      message = message.replace('Error: ', '');
      let jsonMes = JSON.parse(message);
      let uniqueDuplicate = _.get(jsonMes, 'uniqueDuplicate');
      let list =      
      `<div> 
        <br/>
        ${uniqueDuplicate.map((data, index) => `<strong> ${index+1}. ${ _.get(data, 'vd_sap_code')} </strong>`).join(' <br/>')}
      </div>`
      final = t('admin.uploadInvoiceReason.uploadFailedMsg.duplicateOccured.vendor', {
        list: list,
      });
    }
    else {
      final = t('admin.uploadVendor.uploadFailedMsg');
    }
    return final
  }
  const checkValidRow = (item) => {
    if (
      ((item.additional_email && !item.additional_email.validate) || !item.additional_email) &&
      ((item.ap_booker &&        !item.ap_booker.validate) || !item.ap_booker) &&
      ((item.booking_type &&     !item.booking_type.validate) || !item.booking_type) &&
      ((item.email &&            !item.email.validate) || !item.email) &&
      ((item.status &&           !item.status.validate) || !item.status) &&
      ((item.vd_name &&          !item.vd_name.validate) || !item.vd_name) &&
      ((item.vd_sap_code &&      !item.vd_sap_code.validate) || !item.vd_sap_code) &&
      ((item.vd_tax_code &&      !item.vd_tax_code.validate) || !item.vd_tax_code)
    ) {
      return true
    } else {
      return false
    }
  }
  const downloadTemplate = async () => {
    setDownloadLoading(true)
    let apiName = 'unileverAPI'
    let path = '/vendors/download'
    let params = {
      responseType: 'arrayBuffer',
      response: true,
      queryStringParameters: {
        groupname: 'vendor',
      },
      // headers: {
      //   Authorization: `${(await Auth.currentSession())
      //     .getAccessToken()
      //     .getJwtToken()}`,
      // },
    }
    await API.get(apiName, path, params)
      .then((response) => {
        var raw = window.atob(response.data)
        var uInt8Array = new Uint8Array(raw.length)
        for (var i = 0; i < raw.length; ++i) {
          uInt8Array[i] = raw.charCodeAt(i)
        }
        const link = document.createElement('a')
        const blob = new Blob([uInt8Array], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `VendorMasterData_${Date.now()}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setDownloadLoading(false)
      })
      .catch((error) => {
        Modal.error({
          centered: true,
          content: t('admin.uploadVendor.downloadFailedMsg'),
        });
        setDownloadLoading(false)
      })
  }

  return (
    <div className="uploadVendor-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t('admin.uploadVendor.title')}
          subTitle={t('admin.uploadVendor.subTitle')}
        ></PageHeader>
      </Affix>
      <Divider />
      <DropZone
        selectFileCallBack={(file) => handleSelectFile(file)}
        isUploading={uploading}
      />
      <Space direction="horizontal" align="end" className="action-buttons">
        <Button
          loading={downloadLoading}
          onClick={downloadTemplate}
          size="large"
          type="primary"
        >
          <DownloadOutlined /> {t('admin.uploadVendor.downloadTemplateBtn')}
        </Button>
      </Space>
      <PreviewModal
        visible={isFileUploaded}
        onOk={submitRequest}
        onCancel={() => setIsFileUploaded(false)}
        newVendorsData={newVendorsData}
        vendorsForUpdatedData={vendorsForUpdatedData}
        availableNewVendorsData={availableNewVendorsData}
        availableVendorsForUpdatedData={availableVendorsForUpdatedData}
      />
    </div>
  )
}

export default UploadVendor
