import { Affix, Button, Divider, Input, Modal, PageHeader, Table } from 'antd'
import { API } from 'aws-amplify'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../utils/hooks'
import './vendorPendingEmailChange.scss'
const { TextArea } = Input
function VendorPendingEmailChange() {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  })
  const [selectedRow, setSelectedRow] = useState([])
  const [loading, setLoading] = useState(false)
  const [rejectMessage, setRejectMessage] = useState(null)
  const [rejectModal, setRejectModal] = useState(false)
  const { t, i18n } = useTranslation()
  const {findDuplicatesObjectsBy} = useUtils();
  const columns = [
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.vendorSAPCode'),
      dataIndex: 'vd_sap_code',
      width: 150,
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'vd_sap_code')}</div>
        }
      },
      fixed: 'left',
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.vendorName'),
      dataIndex: 'vd_name',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'vd_name')}</div>
        }
      },
      width: 400,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.vendorTaxCode'),
      dataIndex: 'vd_tax_code',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'vd_tax_code')}</div>
        }
      },
      width: 150,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.originalEmail'),
      dataIndex: 'original_email',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'original_email')}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.keyEmailAddress'),
      dataIndex: 'email',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'email')}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.originalAdditionalEmail'),
      dataIndex: 'original_additional_email',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'original_additional_email')}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.additionalEmailAddress'),
      dataIndex: 'additional_email',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{_.get(row,'additional_email')}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.submittedDate'),
      dataIndex: 'submittedDate',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>{moment(_.get(row, 'created_date')).format('DD/MM/YYYY')}</div>
        }
      },
      width: 100,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.attachment'),
      dataIndex: 'attachment',
      render: (text, row, index) => {
        return {
          props: {
            style: {}
          },
          children: <div>
            <a download={_.get(row, 'attachment_name') ? _.get(row, 'attachment_name') : 'download.jpg'} href={_.get(row, 'attachment')}>
            {_.get(row, 'attachment') === 'null'  || _.get(row, 'attachment') === undefined || _.get(row, 'attachment') === null  ? '' : (_.get(row, 'attachment_name') ? _.get(row, 'attachment_name') : 'Download') }
            </a>
          </div>
        }
      },
      width: 300,
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData(pagination);
    setSelectedRow([]);
  };
  const fetchData = (params = {}) => {
    setLoading(true)
    API.get('unileverAPI', 
    `/accounts/getVendorRequestList?currentPage=${params.current - 1}&pageSize=${params.pageSize}`)
      .then((r) => {
        setLoading(false)
        setData(_.get(r,'data'))
        setPagination({...params, total: _.get(r,'total')});
      })
      .catch(() => {
        setLoading(false)
        Modal.error({
          centered: true,
          content: t('manager.vendorMasterData.pendingRequest.fetchDataFailedMsg'),
        });
      })
  }

  useEffect(() => {
    fetchData(pagination)
  }, [])

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys)
    },
  }

  const approveSubmit = () => {
    if(loading === false){
      setLoading(true)
      const request = {
        body: {
          requestIDs: selectedRow,
          status: 'approved',
          description: '',
        },
      }
      API.post('unileverAPI', '/accounts/confirmVendor', request)
        .then((r) => {
          if( r.errorCode === "NOT_ALLOW_DUPLICATE_ID") {
            
            function findDuplicatesKey(arr, key1, key2) {
              arr  = _.map(arr, el =>_.pick(el,[key1, key2]))
              arr = _.filter(arr, el=> _.get(el,key2) !== "")
              if (arr.length <= 1) { return {uniqueDuplicated: []}}
              let uniqueDuplicated = _.uniqBy(arr,key1);
            return {uniqueDuplicated}
            }

            let {uniqueDuplicated : uniqueDuplicatesByEmail} = findDuplicatesKey(_.get(r,'params[0]'), 'vd_sap_code','email')
            let {uniqueDuplicated : uniqueDuplicatesByAdditionalEmail} = findDuplicatesKey(_.get(r,'params[0]'), 'vd_sap_code', 'additional_email')

            let list =      
            `<div> 
              <br/>
              ${_.concat(uniqueDuplicatesByEmail,uniqueDuplicatesByAdditionalEmail).map((data, index) => `<strong> ${index+1}. ${data['vd_sap_code']}</strong> - <strong> ${!_.isNil(data['email']) ? t('manager.vendorListEmail.duplicateOccured.keyEmail') : (!_.isNil(data['additional_email'])? t('manager.vendorListEmail.duplicateOccured.additionalEmail') : '') } </strong>`).join(' <br/>')}
            </div>`
            let final = t('manager.vendorListEmail.duplicateOccured.vendor', {
              list: list,
            }) 
            Modal.error({
              centered: true,
              content: <div dangerouslySetInnerHTML={{__html:`${final}`}}/>
            });
            setLoading(false)
            // setSelectedRow([])
          } else {
            if (r.message) {
              Modal.warning({
                centered: true,
                content: r.message,
              });
            } else {
              Modal.success({
                centered: true,
                content: t('manager.vendorMasterData.pendingRequest.approveSuccessfullyMsg'),
              });
            }
            setLoading(false)
            setSelectedRow([])
            fetchData({
              current: 1,
              pageSize: 10,
            })
          }
        })
        .catch(() => {
          setLoading(false)
          Modal.error({
            centered: true,
            content: t('manager.vendorMasterData.pendingRequest.approveFailedMsg'),
          });
        })
    }
  }

  const rejectSubmit = () => {
    if(loading === false){
      setLoading(true)
      const request = {
        body: {
          requestIDs: selectedRow,
          status: 'rejected',
          description: rejectMessage,
        },
      }
      API.post('unileverAPI', '/accounts/confirmVendor', request)
        .then((r) => {
          if (r.message) {
            Modal.warning({
              centered: true,
              content: r.message,
            });
          } else {
            Modal.success({
              centered: true,
              content: t('manager.vendorMasterData.pendingRequest.rejectSuccessfullyMsg'),
            });
          }
          setLoading(false)
          setRejectModal(false)
          setRejectMessage(null)
          setSelectedRow([])
          fetchData({
            current: 1,
            pageSize: 10,
          })
        })
        .catch(() => {
          setLoading(false)
          Modal.error({
            centered: true,
            content: t('manager.vendorMasterData.pendingRequest.rejectFailedMsg'),
          });
        })
    }
  }

  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={`${t('manager.vendorMasterData.pendingRequest.title')}`}
          subTitle={`${t('manager.vendorMasterData.pendingRequest.subTitle')}`}
          extra={[
            <Button
              key="1"
              primary
              disabled={selectedRow.length == 0 || loading}
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: `${t('manager.vendorMasterData.pendingRequest.confirmApprove', {
                    number: selectedRow.length,
                  })}`,
                  okText: t('manager.vendorMasterData.pendingRequest.popConfirm.yesBtn'),
                  cancelText: t('manager.vendorMasterData.pendingRequest.popConfirm.cancelBtn'),
                  onOk: approveSubmit,
                });
              }}
            >
              {t('manager.vendorMasterData.pendingRequest.approveBtn')}
            </Button>,
            <Button
              key="2"
              danger
              disabled={selectedRow.length == 0 || loading}
              onClick={() => setRejectModal(true)}
            >
              {t('manager.vendorMasterData.pendingRequest.rejectBtn')}
            </Button>,
          ]}
        ></PageHeader>
      </Affix>
      <Divider />
      <Table
        size="small"
        scroll={{ x: 1500 }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        bordered
        columns={columns}
        rowKey={(record) => record.uuid}
        dataSource={data}
        loading={loading}
        pagination={{
          ...pagination, 
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
      <Modal
        maskClosable={false}
        title={`${t('manager.vendorListEmail.pendingRequest.modalReject.title', {
          number: selectedRow.length,
        })}`}
        onCancel={() => {
          setRejectMessage(null)
          setRejectModal(false)
        }}
        afterClose={() => {
          setRejectMessage(null)
          setRejectModal(false)
        }}
        visible={rejectModal}
        footer={[
          <Button
            onClick={() => {
              setRejectMessage(null)
              setRejectModal(false)
            }}
            key="no"
          >
            {t('manager.vendorMasterData.pendingRequest.modalReject.cancelBtn')}
          </Button>,
          <Button
            type="primary"
            onClick={rejectSubmit}
            key="yes"
          >
            {t('manager.vendorMasterData.pendingRequest.modalReject.yesBtn')}
          </Button>,
        ]}
      >
        <div className="confirm-reject-msg">
          <p>{`${t('manager.vendorListEmail.pendingRequest.modalReject.body', {
          number: selectedRow.length,
        })}`}</p>
        </div>
      </Modal>
    </div>
  )
}

export default VendorPendingEmailChange
