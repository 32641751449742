import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import "@ckeditor/ckeditor5-image/theme/imagestyle.css";
import { Affix, Button, Checkbox, Divider, Input, Menu, Modal, PageHeader, Space, Switch, Table, Tooltip } from "antd";
import { API, Storage } from "aws-amplify";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { colInfos, defaultDetailRecord, defaultModalState, POPUP_TYPE, S3_MAIN_BASE, S3_TEMP_BASE } from "./../../configs/constants";
import { useUtils } from './../../utils/hooks';
import QuestionAndAnswerModal from "./../questionAndAnswerModal/questionAndAnswerModal";
import "./index.scss";
const { Search } = Input;
function ColumnItem(props) {
  const { cellData } = props;

  return <div>{cellData}</div>;
}

function QuestionAndAnswer({ language, updateLanguage }) {
  /*************************************************************************
   *                                HOOKS
   *************************************************************************/

  const { t, i18n } = useTranslation();
  let timeout;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  });
  const [loading, setLoading] = useState(false);
  const [qnaDetails, setQnaDetails] = useState([]);
  const [sections, setSections] = useState([]);
  const [checkedSections, setCheckedSections] = useState([]);
  const [detailModalState, setdetailModalState] = useState(defaultModalState);
  const customSetDetailModalState = ({ ...params }) => {
    setdetailModalState((prevState, newState) => {
      return { ...prevState, ...params };
    });
  };

  const {
    strippedString,
    htmlDecode,
    extractLinkFromHtmlString,
    arr_diff,
    truncString,
    sort2fieldAscending,
    escapeXml
  } = useUtils();

  useEffect(() => {
    fetchData(pagination);
  }, []);

  /*************************************************************************
   *                                CLIENT
   *************************************************************************/
  const fetchData = (params = {}) => {
    setLoading(true);
    API.get(
      "unileverAPI",
      `/qna/section/detailsForAP?currentPage=${params.current - 1}&pageSize=${
        params.pageSize
      }&lang=${i18n.language ==='vi'?'vi':'en'}${params.searchValue ? `&searchValue=` + params.searchValue : ""}${params.sectionID ? `&sectionID=`+params.sectionID : ""}`
    )
      .then((r) => {
        setLoading(false);
        let data = r.result.data;
        let dataAfterSort = sort2fieldAscending(data, 'qna_section.order_number','order_number', i18n.language == 'vi' ? 'qna_section.section_vi' : 'qna_section.section_en')
        setQnaDetails(dataAfterSort);
        setPagination({ ...params, total: r.result.total });
      })
      .catch(() => {
        setLoading(false);
        Modal.error({
          centered: true,
          content: t(
            "manager.invoiceStatusMasterData.pendingRequest.fetchDataFailedMsg"
          ),
        });
      });
  };

  const fetchSections = (params = {}) => {
    setLoading(true);
    API.get(
      "unileverAPI",
      `/qna/sections?pageSize=1000&includeInactive=true`
    )
      .then((r) => {
        // setLoading(false);
        let newSections = _.get(r, "result.data").map(s => {return {...s, isChecked : false}})

        setSections(newSections);
      })
      .catch(() => {
        // setLoading(false);
        Modal.error({
          centered: true,
          content: t(
            "manager.invoiceStatusMasterData.pendingRequest.fetchDataFailedMsg"
          ),
        });
      });
  };

  useEffect(() => {
    fetchSections(pagination);
  }, []);

  /*************************************************************************
   *                                TABLE
   *************************************************************************/
  const handleTableChange = (pagination, filters, sorter) => {
    fetchData(pagination);
  };
  const handleSetIsActive = (e, record, bool) => {
      setLoading(true)
      let body = {
        detail: {
          is_active: bool,
        },
        
      };
      let section_id = _.get(record, "qna_section_id");
      let detail_id = _.get(record, "uuid");

      return API.put(
        "unileverAPI",
        `/qna/section/${section_id}/detail/${detail_id}`,
        {
          body,
        }
      )
        .then((d) => {
          setQnaDetails(
            _.map({ ...qnaDetails }, (el) => {
              return {
                ...el,
                is_active: record.uuid === el.uuid ? bool : el.is_active,
              };
            })
          );
          setLoading(true)
        })
        .then(handleClearModal)
        .then(() => fetchData(pagination))
        .catch(()=>{
          Modal.error({
            centered: true,
            content: t('admin.questionAndAnswerList.submitSuccessUpdate'),
          });
        });
  };
  

  // const handleSearchCol = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   // setSearchText(selectedKeys[0])
  //   // setSearchedColumn(dataIndex)
  // };

  // const handleReset = clearFilters => {
  //   clearFilters();
  //   // setSearchText('')

  // };
  // let searchInput = useRef()
  const myDropDown = (dataIndex) => {
    
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, width: '425px', }}>
        {/* <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        /> */}
        <div
          style={{ width: '100%', height: '400px', overflow: 'scroll' }}
          // onChange={(checkedValue)=>{setCheckedSections(checkedValue)}}
        >

            <Menu selectable={false}>
          {sections.map(s=>{
            return (
              <Menu.Item  key={s.uuid}>
              <Checkbox
                      style={{ width: '100%'}}
                      value={s.uuid}
                      checked={s.isChecked}
                      onChange={(e)=>{
                        let newSections = [...sections].map(s => {return s.uuid ===  e.target.value? {...s, isChecked : e.target.checked} : s})
                        setSections(newSections)
                      }}
                      >{s.isChecked}
                        {truncString(_.get(s, i18n.language==='vi' ? 'section_vi':'section_en'), 50)}
                      </Checkbox>
              </Menu.Item>
            )
          })}
          </Menu>
        </div>
        <Space style={{ width: '100%', justifyContent: 'flex-end'}}>
        <Button onClick={() => {
            setPagination({...pagination, sectionID: ''})
            let newSections = [...sections].map(s => {return {...s, isChecked : false}})
            setSections(newSections)
            }}>
            {t('admin.questionAndAnswerModal.reset')}
          </Button>

          
          <Button
            type="primary"
            onClick={() => fetchData({...pagination, current: 1, sectionID: _.filter([...sections], s => s.isChecked).map(s=>s.uuid).join(',')})}
            style={{ width: 90 }}
          >
            OK
          </Button>
          
          
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    )
    }


  const columns = _.map(colInfos, (colInfo, index) => {
    let col = {
      title: t(`admin.questionAndAnswerList.columns.${colInfo.name}`),
      width: colInfo.width,
      
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      
      render(text, record) {
        const cellData = _.get(
          record,
          i18n.language === "vi" ? colInfo.dataKeyVi : colInfo.dataKeyEn
        );

        switch (colInfo.name) {
          case "is_active":
            return (
              <Switch
                disabled={true}
                key={record.uuid}
                onClick={(bool, e) => handleSetIsActive(e, record, bool)}
                checked={_.get(record, "is_active")}
              />
            );
          case "edit":
            return (
              <Button
                type="primary"
                shape="circle"
                onClick={() => handleOpenQnaModal(record, POPUP_TYPE.EDIT)}
                icon={<EditOutlined />}
              />
            );
          case "view":
            return (
              <Button
                type="primary"
                shape="circle"
                onClick={() => handleOpenQnaModal(record, POPUP_TYPE.READ)} 
                icon={<EyeOutlined />}
              />
            );
          default:
            return {
              props: {
                className: record.is_active ? '': 'in-active',
              },
              children: (
                <ColumnItem
                  cellData={truncStringWithEllipsis(
                    record,
                    htmlDecode(unescape(strippedString(cellData))),
                    100
                  )}
                />
              ),
            };
        }
      },
    };
    if (index === 0) {
      _.set(col, "fixed", "left");
    }
    if (index === 1) {

      if (sections.length > 0) {
        // let data = _.uniqBy(qnaDetails, 'qna_section_id')
        _.set(col, "filterDropdown", myDropDown(colInfo.name));
        _.set(col, "onFilter", (value, record) =>
          record[colInfo.name]
          ? record[colInfo.name].toString().toLowerCase().includes(value.toLowerCase())
          : '');
          // onFilter: (value, record) => _.get(record, 'qna_section_id').indexOf(value) === 0,
        // _.set(col, "onFilterDropdownVisibleChange", visible => {
        //   if (visible) {
        //     setTimeout(() => searchInput.select(), 100);
        //   }
        // });

      }
    }
    return col;
  });

 
  const truncStringWithEllipsis = (record, str, n) => {
    return (
      <div>
        {str.substr(0, n - 1)}

        <span style={{ cursor: "pointer" }}>
          {str.length > n ? (
            <Tooltip
              placement="bottom"
              title={
                <p
                  style={{ margin: 0, cursor: "pointer" }}
                  onClick={() => handleOpenQnaModal(record, POPUP_TYPE.READ)}
                >
                  {t('admin.questionAndAnswerModal.viewMore')}
                </p>
              }
            >
              &hellip;
            </Tooltip>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };

  /*************************************************************************
   *                                SEARCH INPUT
   *************************************************************************/
  const handleSearch = (text) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(
      () => fetchData({ ...pagination, searchValue: text }),
      300
    );
  };

  /*************************************************************************
   *                                MODAL
   *************************************************************************/
  const handleOpenQnaModal = (record, type) => {

    /**
     * when user hit edit/add,
     * set isReadOnly = false
     * set originData, toBeModify = data-Selected
     *
     */
    
    switch (type) {
      case POPUP_TYPE.ADD:
        customSetDetailModalState({
          isOpen: true,
          isReadOnly: false,
          originData: record,
          toBeModify: { ...record, uuid: uuidv4(), is_active : false },
          type: POPUP_TYPE.ADD,
        });
        
        break;
      case POPUP_TYPE.EDIT:
        customSetDetailModalState({
          isOpen: true,
          isReadOnly: false,
          originData: record,
          toBeModify: record,
          type: POPUP_TYPE.EDIT,
        });
        break;
      case POPUP_TYPE.READ:
        customSetDetailModalState({
          isOpen: true,
          isReadOnly: true,
          originData: record,
          toBeModify: record,
        });
        break;
      default:
        break;
    }
  };

  const handleClearModal = () => {
    customSetDetailModalState(defaultModalState);
  };
  function handleEditorDataBeforeSubmit(path = 'question_vi') {
    let 
    originLinks,
    afterUpdateLinks,
    keysNeedToDeleteFromMain = [],
    keysNeedToMoveToMain,
    removeQueryString,
    keysAfterMoveToMain,
    detail_id_folder = detailModalState.toBeModify.uuid,
    stringToReplace = `https://${Storage._config.AWSS3.bucket}.s3.${Storage._config.AWSS3.region}.amazonaws.com/`;
    
  originLinks = extractLinkFromHtmlString(
    detailModalState.originData[path],
    stringToReplace
  );
  afterUpdateLinks = extractLinkFromHtmlString(
    detailModalState.toBeModify[path],
    stringToReplace
  );

  removeQueryString= _.map([...afterUpdateLinks], (afterUpdateLink)=> {
    return {
      from : afterUpdateLink,
      to : afterUpdateLink.split('?')[0]
    }
  })

_.each([...originLinks], (link) => {
  if (!_.includes(afterUpdateLinks, link)) keysNeedToDeleteFromMain.push(link)
}) 
  // keysNeedToDeleteFromMain = _.map([...originLinks], (link) => {
  //   return link.replace(stringToReplace, "").split('?')[0];
  // });
  let difference = arr_diff(originLinks, afterUpdateLinks);
  keysNeedToMoveToMain = _.map([...difference], (link) => {
    return link.replace(stringToReplace, "").split('?')[0];
  });
  keysAfterMoveToMain = _.map([...difference], (link) => {
    let key = link.replace(stringToReplace, "").split('?')[0];
    return key.replace(S3_TEMP_BASE,S3_MAIN_BASE)
  });
  let final = _.get(detailModalState.toBeModify, path)
  

  // removeQueryString
  _.each(removeQueryString, (change)=>{
    final = final.replace(escapeXml(change.from), change.to);
  })
    return {
      detail_id_folder: detail_id_folder,
      // originLinks: originLinks,
      // afterUpdateLinks: afterUpdateLinks,
      removeQueryString: removeQueryString, 

      keysNeedToDeleteFromMain: keysNeedToDeleteFromMain, // w This will be delete from main bucket, meanwhile these will be stuck at temp folder, we dont need to do anything, delegate the deletion to theCronJob
      keysAfterMoveToMain:keysAfterMoveToMain,
      keysNeedToMoveToMain: keysNeedToMoveToMain,// This will be copy from temp bucket to main bucket 
      submitData : final
    };
  }

  const handleSubmit = () => {
    /**
     *
     * Storing Images and file story
     *
     * submit =>
     * updage => get originLinks and afterUpdateLinks .
     * we could have : updateDeletedLinks and updateNewLinks
     * from that, updateDeletedLinks are removed from main bucket
     * updateNewLinks from temp cucket are moved to main bucker.
     */
    let url_from = [];
    let url_to = [];
    let url_delete = [];

    let question_vi = handleEditorDataBeforeSubmit("question_vi");
    let question_en = handleEditorDataBeforeSubmit("question_en");
    let answer_vi =  handleEditorDataBeforeSubmit("answer_vi");
    let answer_en =  handleEditorDataBeforeSubmit("answer_en");
    url_from =[...question_vi.keysNeedToMoveToMain,...question_en.keysNeedToMoveToMain,...answer_vi.keysNeedToMoveToMain,...answer_en.keysNeedToMoveToMain]
    url_to =[...question_vi.keysAfterMoveToMain,...question_en.keysAfterMoveToMain,...answer_vi.keysAfterMoveToMain,...answer_en.keysAfterMoveToMain]
    url_delete =[...question_vi.keysNeedToDeleteFromMain,...question_en.keysNeedToDeleteFromMain,...answer_vi.keysNeedToDeleteFromMain,...answer_en.keysNeedToDeleteFromMain]
    if (detailModalState.type === POPUP_TYPE.ADD) {
      let body = {
        detail: {
          uuid: _.get(detailModalState.toBeModify, "uuid"),
          order_number: _.get(detailModalState.toBeModify, "order_number"),
          question_vi: question_vi.submitData,
          question_en: question_en.submitData,
          answer_vi: answer_vi.submitData,
          answer_en: answer_en.submitData,
          is_active: _.get(detailModalState.toBeModify, "is_active") ? 1 : 0,
        },
        file: {
          url_from:url_from,
          url_to: url_to,
          url_delete:url_delete,
          qna_folder_id: detailModalState.toBeModify.uuid
  
        },
      };
      // return;
      return API.post(
        "unileverAPI",
        `/qna/section/${_.get(
          detailModalState.toBeModify,
          "qna_section_id"
        )}/detail`,
        {
          body,
        }
      )
        .then((d) => {
          Modal.success({
            centered: true,
            content: t('admin.questionAndAnswerList.submitSuccessfullyMsg'),
          });
        })
        .then(handleClearModal)
        .then(() => fetchData(pagination));
    } else if (detailModalState.type === POPUP_TYPE.EDIT) {
      let body = {
        detail: {
          order_number: _.get(detailModalState.toBeModify, "order_number"),
          question_vi: question_vi.submitData,
          question_en: question_en.submitData,
          answer_vi: answer_vi.submitData,
          answer_en: answer_en.submitData,
          is_active: _.get(detailModalState.toBeModify, "is_active") ? 1 : 0,
        },
        file: {
          url_from:url_from,
          url_to: url_to,
          url_delete:url_delete,
          qna_folder_id: detailModalState.toBeModify.uuid
  
        },
      };

      let section_id = _.get(detailModalState.toBeModify, "qna_section_id");
      let detail_id = _.get(detailModalState.toBeModify, "uuid");
      // return;

      return API.put(
        "unileverAPI",
        `/qna/section/${section_id}/detail/${detail_id}`,
        {
          body,
        }
      )
        .then((d) => {
          Modal.success({
            centered: true,
            content: t('admin.questionAndAnswerList.submitSuccessUpdate'),
          });
        })
        .then(handleClearModal)
        .then(() => fetchData(pagination));
      // TODO handel error
    }
  };
  
  /** ***********************************************************************
   *                                HTML
   ************************************************************************ */
  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t("admin.questionAndAnswerList.title")}
          subTitle=""
        ></PageHeader>
      </Affix>
      <Divider />
      <div>
        <Search
          className="search-input"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder={t("admin.questionAndAnswerList.search")}
          allowClear
        />
      </div>
      <div className="myBtnAdd">
        <Button
          block
          onClick={() =>
            handleOpenQnaModal(defaultDetailRecord, POPUP_TYPE.ADD)
          }
          type="primary"
        >
          {t("admin.questionAndAnswerList.add")}
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={qnaDetails}
        className="preview-invoice-table"
        rowKey={(record) => record.uuid}
        loading={loading}
        pagination={{
          ...pagination,
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        scroll={{ y: "60vh" }}
      />

      {/*
       *
       * Pass data in,
       * now ther are 2 sets of data that are availble: origin and toBeModify
       * the reason why is to check save button
       *
       */}
      <QuestionAndAnswerModal
        key={detailModalState.type}
        onCancel={() => handleClearModal()}
        visible={detailModalState.isOpen}
        qnaDetails={qnaDetails}
        modalState={detailModalState}
        setModalState={customSetDetailModalState}
        onOk={handleSubmit}
      />
    </div>
  );
}


export default QuestionAndAnswer;
