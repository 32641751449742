import { Affix, Button, Divider, Input, Modal, PageHeader, Table } from 'antd'
import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './pendingVendorMasterDataRequest.scss'
const { TextArea } = Input

function PendingVendorMasterDataRequest() {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  })
  const [selectedRow, setSelectedRow] = useState([])
  const [loading, setLoading] = useState(false)
  const [rejectMessage, setRejectMessage] = useState(null)
  const [rejectModal, setRejectModal] = useState(false)
  const { t, i18n } = useTranslation()
  const columns = [
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.vendorSAPCode'),
      dataIndex: 'vd_sap_code',
      width: 150,
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.vd_sap_code.type == 'created' ? 
                '#d9f4be' : 
                (row.vd_sap_code.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.vd_sap_code.value}</div>
        }
      },
      fixed: 'left',
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.vendorName'),
      dataIndex: 'vd_name',
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.vd_name.type == 'created' ? 
                '#d9f4be' : 
                (row.vd_name.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.vd_name.value}</div>
        }
      },
      width: 400,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.vendorTaxCode'),
      dataIndex: 'vd_tax_code',
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.vd_tax_code.type == 'created' ? 
                '#d9f4be' : 
                (row.vd_tax_code.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.vd_tax_code.value}</div>
        }
      },
      width: 150,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.keyEmailAddress'),
      dataIndex: 'email',
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.email.type == 'created' ? 
                '#d9f4be' : 
                (row.email.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.email.value}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.additionalEmailAddress'),
      dataIndex: 'additional_email',
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.additional_email.type == 'created' ? 
                '#d9f4be' : 
                (row.additional_email.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.additional_email.value}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.bookingType'),
      dataIndex: 'booking_type',
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.booking_type.type == 'created' ? 
                '#d9f4be' : 
                (row.booking_type.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.booking_type.value}</div>
        }
      },
      width: 100,
    },
    {
      title: t('manager.vendorMasterData.pendingRequest.columns.apBooker'),
      dataIndex: 'ap_booker',
      render: (text, row, index) => {
        return {
          props: {
            style: { 
              backgroundColor: 
                row.ap_booker.type == 'created' ? 
                '#d9f4be' : 
                (row.ap_booker.type == 'updated' ? 
                '#fef5b8' : 
                'white') 
            }
          },
          children: <div>{row.ap_booker.value}</div>
        }
      },
      width: 300,
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData(pagination);
    setSelectedRow([]);
  };

  const fetchData = (params = {}) => {
    setLoading(true)
    API.get('unileverAPI', 
    `/vendors/getPendingRequest?requestStatus=pending&currentPage=${params.current - 1}&pageSize=${params.pageSize}`)
      .then((r) => {
        setLoading(false)
        setData(r.result.data)
        setPagination({...params, total: r.result.total});
      })
      .catch(() => {
        setLoading(false)
        Modal.error({
          centered: true,
          content: t('manager.vendorMasterData.pendingRequest.fetchDataFailedMsg'),
        });
      })
  }

  useEffect(() => {
    fetchData(pagination)
  }, [])

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys)
    },
  }

  const approveSubmit = () => {
    if(loading === false){
      setLoading(true)
      const request = {
        body: {
          requestIDs: selectedRow,
          status: 'approved',
          description: '',
        },
      }
      API.put('unileverAPI', '/vendors/confirmMasterData', request)
        .then((r) => {
          if( r.duplicatedIds) {
            let list =      
            `<div> 
              <br/>
              ${r.duplicatedIds.map((data, index) => `<strong> ${index+1}. ${data['vd_sap_code']} </strong>`).join(' <br/>')}
            </div>`
            let final = t('manager.uploadFailedMsg.duplicateOccured.vendor', {
              list: list,
            }) 
            Modal.error({
              centered: true,
              content: <div dangerouslySetInnerHTML={{__html:`${final}`}}/>
            });
            setLoading(false)
            setSelectedRow([])
          } else {
            if (r.message) {
              Modal.warning({
                centered: true,
                content: r.message,
              });
            } else {
              Modal.success({
                centered: true,
                content: t('manager.vendorMasterData.pendingRequest.approveSuccessfullyMsg'),
              });
            }
            setLoading(false)
            setSelectedRow([])
            fetchData({
              current: 1,
              pageSize: 10,
            })
          }
        })
        .catch(() => {
          setLoading(false)
          Modal.success({
            centered: true,
            content: t('manager.vendorMasterData.pendingRequest.approveFailedMsg'),
          });
        })
    }
  }

  const rejectSubmit = () => {
    if(loading === false){
      setLoading(true)
      const request = {
        body: {
          requestIDs: selectedRow,
          status: 'rejected',
          description: rejectMessage,
        },
      }
      API.put('unileverAPI', '/vendors/confirmMasterData', request)
        .then((r) => {
          if (r.message) {
            Modal.warning({
              centered: true,
              content: r.message,
            });
          } else {
            Modal.success({
              centered: true,
              content: t('manager.vendorMasterData.pendingRequest.rejectSuccessfullyMsg'),
            });
          }
          setLoading(false)
          setRejectModal(false)
          setRejectMessage(null)
          setSelectedRow([])
          fetchData({
            current: 1,
            pageSize: 10,
          })
        })
        .catch(() => {
          setLoading(false)
          Modal.error({
            centered: true,
            content: t('manager.vendorMasterData.pendingRequest.rejectFailedMsg'),
          });
        })
    }
  }

  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={`${t('manager.vendorMasterData.pendingRequest.title')}`}
          subTitle={`${t('manager.vendorMasterData.pendingRequest.subTitle')}`}
          extra={[
            <Button
              key="1"
              primary
              disabled={selectedRow.length == 0 || loading}
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: `${t('manager.vendorMasterData.pendingRequest.confirmApprove', {
                    number: selectedRow.length,
                  })}`,
                  okText: t('manager.vendorMasterData.pendingRequest.popConfirm.yesBtn'),
                  cancelText: t('manager.vendorMasterData.pendingRequest.popConfirm.cancelBtn'),
                  onOk: approveSubmit,
                });
              }}
            >
              {t('manager.vendorMasterData.pendingRequest.approveBtn')}
            </Button>,
            <Button
              key="2"
              danger
              disabled={selectedRow.length == 0 || loading}
              onClick={() => setRejectModal(true)}
            >
              {t('manager.vendorMasterData.pendingRequest.rejectBtn')}
            </Button>,
          ]}
        ></PageHeader>
      </Affix>
      <Divider />
      <Table
        size="small"
        scroll={{ x: 1500 }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        bordered
        columns={columns}
        rowKey={(record) => record.uuid.value}
        dataSource={data}
        loading={loading}
        pagination={{
          ...pagination, 
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
      <Modal
        maskClosable={false}
        title={`${t('manager.vendorMasterData.pendingRequest.modalReject.title', {
          number: selectedRow.length,
        })}`}
        onCancel={() => {
          setRejectMessage(null)
          setRejectModal(false)
        }}
        afterClose={() => {
          setRejectMessage(null)
          setRejectModal(false)
        }}
        visible={rejectModal}
        footer={[
          <Button
            onClick={() => {
              setRejectMessage(null)
              setRejectModal(false)
            }}
            key="no"
          >
            {t('manager.vendorMasterData.pendingRequest.modalReject.cancelBtn')}
          </Button>,
          <Button
            type="primary"
            onClick={rejectSubmit}
            key="yes"
            disabled={!rejectMessage || rejectMessage.trim().length === 0}
          >
            {t('manager.vendorMasterData.pendingRequest.modalReject.yesBtn')}
          </Button>,
        ]}
      >
        <div className="confirm-reject-title">{t('manager.vendorMasterData.pendingRequest.modalReject.reasonLabel')}</div>
        <TextArea
          showCount
          maxLength={100}
          rows={6}
          onChange={(e) => setRejectMessage(e.target.value)}
          value={rejectMessage}
        />
        <div className="confirm-reject-msg">
          <p>{t('manager.vendorMasterData.pendingRequest.modalReject.guidelineMsg')}</p>
          <p>{t('manager.vendorMasterData.pendingRequest.modalReject.confirmMsg')}</p>
        </div>
      </Modal>
    </div>
  )
}

export default PendingVendorMasterDataRequest
