import { message, Spin, Modal } from 'antd'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  justify-content: center;
  border-width: 2px;
  height: 150px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

function StyledDropzone(props) {
  const { isUploading } = props
  const { t, i18n } = useTranslation()
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (
        acceptedFiles[0].type === 'application/vnd.ms-excel' ||
        acceptedFiles[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        props.selectFileCallBack(acceptedFiles)
      } else {
        Modal.error({
          centered: true,
          content: t('admin.uploadInvoiceReason.dropZone.uploadWrongFileFormatMsg'),
        });
      }
    },
    disabled: isUploading,
  })

  return (
    <div className="upload-vendor-dropzone">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {isUploading ? (
          <Spin tip={t('admin.uploadInvoiceReason.dropZone.loading')} />
        ) : (
          <div>
            <p>{t('admin.uploadInvoiceReason.dropZone.guideline')}</p>
            <p>{t('admin.uploadInvoiceReason.dropZone.fileSupport')}</p>
          </div>
        )}
      </Container>
    </div>
  )
}

export default StyledDropzone
