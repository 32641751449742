import { Affix, Divider, Modal, PageHeader, Select, Table } from 'antd'
import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
const { Option } = Select

function isObject(obj){
  return obj !== undefined && obj !== null && obj.constructor == Object;
}

function VendorMasterDataRequest() {
  const [data, setData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  })
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const getStatus = (status) => {
    switch (status) {
      case 'rejected':
        return 'Rejected';
      case 'approved':
        return 'Approved';
      case 'pending-update':
      case 'pending-create':
        return 'Pending';
    }
  }
  const columnsWithStatus = [
    {
      title: t('manager.vendorMasterData.allRequest.columns.status'),
      dataIndex: 'status',
      width: 150,
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.status) ? getStatus(row.status.value) : getStatus(row.status)}</div>
        }
      },
      fixed: 'left',
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.vendorSAPCode'),
      dataIndex: 'vd_sap_code',
      width: 150,
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.vd_sap_code) ? row.vd_sap_code.value : row.vd_sap_code}</div>
        }
      },
      fixed: 'left',
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.vendorName'),
      dataIndex: 'vd_name',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.vd_name) ? row.vd_name.value : row.vd_name}</div>
        }
      },
      width: 400,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.vendorTaxCode'),
      dataIndex: 'vd_tax_code',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.vd_tax_code) ? row.vd_tax_code.value : row.vd_tax_code}</div>
        }
      },
      width: 150,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.keyEmailAddress'),
      dataIndex: 'email',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.email) ? row.email.value : row.email}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.additionalEmailAddress'),
      dataIndex: 'additional_email',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.additional_email) ? row.additional_email.value : row.additional_email}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.bookingType'),
      dataIndex: 'booking_type',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.booking_type) ? row.booking_type.value : row.booking_type}</div>
        }
      },
      width: 100,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.apBooker'),
      dataIndex: 'ap_booker',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.ap_booker) ? row.ap_booker.value : row.ap_booker}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.description'),
      dataIndex: 'description',
      width: 300,
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.description) ? row.description.value : row.description}</div>
        }
      },
    },
  ]
  const columns = [
    {
      title: t('manager.vendorMasterData.allRequest.columns.vendorSAPCode'),
      dataIndex: 'vd_sap_code',
      width: 150,
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.vd_sap_code) ? row.vd_sap_code.value : row.vd_sap_code}</div>
        }
      },
      fixed: 'left',
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.vendorName'),
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.vd_name) ? row.vd_name.value : row.vd_name}</div>
        }
      },
      width: 400,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.vendorTaxCode'),
      dataIndex: 'vd_tax_code',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.vd_tax_code) ? row.vd_tax_code.value : row.vd_tax_code}</div>
        }
      },
      width: 150,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.keyEmailAddress'),
      dataIndex: 'email',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.email) ? row.email.value : row.email}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.additionalEmailAddress'),
      dataIndex: 'additional_email',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.additional_email) ? row.additional_email.value : row.additional_email}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.bookingType'),
      dataIndex: 'booking_type',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.booking_type) ? row.booking_type.value : row.booking_type}</div>
        }
      },
      width: 100,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.apBooker'),
      dataIndex: 'ap_booker',
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.ap_booker) ? row.ap_booker.value : row.ap_booker}</div>
        }
      },
      width: 300,
    },
    {
      title: t('manager.vendorMasterData.allRequest.columns.description'),
      dataIndex: 'description',
      width: 300,
      render: (text, row, index) => {
        return {
          children: <div>{isObject(row.description) ? row.description.value : row.description}</div>
        }
      },
    },
  ]

  const fetchData = (value, params = {}) => {
    setLoading(true)
    API.get(
      'unileverAPI',
      `/vendors/getPendingRequest?currentPage=${params.current - 1}&pageSize=${params.pageSize}${value ? '&requestStatus=' + value : ''}`
    )
      .then((r) => {
        setLoading(false)
        setData(r.result.data)
        setPagination({...params, total: r.result.total});
      })
      .catch(() => {
        setLoading(false)
        Modal.error({
          centered: true,
          content: t('manager.vendorMasterData.allRequest.fetchDataFailedMsg')
        });
      })
  }

  useEffect(() => {
    fetchData(selectedStatus, pagination)
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData(selectedStatus, pagination)
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={`${t('manager.vendorMasterData.allRequest.title')}`}
          subTitle={`${t('manager.vendorMasterData.allRequest.subTitle')}`}
        ></PageHeader>
      </Affix>
      <Divider />
      <Select
        loading={loading}
        value={selectedStatus}
        size="large"
        style={{ width: 180, marginBottom: 20 }}
        onChange={(value) => {
          fetchData(value, {
            current: 1,
            pageSize: 10,
          });
          setSelectedStatus(value);
        }}
      >
        <Option>{t('manager.vendorMasterData.allRequest.allStatus')}</Option>
        <Option value="pending">{t('manager.vendorMasterData.allRequest.pendingStatus')}</Option>
        <Option value="approved">{t('manager.vendorMasterData.allRequest.approvedStatus')}</Option>
        <Option value="rejected">{t('manager.vendorMasterData.allRequest.rejectedStatus')}</Option>
      </Select>
      <Table
        size="small"
        scroll={{ x: 1500 }}
        bordered
        columns={selectedStatus != null ? columns : columnsWithStatus}
        rowKey={(record) => isObject(record.uuid) ? record.uuid.value : record.uuid}
        dataSource={data}
        pagination={{
          ...pagination, 
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={handleTableChange}
      /> 
    </div>
  )
}

export default VendorMasterDataRequest
