import { Button, Form, Input } from 'antd';
import _ from "lodash";
import React, { useRef, useState } from 'react';
const CustomCognitoVerifyEmail = ({user, onVerifyEmail, onSubmitCode, formState, formStates, loading}) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const [form] = Form.useForm();

  const [radioStatus, setRadioStatus] = useState(false);
  const radioRef = useRef();

  const toggleRadio = () => {
    
    setRadioStatus(_.get(radioRef, 'current.checked'))
  }

  

  return (
    <>
    {/******************************* Errors *****************************/}
      {errorMessage && 
        <div className="ant-alert ant-alert-error" style={{position: 'fixed', top: 0, width: '100%'}}>
          <span className="anticon anticon-close-circle ant-alert-icon">
            <svg viewBox="64 64 896 896" 
            focusable="false" data-icon="close-circle" width="1em" height="1em" 
                fill="currentColor" aria-hidden="true">
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
            </svg>
          </span>
          <div className="ant-alert-content">
            <div className="ant-alert-message">
              {errorMessage}
            </div>
            <div className="ant-alert-description"></div>
          </div>
        </div>
      }
      {/******************************* Form ***************************/}

      {
        formState === formStates.onVerify &&
        <Form
        class="section" role="application"
        form={form}
        name="email-verify"
        onFinish={onVerifyEmail}
        style={{
          backgroundColor: '#fff',
          padding: '35px 40px',
          borderRadius: '6px',
          width: '460px',
        }}
        layout="vertical"
        scrollToFirstError>
        <div className="form-section-header">
          <h1 className="header" style={{textAlign: 'center', 'marginBottom': '20px'}}> <span style={{fontSize: '45px'}}> Unilever </span> <br/> <span style={{fontSize: '25px','display': 'block', 'marginTop' : '-15px'}}>Vendor Portal</span></h1>
          <h3 class="header" style={{textAlign: 'center'}} data-test="form-section-header-section">
          Account recovery requires verified contact information
          <br />
          Để khôi phục tài khoản, cần xác minh thông tin liên hệ.
          </h3>  
          <div className="subtitle"></div>
        </div>

        <span class="custom-radio-button">
          <input ref={radioRef} onChange={toggleRadio} type="radio" style={{marginRight: '10px'}} name="contact" value="email" placeholder="" data-test="verify-contact-email-radio" />
          <amplify-label class="hydrated">Email</amplify-label>
        </span>
      <Form.Item style={{textAlign:'center'}}>
          <Button disabled={!radioStatus} loading={loading} type="primary" size="large" htmlType="submit" style={{minWidth: '153px'}}>
            Verify / Xác minh
          </Button>
        </Form.Item>
      </Form>
      }
      
    
      {
        formState === formStates.onInputCode &&
        <Form
        class="section" role="application"
        form={form}
        name="code-submit"
        onFinish={onSubmitCode}
        style={{
          backgroundColor: '#fff',
          padding: '35px 40px',
          borderRadius: '6px',
          width: '460px',
        }}
        layout="vertical"
        scrollToFirstError>
        <div className="form-section-header" style={{textAlign: 'center', fontSize: '1rem'}}>
          <h1 className="header" style={{textAlign: 'center', 'marginBottom': '20px'}}> <span style={{fontSize: '45px'}}> Unilever </span> <br/> <span style={{fontSize: '25px','display': 'block', 'marginTop' : '-15px'}}>Vendor Portal</span></h1>
          <h3 className="header" style={{textAlign: 'center'}}>Confirm Email Address <br /> Xác nhận địa chỉ Email</h3>
          <div className="subtitle">A code has been snet to your email and it will be expire after 24 hours <br /> Please enter the code from the email</div>
          <br />
          <div className="subtitle">Mã xác nhận đã được gửi tới địa chỉ email của bạn và sẽ hết hạn sau 24 giờ <br /> Vui lòng nhập mã xác nhận từ địa chỉ email</div>
        </div>
        <Form.Item
          name="code"
          rules={[{ 
            required: true, 
            message: 'Confirmation code cannot be empty! Mã xác nhận không được để trống!' 
          }]}
          >
          <Input placeholder="Confirmation code / Mã xác nhận" />
        </Form.Item>
        {/* <span class="input-host hydrated">
          <input type="text" placeholder="Enter code" name="code" class="input" autocomplete="off" data-test="verify-contact-code-input" />
        </span> */}


        {/* <span class="custom-radio-button">
          <input ref={radioRef} onChange={toggleRadio} type="radio" style={{marginRight: '10px'}} name="contact" value="email" placeholder="" data-test="verify-contact-email-radio" />
          <amplify-label class="hydrated">Email</amplify-label>
        </span> */}
      <Form.Item style={{textAlign:'center'}}>
          <Button disabled={!radioStatus} loading={loading} type="primary" size="large" htmlType="submit" style={{minWidth: '153px'}}>
            Submit / Gửi
          </Button>
        </Form.Item>
      </Form>
      }
    </>
  )
}

export default CustomCognitoVerifyEmail


// <Form.Item
// name="password"
// label="New Password"
// rules={[
//   {
//     min: 10,
//     message: 'At least 10 characters/ Ít nhất 10 ký tự',
//   },
//   {
//     required: true,
//     message: 'Please input your password!',
//   },
//   () => ({
//     validator(_, value) {
//       if (value && (value.search(/[a-z]/) < 0 || value.search(/[A-Z]/) < 0)) {
//         return Promise.reject(new Error('A Mixture of both uppercase and lowercase/ Bao gồm cả chữ in hoa và chữ thường'));
//       }
//       return Promise.resolve();
//     },
//   }),
//   () => ({
//     validator(_, value) {
//       if (value && !(/[*@!#%&()^~{}]+/.test(value))) {
//         return Promise.reject(new Error('An inclusion of at least one special character/ Bao gồm ít nhất một ký tự đặc biệt'));
//       }
//       return Promise.resolve();
//     },
//   }),
// ]}
// hasFeedback>
// <Input.Password size="large" />
// </Form.Item>
// <Form.Item
// name="confirm"
// label="Confirm Password"
// dependencies={['password']}
// hasFeedback
// rules={[
//   {
//     required: true,
//     message: 'Please confirm your password!',
//   },
//   ({ getFieldValue }) => ({
//     validator(_, value) {
//       if (!value || getFieldValue('password') === value) {
//         return Promise.resolve();
//       }
//       return Promise.reject(new Error('The two passwords that you entered do not match!'));
//     },
//   }),
// ]}>
// <Input.Password size="large" />
// </Form.Item>
