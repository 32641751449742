import { LoadingOutlined } from "@ant-design/icons";
import { InputNumber, Modal, Select, Switch } from "antd";
import { API } from "aws-amplify";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { POPUP_TYPE } from "../../configs/constants";
import { MyCKEditor } from "../editor/CkEditor";
import { useUtils } from "./../../utils/hooks";
import "./index.scss";

const { Option } = Select;
function QuestionAndAnswerModal(props) {
  const { modalState, setModalState, qnaDetails } = props;
  const { useTraceUpdate } = useUtils();
  useTraceUpdate("QuestionAndAnswerModal", props);
  const { t, i18n } = useTranslation();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shouldDisableQEn, setShouldDisableQEn] = useState(false);
  const [shouldDisableQVi, setShouldDisableQVi] = useState(false);
  const [shouldDisableAEn, setShouldDisableAEn] = useState(false);
  const [shouldDisableAVi, setShouldDisableAVi] = useState(false);

  const getLabel = () => {
    let vi = _.get(modalState, "toBeModify.qna_section.section_vi");
    let en = _.get(modalState, "toBeModify.qna_section.section_en");
    return i18n.language === "vi" ? vi : en;
  };
  const MAX_CHARACTER_QUESTION = 500
  const MAX_CHARACTER_ANSWER = 5000

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 1,
    searchValue: "",
  });

  const fetchData = (params = {}) => {
    setLoading(true);
    API.get(
      "unileverAPI",
      `/qna/sections?currentPage=${params.current - 1}&pageSize=${
        params.pageSize
      }&includeInactive=true&searchValue=${params.searchValue ? params.searchValue : ""}`
    )
      .then((r) => {
        setLoading(false);
        setSections(_.get(r, "result.data"));
      })
      .catch(() => {
        setLoading(false);
        Modal.error({
          centered: true,
          content: t(
            "manager.invoiceStatusMasterData.pendingRequest.fetchDataFailedMsg"
          ),
        });
      });
  };
  useEffect(() => {
    fetchData(pagination);
  }, [pagination]);

  const setQnaSectionObject = (id, key, value) => {
    let newOne = _.get(
      _.filter(sections, (s) => s.uuid === id),
      0
    );

    if (modalState.type === POPUP_TYPE.ADD) {
      // Auto Complete
      let qna_section_id = id;

      let allQnaThatHasSection = _.filter([...qnaDetails], (qna) => {
        return qna.qna_section_id === qna_section_id;
      });

      let allQnaThatHasSection2 = [...allQnaThatHasSection].sort(function (
        a,
        b
      ) {
        return _.get(b, "order_number") - _.get(a, "order_number");
      });

      let max = _.get(allQnaThatHasSection2[0], "order_number", 0);
      setModalState({
        toBeModify: {
          ...modalState.toBeModify,
          order_number: max + 1,
          qna_section_id: id,
          qna_section: newOne,
        },
      });
    } else {
      setModalState({
        toBeModify: {
          ...modalState.toBeModify,
          qna_section_id: id,
          qna_section: newOne,
        },
      });
    }
  };

  function isInt(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  }
  

  const setQnaSection = (key) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, qna_section_id: key },
    });
  const setOrderNumber = (num) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, order_number: num },
    });
  const setIsActive = (bool) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, is_active: bool },
    });
  const setQuestionEn = (text) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, question_en: text },
    });
  const setQuestionVi = (text) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, question_vi: text },
    });
  const setAnswerEn = (text) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, answer_en: text },
    });
  const setAnswerVi = (text) =>
    setModalState({
      toBeModify: { ...modalState.toBeModify, answer_vi: text },
    });

  const shouldDisableOKButton = (modalState) => {
    // is null
    const {
      qna_section_id,
      order_number,
      question_vi,
      question_en,
      answer_vi,
      answer_en,
      is_active,
    } = modalState.toBeModify;
    
    if (!qna_section_id || !order_number || !question_vi || !question_en) {
      return true;
    }

      let flag = true;

    _.each(_.keys(modalState.toBeModify), (key) => {
      //has modify
      if (modalState.toBeModify[key] !== modalState.originData[key]) {
        flag = false;
      }
    });
    return flag;
  };
  return (
    <Modal
      {...props}
      width={"90vw"}
      bodyStyle={{ minHeight: "80vh", maxHeight: "950vh", overflowY: "scroll" }}
      className="preview-invoiceStatus-modal"
      maskClosable={false}
      // footer={modalState.isReadOnly ? null : true}
      cancelText={t('admin.questionAndAnswerModal.cancel')}
      okText={t('admin.questionAndAnswerModal.submit')}
      okButtonProps={{
        disabled: shouldDisableOKButton(modalState) || shouldDisableQEn || shouldDisableQVi || shouldDisableAEn || shouldDisableAVi,
        style: { display: modalState.isReadOnly ? "none" : "inline-block" },
      }}
    >
      <div id="top-div">
        <div className="child-div">
          <div>
            <span className="header-title"> {t('admin.questionAndAnswerList.columns.qna_section_id')} (<span style={{color:'red'}}>*</span>) </span>
            <QnaSectionDropBox
              defaultValue={modalState.toBeModify.qna_section_id}
              loading={loading}
              showSearch={true}
              onSearch={(text, b, c) => {
                setPagination({ ...pagination, searchValue: text });
              }}
              filterOption={(input, option) => {
                return (
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              i18n={i18n}
              sections={sections}
              disabled={modalState.isReadOnly}
              onChange={(val) =>
                setQnaSectionObject(
                  val.value,
                  i18n.language === "vi" ? "section_vi" : "section_en",
                  val.label
                )
              }
              labelInValue={true}
              value={{
                value: modalState.toBeModify.qna_section_id,
                label: getLabel(),
              }}
              pagination={pagination}
              fetchData={fetchData}
            />
          </div>
        </div>
        <div className="child-div">
          <div className="grand-child-div">
            <span className="header-title"> {t('admin.questionAndAnswerList.columns.order_number')} (<span style={{color:'red'}}>*</span>) </span>
            <InputNumber
              min={1}
              type="number"
              stringMode={false}
              value={modalState.toBeModify.order_number}
              style={{ width: "30%" }}
              onChange={(num) => setOrderNumber(num)}
              disabled={modalState.isReadOnly}
            />
          </div>
          <div className="grand-child-div">
            <span className="header-title"> {t('admin.questionAndAnswerList.columns.is_active')} (<span style={{color:'red'}}>*</span>)</span>
            <Switch
              disabled={
                modalState.isReadOnly 
                // || _.get(modalState, "toBeModify.qna_section.is_active") === 0
              }
              isReadOnly={modalState.isReadOnly}
              checked={modalState.toBeModify.is_active}
              onChange={() => setIsActive(!modalState.toBeModify.is_active)}
            />
          </div>
        </div>
      </div>
      <div id="editor">
        <div id="en" className="child-section">
          <div id="ques-en">
            <span className="header-title"> {t('admin.questionAndAnswerList.columns.question_en')} (<span style={{color:'red'}}>*</span>) </span>
            <MyEditor
              present={modalState.isOpen}
              workingDocId={modalState.toBeModify.uuid}
              maxCharacters={MAX_CHARACTER_QUESTION}
              height={"200px"}
              setModel={setQuestionEn}
              model={modalState.toBeModify.question_en}
              readOnly={modalState.isReadOnly}
              setShouldDisable={setShouldDisableQEn}
            />
          </div>
          <div id="ans-en" className="mt20">
            <span className="header-title">{t('admin.questionAndAnswerList.columns.answer_en')} </span>
            <MyEditor
              present={modalState.isOpen}
              workingDocId={modalState.toBeModify.uuid}
              maxCharacters={MAX_CHARACTER_ANSWER}
              height={"400px"}
              setModel={setAnswerEn}
              model={modalState.toBeModify.answer_en}
              readOnly={modalState.isReadOnly}
              setShouldDisable={setShouldDisableAEn}
            />
          </div>
        </div>
        <div id="vn" className="child-section">
          <div id="ques-vn">
            <span className="header-title">{t('admin.questionAndAnswerList.columns.question_vi')} (<span style={{color:'red'}}>*</span>) </span>
            <MyEditor
              present={modalState.isOpen}
              workingDocId={modalState.toBeModify.uuid}
              maxCharacters={MAX_CHARACTER_QUESTION}
              height={"200px"}
              setModel={setQuestionVi}
              model={modalState.toBeModify.question_vi}
              readOnly={modalState.isReadOnly}
              setShouldDisable={setShouldDisableQVi}
            />
          </div>
          <div id="ans-vn" className="mt20">
            <span className="header-title">{t('admin.questionAndAnswerList.columns.answer_vi')}</span>
            <MyEditor
              present={modalState.isOpen}
              workingDocId={modalState.toBeModify.uuid}
              maxCharacters={MAX_CHARACTER_ANSWER}
              height={"400px"}
              setModel={setAnswerVi}
              model={modalState.toBeModify.answer_vi}
              readOnly={modalState.isReadOnly}
              setShouldDisable={setShouldDisableAVi}
            />
          </div>
        </div>
      </div>
    <p style={{color:'red'}}>* {t('admin.questionAndAnswerModal.note1')}</p>
    </Modal>
  );
}

const MyEditor = (props) => {
  return props.present ? <MyCKEditor {...props} tag="textarea" /> : "";
};
const QnaSectionDropBox = (props) => {
  const children = _.uniqBy(props.sections, "uuid").map((el) => {
    return (
      <Option key={el.uuid} value={el.uuid} style={{color :el.is_active == 0 ?'#bdb8b8' :'none'}} >
        {props.i18n.language === "vi" ? el.section_vi : el.section_en}
      </Option>
    );
  });
  return (
    <Select {...props} size={"default"} style={{ width: 200 }}>
      {props.loading && <LoadingOutlined />}
      {children}
    </Select>
  );
};

export default QuestionAndAnswerModal;
