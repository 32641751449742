import { Modal, Table } from 'antd'
import React, { useState, useEffect } from 'react'
import { Helpers } from '../../../../utils'
import ColumnItem from './columnItem'
import { v4 as uuidv4 } from 'uuid'
import './uploadInvoiceStatus.scss'
import { useTranslation } from 'react-i18next';

function PreviewModal(props) {
  const { newInvoiceStatusData, invoiceStatusForUpdatedData, availableNewInvoiceStatusData, availableInvoiceStatusForUpdatedData } = props
  const { t, i18n } = useTranslation()

  const columns = [
    {
      title: t('admin.uploadInvoiceStatus.previewModal.columns.systemLanguage'),
      key: 'sys_lang',
      fixed: 'left',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'sys_lang')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoiceStatus.previewModal.columns.vietnamese'),
      key: 'vi',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'vi')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoiceStatus.previewModal.columns.english'),
      key: 'en',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellData(record, 'en')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    }
  ]

  return (
    <Modal
      {...props}
      title={t('admin.uploadInvoiceStatus.previewModal.title')}
      width={'80vw'}
      className="preview-invoiceStatus-modal"
      okButtonProps={{ disabled: availableNewInvoiceStatusData.concat(availableInvoiceStatusForUpdatedData).length == 0 }}
      okText={t('admin.uploadInvoiceStatus.previewModal.yesBtn')}
      cancelText={t('admin.uploadInvoiceStatus.previewModal.noBtn')}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="header-confirmation-msg">
        {t('admin.uploadInvoiceStatus.previewModal.confirmHeaderMsg')}
      </div>
      <Table
        columns={columns}
        dataSource={newInvoiceStatusData.concat(invoiceStatusForUpdatedData)}
        className="preview-invoiceStatus-table"
        rowClassName={(record, index) =>
          record.status === 'pending-update'
            ? 'updated-invoiceStatus-row'
            : 'new-invoiceStatus-row'
        }
        rowKey={() => uuidv4()}
        pagination={{
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        scroll={{ y: '50vh' }}
      />
      <div className="incorrect-records-msg">
        {t('admin.uploadInvoiceStatus.previewModal.invalidFormatMsg')}
      </div>
    </Modal>
  )
}

export default PreviewModal
