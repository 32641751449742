import { SearchOutlined } from "@ant-design/icons";
import "@ckeditor/ckeditor5-image/theme/imagestyle.css";
import { Affix, Button, Divider, Input, Modal, PageHeader, Space } from "antd";
import { API } from "aws-amplify";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorHandlers } from "../../utils/index";
import AddSectionModal from "./addSectionModal";
import "./questionAndAnswer.scss";
import SectionsTable from "./sectionsTable";

function isNormalInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

function QASections() {
  const { t, i18n } = useTranslation();
  const [addSectionModalVisible, setAddSectionModalVisible] = useState({
    isOpen: false,
    order: 1,
  });
  const [listSections, setListSections] = useState([]);
  const [maxOrder, setMaxOrder] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const refTableSection = useRef(null);

  const resetPaging = () => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 10,
    });
  };

  useEffect(() => {
    getListSection(pagination);
  }, []);

  const handleSearch = _.debounce((e) => {
    const { value } = e.target
    if(value.length >= 3 || value == '') {
      setSearchParam(value)
      getListSection({
        ...pagination,
        current: 1,
      }, value)
      resetPaging();
    }
  }, 500)

  const handleAddSectionModalShow = () => {
    setAddSectionModalVisible({
      ...addSectionModalVisible,
      isOpen: true,
      order: maxOrder + 1,
    });
    refTableSection.current.handleCancelEdit();
  };

  const handleAddSectionModalCancel = () => {
    setAddSectionModalVisible({ ...addSectionModalVisible, isOpen: false });
  };

  const handlePaginationChange = (newPagination) => {
    const newPaginationPayload = {
      ...pagination,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    };
    setPagination(newPaginationPayload);
    if(searchParam){
      getListSection(newPaginationPayload, searchParam);
    } else {
      getListSection(newPaginationPayload);
    }
  };

  const getListSection = (pagination, searchValue) => {
    setLoadingTable(true);
    API.get("unileverAPI", "/qna/sections", {
      queryStringParameters: {
        currentPage: pagination.current - 1,
        pageSize: pagination.pageSize,
        includeInactive: true,
        ...(searchValue && { searchValue: searchValue })
      },
    })
      .then((res) => {
        setLoadingTable(false);
        setListSections(res.result.data);
        setPagination({ ...pagination, total: res.result.total });
        setMaxOrder(res.result.maxOrder)
      })
      .catch((error) => {
        setLoadingTable(false);
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const onAddNewSection = (payload) => {
    API.post("unileverAPI", "/qna/section", { body: payload })
      .then((res) => {
        handleAddSectionModalCancel();
        getListSection({
          ...pagination,
          current: 1,
        });
        resetPaging();
        Modal.success({
          centered: true,
          content: t("admin.qna.addSection.addSectionSuccessfullyMsg"),
        });
      })
      .catch((error) => {
        handleAddSectionModalCancel();
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  const onUpdateSection = (sectionID, payload) => {
    const payloadSection = payload.section
    const updatedPayload = isNormalInteger(payloadSection.order_number) ? payload : {
      section: {
        ..._.omit(payload.section, "order_number")
      }
    }
    API.put("unileverAPI", `/qna/section/${sectionID}`, { body: updatedPayload })
      .then((res) => {
        resetPaging();
        getListSection({
          ...pagination,
          current: 1,
        });
        Modal.success({
          centered: true,
          content: t("admin.qna.updateSection.updateSectionSuccessfullyMsg"),
        });
      })
      .catch((error) => {
        const errorCode = _.get(error, "response.data.errorCode", undefined);
        const errorMessage = errorCode
          ? ErrorHandlers.generateErrorMessage(
              error,
              t(`admin.errorCode.qna.${errorCode}`)
            )
          : _.get(error, "message", t("admin.qna.addSection.errorMsg"));
        Modal.error({
          centered: true,
          content: errorMessage,
        });
      });
  };

  return (
    <div className="site-page-header-ghost-wrapper qna-section-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t("admin.qna.title")}
        ></PageHeader>
      </Affix>
      <Divider />
      <Input
        size="large"
        placeholder={t('admin.qna.search.placeholder')}
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        allowClear
      />
      <div>
        <Space direction="horizontal" align="end" className="action-buttons">
          <Button
            onClick={handleAddSectionModalShow}
            size="large"
            type="primary"
            style={{ minWidth: "100px" }}
            disabled={loadingTable}
          >
            {t("admin.qna.addBtn")}
          </Button>
        </Space>
        <SectionsTable
          data={listSections}
          loading={loadingTable}
          pagination={pagination}
          onPaginationChangeCb={handlePaginationChange}
          onUpdateSectionCb={onUpdateSection}
          ref={refTableSection}
        />
        <AddSectionModal
          visible={addSectionModalVisible.isOpen}
          data={addSectionModalVisible}
          onCancelCb={handleAddSectionModalCancel}
          onSubmitCb={onAddNewSection}
        />
      </div>
    </div>
  );
}

export default QASections;
