import React, { useRef, useState } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Form, Field } from "formik";

import { AntInput, AntSwitch } from "./createAntFields";

function isNormalInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

const AddSectionForm = ({ handleSubmit, values, submitCount }) => {
  const { t, i18n } = useTranslation();

  const validateSectionEnName = (value) => {
    let errors;
    if (!value || value.trim().length == 0) {
      errors = t("admin.qna.addSectionModal.requiredMsg");
    }

    return errors;
  };

  const validateSectionVnName = (value) => {
    let errors;
    if (!value || value.trim().length == 0) {
      errors = t("admin.qna.addSectionModal.requiredMsg");
    }

    return errors;
  };

  const validateOrder = (value) => {
    let errors;
    if (!value || value.length == 0) {
      errors = t("admin.qna.addSectionModal.requiredMsg");
    } else if (!isNormalInteger(value)) {
      errors = t("admin.qna.addSectionModal.isNotNumberMsg");
    }
    return errors;
  };

  return (
    <Form className="form-container" onSubmit={handleSubmit}>
      <Field
        component={AntInput}
        name="qnaSectionEnName"
        type="text"
        label={t("admin.qna.addSectionModal.qnaEnName")}
        validate={validateSectionEnName}
        submitCount={submitCount}
        hasFeedback
        maxLength={500}
      />
      <Field
        component={AntInput}
        name="qnaSectionVnName"
        type="text"
        label={t("admin.qna.addSectionModal.qnaVnName")}
        validate={validateSectionVnName}
        submitCount={submitCount}
        hasFeedback
        maxLength={500}
      />
      <Field
        component={AntInput}
        name="order"
        type="text"
        label={t("admin.qna.addSectionModal.order")}
        validate={validateOrder}
        submitCount={submitCount}
        hasFeedback
      />
      <Field
        component={AntSwitch}
        name="isActiveSection"
        label={t("admin.qna.addSectionModal.active")}
      />
    </Form>
  );
};

function AddSectionModal(props) {
  const { t, i18n } = useTranslation();
  const formikRef = useRef();

  const initialValues = {
    qnaSectionEnName: "",
    qnaSectionVnName: "",
    order: props.data.order,
    isActiveSection: false,
  };

  const handleSubmit = (formProps) => {
    const { order, qnaSectionEnName, qnaSectionVnName, isActiveSection } =
      formProps;
    const newSectionPayload = {
      section: {
        order_number: parseInt(order),
        section_vi: qnaSectionVnName,
        section_en: qnaSectionEnName,
        is_active: isActiveSection ? 1 : 0,
      },
    };
    props.onSubmitCb(newSectionPayload);
    setTimeout(() => {
      formikRef.current?.resetForm();
    }, 500);
  };

  const handleOkClick = () => {
    formikRef.current?.handleSubmit();
  };

  const handleCancelClick = () => {
    formikRef.current?.resetForm();
    props.onCancelCb();
  };
  return (
    <Modal
      {...props}
      destroyOnClose={true}
      width={"70vw"}
      title={t("admin.qna.addSectionModal.title")}
      onOk={handleOkClick}
      okText={t('admin.qna.addSectionModal.addBtn')}
      cancelText={t('admin.qna.addSectionModal.cancelBtn')}
      onCancel={handleCancelClick}
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={AddSectionForm}
      />
      <div className="incorrect-records-msg">
        {t('admin.qna.addSectionModal.note1')}
      </div>
    </Modal>
  );
}

export default AddSectionModal;
