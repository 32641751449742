import { createSlice } from '@reduxjs/toolkit'

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    theme: 'light',
  },
  reducers: {
  },
})

// Action creators are generated for each case reducer function
export const { } = settingSlice.actions

export default settingSlice.reducer