import { Modal, Table } from 'antd'
import React from 'react'
import { Helpers } from '../../../../utils'
import ColumnItem from './columnItem'
import { v4 as uuidv4 } from 'uuid'
import './uploadInvoice.scss'
import { useTranslation } from 'react-i18next';
import moment from 'moment'

const hasError = invoice => Object.values(invoice).filter(item => typeof item === 'object').filter(item => item.validate === "error").length > 0;
function PreviewModal(props) {
  const { newInvoiceData, invoiceForUpdatedData, availableNewInvoiceData, availableInvoiceForUpdatedData } = props
  const { t, i18n } = useTranslation()
  const invoiceErrorForNew = newInvoiceData.filter(hasError) || [];
  const invoiceErrorForUpdate = invoiceForUpdatedData.filter(hasError) || [];
  const data = invoiceErrorForNew.concat(invoiceErrorForUpdate).concat(newInvoiceData.filter(i => !hasError(i))).concat(invoiceForUpdatedData.filter(i => !hasError(i)))
  const columns = [
    {
      title: t('admin.uploadInvoice.previewModal.columns.vendorId'),
      key: 'vendor_id',
      fixed: 'left',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'vendor_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
          <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.vendorName'),
      key: 'vd_name',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'vd_name')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.vendorTaxCode'),
      key: 'vd_tax_code',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'vd_tax_code')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.invoiceNumber'),
      key: 'invoice_number',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'invoice_number')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.scanIdOrReceivedDate'),
      key: 'scan_id',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'scan_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.invoiceDate'),
      key: 'invoice_date',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'invoice_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={moment(cellData.displayValue, 'MM/DD/YYYY').format('DD/MM/YYYY')} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.dueDate'),
      key: 'due_date',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'due_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={moment(cellData.displayValue, 'MM/DD/YYYY').format('DD/MM/YYYY')} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.invoiceAmount'),
      key: 'invoice_amount',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'invoice_amount')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.currency'),
      key: 'currency',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'currency')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.assignedTo'),
      key: 'assigned_to',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'assigned_to')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.invoiceStatus'),
      key: 'invoice_status_id',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'invoice_status_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.reason'),
      key: 'invoice_reason_id',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'invoice_reason_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.statusDate'),
      key: 'status_date',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'status_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={moment(cellData.displayValue, 'MM/DD/YYYY HH:mm:ss A').format('DD/MM/YYYY')} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.poNumber'),
      key: 'po_number',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'po_number')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    },
    {
      title: t('admin.uploadInvoice.previewModal.columns.notes'),
      key: 'notes',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataOfPreviewInvoice(record, 'notes')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} type={record.status} />
          ),
        }
      },
    }
  ]

  return (
    <Modal
      {...props}
      title={t('admin.uploadInvoice.previewModal.title')}
      width={'80vw'}
      className="preview-invoice-modal"
      okButtonProps={{ disabled: availableNewInvoiceData.concat(availableInvoiceForUpdatedData).length == 0 }}
      okText={t('admin.uploadInvoice.previewModal.yesBtn')}
      cancelText={t('admin.uploadInvoice.previewModal.noBtn')}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="header-confirmation-msg">
        {t('admin.uploadInvoice.previewModal.confirmHeaderMsg')}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        className="preview-invoice-table"
        rowClassName={(record, index) =>
          record.status === 'pending-update'
            ? 'updated-invoice-row'
            : 'new-invoice-row'
        }
        rowKey={() => uuidv4()}
        pagination={{
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        scroll={{ y: '50vh' }}
      />
      <div className="incorrect-records-msg">
        {t('admin.uploadInvoice.previewModal.invalidFormatMsg')}
      </div>
    </Modal>
  )
}

export default PreviewModal
