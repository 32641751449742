import { Affix, Descriptions, Divider, PageHeader, Radio } from "antd";
import React from "react";
import { useTranslation } from 'react-i18next';

function Settings({language, updateLanguage}) {
  const { t, i18n } = useTranslation()

  return (
    <div className="site-page-header-ghost-wrapper">
      <Affix offsetTop={0}>
        <PageHeader
          style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
          ghost={false}
          title={t('admin.settings.title')}
          subTitle=""
        ></PageHeader>
      </Affix>
      <Divider />
      <Descriptions size="middle" title="" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        <Descriptions.Item label={t('admin.settings.languageLabel')}>
          <Radio.Group size="small" buttonStyle="solid" value={language} onChange={(e) => updateLanguage(e.target.value)}>
            <Radio.Button key="en" value="en">
              {t('admin.settings.englishLabel')}
            </Radio.Button>
            <Radio.Button key="vn" value="vi">
              {t('admin.settings.vietnameseLabel')}
            </Radio.Button>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default Settings;
