export const POPUP_TYPE = {
  NONE: "",
  ADD: "add",
  EDIT: "edit",
  READ: "read",
};

export const S3_TEMP_BASE ='6d6f16d1de2948a7b49972dd8bc0d3cd_TEMP';
export const S3_MAIN_BASE = '101e68ca31cc47f4b3c7d299c07511c3_MAIN';
export const CUSTOM_SET_STATE = {
  TOGGLE: "toggle",
};

export const defaultDetailRecord = {
  uuid: null,
  order_number: 0,
  qna_section_id: null,
  question_vi: "",
  question_en: "",
  answer_vi: "",
  answer_en: "",
  is_active: true,
  created_date: null,
  updated_date: null,
};
export const defaultModalState = {
  isOpen: false,
  isReadOnly: false,
  originData: defaultDetailRecord,
  toBeModify: defaultDetailRecord,
  type: POPUP_TYPE.NONE,
};

export let colInfos = [
  {
    dataKeyVi: "order_number",
    dataKeyEn: "order_number",
    name: "order_number",
    width: "120px",
  },
  {
    dataKeyVi: "qna_section.section_vi",
    dataKeyEn: "qna_section.section_en",
    name: "qna_section_id",
    width: 300,
  },
  {
    dataKeyVi: "question_en",
    dataKeyEn: "question_en",
    name: "question_en",
    width: 300,
  },
  {
    dataKeyVi: "question_vi",
    dataKeyEn: "question_vi",
    name: "question_vi",
    width: 300,
  },
  {
    dataKeyVi: "answer_en",
    dataKeyEn: "answer_en",
    name: "answer_en",
    width: 400,
  },
  {
    dataKeyVi: "answer_vi",
    dataKeyEn: "answer_vi",
    name: "answer_vi",
    width: 400,
  },
  {
    dataKeyVi: "is_active",
    dataKeyEn: "is_active",
    name: "is_active",
    width: 80,
  },
  { dataKeyVi: "edit", dataKeyEn: "edit", name: "edit", width: 80 },
  { dataKeyVi: "view", dataKeyEn: "view", name: "view", width: 80 },
];
