import _ from 'lodash'

const ErrorHandlers = {
  generateErrorMessage: (error, errorTemplateMsg) => {
    try {
      if(errorTemplateMsg) {
        if(_.get(error, 'response.data.params', []).length > 0){
          const errorParamsArr = _.get(error, 'response.data.params')
          errorParamsArr.map(e => {
            errorTemplateMsg = errorTemplateMsg.replace('{}', e)
          })
          return errorTemplateMsg
        }
        return errorTemplateMsg
      }
      return _.get(error, 'response.data.message', 'Something went wrong')
    } catch (error) {
      return JSON.stringify(error)
    }
  },
}

export default ErrorHandlers