import { Modal, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { Helpers } from './../../utils'
// import './uploadMissingInvoiceRequest.scss'

function ColumnItem(props) {
  const { cellData } = props

  return <div>{cellData}</div>
}

function HistoryModal(props) {
  const { historyData } = props
  const { t, i18n } = useTranslation()

  const columns = [
    {
    title: t('vendor.uploadMissingInvoice.previewModal.columns.version'),
      key: 'version',
      fixed: 'left',
      width: 80,
      render(text, record) {
        const cellData = Helpers.generateCellDataHistory(record, 'version')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem 
              cellData={cellData.displayValue} 
              //  
              />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.scan_id'),
      key: 'scan_id',
      width: 150,
      render(text, record) {
        const cellData = Helpers.generateCellDataHistory(record, 'scan_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}/>
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_status'),
      key: 'invoice_status_id',
      width: 150,
      render(text, record) {
        const cellData = Helpers.generateCellDataHistory(record, 'invoice_status', i18n.language ==='en'? 'en':'vi')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_reason'),
      key: 'invoice_reason_id',
      width: 150,
      render(text, record) {
        const cellData = Helpers.generateCellDataHistory(record, 'invoice_reason', i18n.language ==='en'? 'en':'vi')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.notes'),
      key: 'notes',
      width: 150,
      render(text, record) {
        const cellData = Helpers.generateCellDataHistory(record, 'notes')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.updated_date'),
      key: 'updated_date',
      width: 150,
      render(text, record) {
        const cellData = Helpers.generateCellDataHistory(record, 'updated_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    }

  ]
  

  return (
    <Modal
      {...props}
      width={'70vw'}
      bodyStyle={{minHeight: '50vh', maxHeight: '70vh', overflowY: 'scroll'}}
      className="preview-invoiceStatus-modal"
      footer={null}
      maskClosable={false}
    >
      <Table
        style={{marginTop: '30px'}}
        columns={columns}
        dataSource={historyData}
        rowKey={() => uuidv4()}
        pagination={{
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        scroll={{ y: '100vh' }}
      />
    </Modal>
  )
}

export default HistoryModal
