import axios from 'axios'
import * as AxiosLogger from 'axios-logger'

import URLs from '../configs/urls'

class AxiosServices {
  constructor() {
    const instance = axios.create({
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      }
    })
    instance.interceptors.request.use(AxiosLogger.requestLogger)
    instance.interceptors.response.use(this._handleSuccess, this._handleError)
    this.instance = instance
  }

  _handleSuccess(response) {
    return response.data.data
  }

  _handleError(error) {
    if (!error.response) {
      return Promise.reject({ response: { data: { error: { code: 'networkError' } } } })
    } if (error.response.status == 502) {
      return Promise.reject({ response: { data: { error: { code: 'serverError' } } } })
    }
    if (!error.response.data.error.code) {
      return Promise.reject({ response: { data: { error: { code: 123 } } } })
    }

    return Promise.reject(error)
  }

  getMethod = async (endPoint) => {
    const url = `${URLs.BASE_URL}${URLs.ENV}${endPoint}`
    return this.instance.get(url)
  }

  postMethod = async (endPoint, body = {}) => {
    const url = `${URLs.BASE_URL}${URLs.ENV}${endPoint}`
    return this.instance.post(url, body)
  }

  putMethod = async (endPoint, body = {}) => {
    const url = `${URLs.BASE_URL}${URLs.ENV}${endPoint}`
    return this.instance.put(url, body)
  }
}

export default new AxiosServices()
