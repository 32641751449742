import { AuthState } from '@aws-amplify/ui-components';
import { Button, Form, Input } from 'antd';
import React from 'react';
const CustomCognitoRequestForgotPassword = ({user, onSubmitUserSAP,setAuthState, setFormState, onSubmitCode, formState, formStates, loading}) => {

  const [form] = Form.useForm();

  

  return (
    <>
        <Form
        className="section" role="application"
        form={form}
        name="email-verify"
        onFinish={onSubmitUserSAP}
        style={{
          backgroundColor: '#fff',
          padding: '35px 40px',
          borderRadius: '6px',
          width: '460px',
        }}
        layout="vertical"
        scrollToFirstError>
        <div className="form-section-header">
          <h1 className="header" style={{textAlign: 'center', 'marginBottom': '20px'}}> <span style={{fontSize: '45px'}}> Unilever </span> <br/> <span style={{fontSize: '25px','display': 'block', 'marginTop' : '-15px'}}>Vendor Portal</span></h1>
          <h2 className="header" style={{textAlign: 'center', 'marginBottom': '20px'}}>Forgot your Password / Quên Mật Khẩu</h2>
          <h3 className="header" style={{textAlign: 'center'}} data-test="form-section-header-section">
          Please enter your User ID. An email notification with a password reset link will be sent to your registered email address.
          <br />
          Vui lòng nhập tên tài khoản. Một email có chứa đường dẫn thiết lập lại mật khẩu sẽ được gửi đến địa chỉ email đã đăng kí của bạn.
          </h3>  
          <div className="subtitle"></div>
        </div>
        <Form.Item 
          name="usersap" 
          label="Enter User ID/ Nhập tên tài khoản"
          rules={[
            {
              required: true,
              message: 'This field cannot be blank ! Trường này không được bỏ trống !',
            }
          ]}
          style={{textAlign:'center'}}>
        <Input style={{marginRight: '10px'}} placeholder="" />
        </Form.Item>
      <Form.Item shouldUpdate style={{textAlign:'center'}}>
          {()=> (
            <Button 
            disabled={
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
            }
            loading={loading} type="primary" size="large" htmlType="submit" style={{minWidth: '153px'}}>
            Submit/ Gửi 
          </Button>
          )}
        </Form.Item>
      <div style={{textAlign: 'center'}}>
        <a onClick={() => {
          setFormState(formStates.onSignIn)
          setAuthState(AuthState.signIn)
          }}>Back to Login/ Quay lại Đăng Nhập</a></div>
      </Form>
    </>
  )
}

export default CustomCognitoRequestForgotPassword