import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    username: null,
    language: null,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUsername, setLanguage } = authSlice.actions

export default authSlice.reducer