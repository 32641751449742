import { AuditOutlined, DatabaseOutlined, DollarOutlined, ExceptionOutlined, QuestionCircleOutlined, SearchOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

function LeftMenu() {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const getOpenKeys = (pathname) => {
    switch (pathname) {
      case '/': return ['masterdata', 'vendors'];
      case '/vendors/pending-requests': return ['masterdata', 'vendors'];
      case '/vendors/requests': return ['masterdata', 'vendors'];
      case '/invoice/status/pending-requests': return ['masterdata', 'invoiceStatus'];
      case '/invoice/status/requests': return ['masterdata', 'invoiceStatus'];
      case '/invoice/reason/pending-requests': return ['masterdata', 'invoiceReason'];
      case '/invoice/reason/requests': return ['masterdata', 'invoiceReason'];
      default: return [];
    }
  }

  return (
    <>
      <div className="logo" />
      <Menu
        inlineIndent="12"
        theme="dark"
        selectedKeys={[location.pathname == '/' ? '/vendors/pending-requests' : location.pathname]}
        defaultOpenKeys={getOpenKeys(location.pathname)}
        mode="inline"
      >
        <Menu.SubMenu
          key="questionAndAnswer"
          icon={<QuestionCircleOutlined />}
          title={`${t('admin.leftMenu.questionAndAnswer')}`}
        >
          <Menu.Item
            onClick={() => history.push('/qna/section')}
            key="/qna/section"
          >
            {t('admin.leftMenu.qaSection')}
          </Menu.Item>
          <Menu.Item
            onClick={() => history.push('/qna/list')}
            key="/qna/list"
          >
            {t('admin.leftMenu.qaList')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="/invoice/tracking" icon={<DollarOutlined />} onClick={() => window.location.href = '/invoice/tracking'}>
          {t('manager.leftMenu.invoiceTracking')}
        </Menu.Item>
        <Menu.Item key="/invoice/missing" icon={<SearchOutlined />} onClick={() => window.location.href = '/invoice/missing'}>
          {t('manager.leftMenu.invoiceMissing')}
        </Menu.Item>
        <Menu.SubMenu
          key="masterdata"
          icon={<DatabaseOutlined />}
          title={`${t('manager.leftMenu.masterDataManagement')}`}
        >
          <Menu.SubMenu
            key="vendors"
            icon={<UserOutlined />}
            title={`${t('manager.leftMenu.vendorMasterData')}`}
          >
            <Menu.Item
              onClick={() => history.push('/vendors/pending-requests')}
              key="/vendors/pending-requests"
            >
              {t('manager.leftMenu.vendorMasterData.pendingRequest')}
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push('/vendors/requests')}
              key="/vendors/requests"
            >
              {t('manager.leftMenu.vendorMasterData.allRequest')}
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="invoiceStatus"
            icon={<AuditOutlined />}
            title={`${t('manager.leftMenu.invoiceStatusMasterData')}`}
          >
            <Menu.Item
              onClick={() => history.push('/invoice/status/pending-requests')}
              key="/invoice/status/pending-requests"
            >
              {t('manager.leftMenu.invoiceStatusMasterData.pendingRequest')}
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push('/invoice/status/requests')}
              key="/invoice/status/requests"
            >
              {t('manager.leftMenu.invoiceStatusMasterData.allRequest')}
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="invoiceReason"
            icon={<ExceptionOutlined />}
            title={`${t('manager.leftMenu.invoiceReasonMasterData')}`}
          >
            <Menu.Item
              onClick={() => history.push('/invoice/reason/pending-requests')}
              key="/invoice/reason/pending-requests"
            >
              {t('manager.leftMenu.invoiceReasonMasterData.pendingRequest')}
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push('/invoice/reason/requests')}
              key="/invoice/reason/requests"
            >
              {t('manager.leftMenu.invoiceReasonMasterData.allRequest')}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu.SubMenu>
        <Menu.SubMenu
            key="userMananagement"
            icon={<UserOutlined />}
            title={`${t('manager.leftMenu.userManagement')}`}
        >
          <Menu.Item
            onClick={() => history.push('/user/vendor/pending-email')}
            key="/user/vendor/pending-email"
            title={t('manager.leftMenu.userManagement.vendorEmailPendingList')}
          >
            {t('manager.leftMenu.userManagement.vendorEmailPendingList')}
          </Menu.Item>
          <Menu.Item
            onClick={() => history.push('/user/apadmin')}
            key="/user/apadmin"
          >
            {t('manager.leftMenu.userManagement.apadminAccount')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="/settings" icon={<SettingOutlined />} onClick={() => window.location.href = '/settings'}>
          {t('manager.leftMenu.settings')}
        </Menu.Item>
      </Menu>
    </>
  )
}

export default LeftMenu
