import React, { useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  TimePicker,
  Select,
  InputNumber,
  Switch,
  Button,
  Upload,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AntUpload = ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  submitCount,
  type,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  return (
    <div className="field-container attachment-field">
      <FormItem
        label={label}
        {...formItemLayout}
        tooltip={t("vendor.settings.keyEmail.changeModal.attachment.tooltip")}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        }
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? "error" : "success"}
      >
        <Upload
          fileList={fileList}
          onChange={(info) => {
            const file = info.file;
            const fileType = file.type
            const overLimitSize = file.size / 1024 > 1024;
            if(!(fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/png")){
              Modal.error({
                centered: true,
                content: t('vendor.settings.keyEmail.wrongFileTypeMsg'),
              });
            }
            else if (overLimitSize) {
              Modal.error({
                centered: true,
                content: t(
                  "vendor.settings.keyEmail.changeModal.attachment.overLimit"
                ),
              });
            } else {
              setFileList(info.fileList);
            }
          }}
          beforeUpload={(file) => {
            const fileType = file.type
            const overLimitSize = file.size / 1024 > 1024;
            if((fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/png") && !overLimitSize){
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                form.setFieldValue('attachment', reader.result);
                form.setFieldValue('attachment_name', file.name);
              };
            }
            // Prevent upload
            return false;
          }}
          onRemove={(file) => {
            form.setFieldValue('attachment', undefined);
            form.setFieldValue('attachment_name', undefined);
          }}
          listType="picture"
          maxCount={1}
        >
          <Button>{props.btnText}</Button>
        </Upload>
      </FormItem>
    </div>
  );
};

const CreateAntField =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    type,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { value } }) =>
      form.setFieldValue(field.name, value);
    const onChange = (value) => form.setFieldValue(field.name, value);
    const onBlur = () => form.setFieldTouched(field.name, true);
    return (
      <div className="field-container">
        <FormItem
          label={label}
          {...formItemLayout}
          hasFeedback={
            (hasFeedback && submitted) || (hasFeedback && touched)
              ? true
              : false
          }
          help={submittedError || touchedError ? hasError : false}
          validateStatus={submittedError || touchedError ? "error" : "success"}
        >
          <AntComponent
            {...field}
            {...props}
            onBlur={onBlur}
            onChange={type ? onInputChange : onChange}
          >
            {selectOptions &&
              selectOptions.map((name) => <Option key={name}>{name}</Option>)}
          </AntComponent>
        </FormItem>
      </div>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntSwitch = CreateAntField(Switch);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntButton = CreateAntField(Button);
export { AntUpload };
