import { Modal, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { Helpers } from '../../../../utils'
import ColumnItem from './columnItem'
import './uploadMissingInvoiceRequest.scss'

function PreviewModal(props) {
  const { newInvoiceStatusData, invoiceStatusForUpdatedData, availableNewInvoiceStatusData, availableInvoiceStatusForUpdatedData } = props
  const { t, i18n } = useTranslation()

  const columns = [
    {
    title: t('vendor.uploadMissingInvoice.previewModal.columns.vendor_id'),
      key: 'vendor_id',
      fixed: 'left',
      width: 180,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'vendor_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem 
              cellData={cellData.displayValue} 
              //  
              />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.vendor_name'),
      key: 'vendor_name',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'vendor_name')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}/>
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.vendor_tax_code'),
      key: 'vendor_tax_code',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'vendor_tax_code')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_number'),
      key: 'invoice_number',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'invoice_number')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.scan_id'),
      key: 'scan_id',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'scan_id')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_date'),
      key: 'invoice_date',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'invoice_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.due_date'),
      key: 'due_date',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'due_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_amount'),
      key: 'invoice_amount',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'invoice_amount')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.currency'),
      key: 'currency',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'currency')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.assigned_to'),
      key: 'assigned_to',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'assigned_to')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_status'),
      key: 'invoice_status',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'invoice_status', i18n.language ==='en'? 'en':'vi')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.invoice_reason'),
      key: 'invoice_reason',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'invoice_reason', i18n.language ==='en'? 'en':'vi')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.status_date'),
      key: 'status_date',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'status_date')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.po_number'),
      key: 'po_number',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'po_number')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue}  />
          ),
        }
      },
    },
    {
      title: t('vendor.uploadMissingInvoice.previewModal.columns.notes'),
      key: 'notes',
      width: 200,
      render(text, record) {
        const cellData = Helpers.generateCellDataForMissingInvoice(record, 'notes')
        return {
          props: {
            className: cellData.cellStyle,
          },
          children: (
            <ColumnItem cellData={cellData.displayValue} />
          ),
        }
      },
    }
  ]
  

  return (
    <Modal
      {...props}
      width={'80vw'}
      className="preview-invoiceStatus-modal"
      okButtonProps={{ disabled: availableNewInvoiceStatusData.length == 0 }}
      okText={t('admin.uploadInvoiceStatus.previewModal.yesBtn')}
      cancelText={t('admin.uploadInvoiceStatus.previewModal.noBtn')}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="header-confirmation-msg">
        {t('admin.uploadInvoiceStatus.previewModal.confirmHeaderMsg')}
      </div>
      <Table
        columns={columns}
        dataSource={newInvoiceStatusData.concat(invoiceStatusForUpdatedData)}
        className="preview-invoiceStatus-table"
        rowKey={() => uuidv4()}
        rowClassName={'new-invoiceStatus-row'
        }
        pagination={{
          locale: { items_per_page: "" },
          showTotal: (total) => `${t("total")} ${total}`,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        scroll={{ y: '50vh' }}
      />
      <div className="correct-records-msg">
        <span> {t('vendor.uploadMissingInvoice.previewModal.greenHighlight')}</span> <br />
        {t('vendor.uploadMissingInvoice.previewModal.greenMess')}
      </div>
      <div className="incorrect-records-msg">
      <span> {t('vendor.uploadMissingInvoice.previewModal.redHighlight')}</span> <br />
        {t('vendor.uploadMissingInvoice.previewModal.redMess1')}
      <br/>
        {t('vendor.uploadMissingInvoice.previewModal.redMess2')}
      </div>
    </Modal>
  )
}

export default PreviewModal
