import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Divider, Layout, Modal, Radio } from "antd";
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Link, Route, Switch, useHistory } from "react-router-dom";
import InvoiceMissing from '../../sharedPages/invoiceMissing';
import InvoiceTracking from '../../sharedPages/invoiceTracking';
import QASections from '../../sharedPages/questionAndAnswerSections';
import QuestionAndAnswer from './../../sharedPages/questionAndAnswerDetails';
import './index.css';
import LeftMenu from "./leftMenu";
import NoMatch from "./pages/noMatch";
import Settings from "./pages/settings";
import UploadInvoice from './pages/uploadInvoice';
import UploadInvoiceReason from "./pages/uploadInvoiceReason";
import UploadInvoiceStatus from "./pages/uploadInvoiceStatus";
import UploadVendor from "./pages/uploadVendor";

const browserHistory = createBrowserHistory();
const { Header, Content, Sider } = Layout;

function APAdminView({user, setRole}) {
  const [language, setLanguage] = useState('en');
  const [languageModal, setLanguageModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const { t, i18n } = useTranslation()
  const history = useHistory();
  async function logOut() {
    await Auth.signOut()
    localStorage.clear();
    setRole(undefined)

  }
  
  const updateLanguage = (lang) => {
    return Auth.updateUserAttributes(user, {
        'custom:language': lang,
    })
    .then(() => {
      setLanguage(lang);
      i18n.changeLanguage(lang);
    })
    .catch((err) => {
      let final = "Something went wrong."
      if(_.includes(_.get(err,'message'), 'Access Token has been revoked')) {
        final = t('manager.language.tokenRevoked');
      }
      Modal.error({
        title: 'Error / Lỗi',
        content: <div dangerouslySetInnerHTML={{__html:`${final}`}}/>,
        centered: true,
        maskClosable: true
      });
    });
  }

  useEffect(async() => {
    if(user && user.attributes && user.attributes['custom:language']) {
      i18n.changeLanguage(user.attributes['custom:language']);
      setLanguage(user.attributes['custom:language']);
    } else {
      setLanguageModal(true); 
      updateLanguage('en');
    }
  }, [])

  return (
    <Router>
      <Layout className="apadmin-layout" style={{ minHeight: "100vh" }}>
        <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
          <LeftMenu />
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0, backgroundColor: '#001529' }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: onCollapse,
            })}
            <div style={{float: 'right', color: 'white', paddingRight: 10}}>
              {user.username}
              <Divider type="vertical" style={{ borderLeftColor: 'white' }}/>
              
              <Link to="/">
                <Button
                  style={{ color: 'red', }}
                  type="text"
                  size="large"
                  icon={<LogoutOutlined />}
                  onClick={logOut}
                ></Button>
              </Link>
              
            </div>
            <div style={{ display: 'inline-block', width: '100%' }}>
                <p
                  className="text-overflow-hidden"
                  style={{
                    color: 'white',
                    fontSize: 17,
                    float: 'left',
                    paddingTop: '3px',
                  }}
                >
                  {user.username}
                </p>
                
            </div>
              <p className="text-overflow-hidden" style={{ color: 'grey' }}>
                APAdmin
              </p>
          </Header>
          <Content>
            <div
              className="site-layout-background"
              style={{ padding: '0 24px 24px 24px', minHeight: '100vh' }}
            >
              <Switch>
                <Route exact path="/">
                  <UploadInvoice user={user} />
                </Route>
                <Route exact path="/vendors/upload">
                  <UploadVendor user={user} />
                </Route>
                <Route exact path="/invoice/status/upload">
                  <UploadInvoiceStatus user={user}/>
                </Route>
                <Route exact path="/invoice/reason/upload">
                  <UploadInvoiceReason user={user}/>
                </Route>
                <Route exact path="/qna/section">
                  <QASections />
                </Route>
                <Route exact path="/invoice/tracking">
                  <InvoiceTracking language={language} editTable />
                </Route>
                <Route exact path="/invoice/tracking/upload">
                  <UploadInvoice user={user} />
                </Route>
                <Route exact path="/invoice/missing">
                  <InvoiceMissing language={language} editTable />
                </Route>
                <Route exact path="/qna/list">
                  <QuestionAndAnswer language={language} editTable />
                </Route>
                <Route exact path="/settings">
                  <Settings language={language} updateLanguage={updateLanguage} />
                </Route>
                <Route exact path="/login" render={logOut}>
                </Route>
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </div>
          </Content>
        </Layout>
      </Layout>
      <Modal 
        closable={false}
        title={t('admin.index.selectLanguage.title')}
        visible={languageModal} 
        onOk={() => setLanguageModal(false)} 
        // onCancel={() => setLanguageModal(false)}
        okText={t('admin.index.selectLanguage.yesBtn')}
        // cancelText={t('admin.index.selectLanguage.noBtn')}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
      >
        <Radio.Group size="large" style={{width: '100%'}} buttonStyle="solid" value={language} onChange={(e) => updateLanguage(e.target.value)}>
          <Radio.Button style={{width: '50%', textAlign: 'center'}} key="en" value="en">
            {t('admin.index.chooseLanguage.englishLabel')}
          </Radio.Button>
          <Radio.Button style={{width: '50%', textAlign: 'center' }} key="vn" value="vi">
            {t('admin.index.chooseLanguage.vietnameseLabel')}
          </Radio.Button>
        </Radio.Group>
      </Modal>
    </Router>
  );
}

export default APAdminView;
