import { DownloadOutlined } from '@ant-design/icons'
import { Affix, Button, Divider, Modal, PageHeader, Space } from 'antd'
import { API, Storage } from 'aws-amplify'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropZone from './dropZone'
import PreviewModal from './previewModal'
import './uploadInvoiceReason.scss'

function UploadInvoiceReason({user}) {
  const [fileList, setFileList] = useState([])
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [newInvoiceReasonData, setNewInvoiceReasonData] = useState([])
  const [invoiceReasonForUpdatedData, setInvoiceReasonForUpdatedData] = useState([])
  const [availableNewInvoiceReasonData, setAvailableNewInvoiceReasonData] = useState([])
  const [
    availableInvoiceReasonForUpdatedData,
    setAvailableInvoiceReasonForUpdatedData,
  ] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (fileList.length > 0) {
      handleUpload()
    }
  }, [fileList])

  const handleSelectFile = (files) => {
    setFileList(files)
  }
  
  const submitRequest = () => {
    if (!submitting) {
      setSubmitting(true)
      const request = {
        body: {
          username: user.username,
          type: 'invoice_reason'
        },
      }
      if (availableNewInvoiceReasonData.length > 0) {
        request.body.newItems = availableNewInvoiceReasonData
      }
      if (availableInvoiceReasonForUpdatedData.length > 0) {
        request.body.itemsForUpdated = availableInvoiceReasonForUpdatedData
      }
      return API.post('unileverAPI', '/invoices/status/submitMasterData', request)
        .then(() => {
          Modal.success({
            centered: true,
            content: t('admin.uploadInvoiceReason.submitSuccessfullyMsg'),
          });
          setIsFileUploaded(false)
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          Modal.error({
            centered: true,
            content: t('admin.uploadInvoiceReason.submitFailedMsg'),
          });
        })
    }
  }

  const handleUpload = () => {
    setUploading(true)
    Storage.put(`vendorMasterData/${fileList[0].name}`, fileList[0], {
      contentType: fileList[0].type,
      level: 'public',
    })
      .then((result) => {
        const request = {
          body: {
            bucket: Storage._config.AWSS3.bucket,
            key: `public/${result.key}`,
            type: 'invoice_reason'
          },
        }
        return API.post(
          'unileverAPI',
          '/invoices/status/validateMasterData',
          request
        )
      })
      .then((result) => {
        setFileList([])
        setUploading(false)
        const { newItems, itemsForUpdated } = result.data
        setNewInvoiceReasonData(newItems);
        setInvoiceReasonForUpdatedData(itemsForUpdated);
        let aviNewInvoiceReasonData = [];
        let aviInvoiceReasonForUpdateData = [];
        for (let item of newItems) {
          if (checkValidRow(item)) {
            aviNewInvoiceReasonData.push(item);
          }
        }
        for (let item of itemsForUpdated) {
          if (checkValidRow(item)) {
            aviInvoiceReasonForUpdateData.push(item)
          }
        }
        setAvailableNewInvoiceReasonData(aviNewInvoiceReasonData)
        setAvailableInvoiceReasonForUpdatedData(aviInvoiceReasonForUpdateData)
        setIsFileUploaded(true)
      })
      .catch((err) => {
        setUploading(false)
        Modal.error({
          centered: true,
          content: <div id='dangerhtml' dangerouslySetInnerHTML={{__html:`${getCustomErrorMessage(err)}`}}/>,
        });
      })
  }
  const getCustomErrorMessage = (err) => {
    let message = _.get(err, 'response.data.message');
    let final;
    if (_.includes(message, 'Invalid header value or order of column') ||
    _.includes(message, 'Invalid amount of column')
    ) {
      final = t('admin.uploadInvoiceReason.uploadFailedMsg.wrongHeader');
    }
    else if(_.includes(message, 'duplicate(s) occurred')) {
      message = message.replace('Error: ', '');
      let jsonMes = JSON.parse(message);
      let uniqueDuplicate = _.get(jsonMes, 'uniqueDuplicate');
      
      let list = 
      `<div> 
        <br/>
        ${uniqueDuplicate.map((data, index) => `<strong> ${index+1}. ${ _.get(data, 'sys_lang')} </strong>`).join(' <br/>')}
      </div>`
      final = t('admin.uploadInvoiceReason.uploadFailedMsg.duplicateOccured', {
        list: list,
      });
    }
    else {
      final = t('admin.uploadInvoiceReason.uploadFailedMsg');
    }
    return final
  }
  const checkValidRow = (item) => {
    if (
      ((item.sys_lang && !item.sys_lang.validate) || !item.sys_lang) &&
      ((item.vi &&        !item.vi.validate) || !item.vi) &&
      ((item.en &&     !item.en.validate) || !item.en)
    ) {
      return true
    } else {
      return false
    }
  }
  const downloadTemplate = async () => {
    setDownloadLoading(true)
    let apiName = 'unileverAPI'
    let path = '/invoices/status/download'
    let params = {
      responseType: 'arrayBuffer',
      response: true,
      queryStringParameters: {
        type: 'invoice_reason'
      }
    }
    await API.get(apiName, path, params)
      .then((response) => {
        var raw = window.atob(response.data)
        var uInt8Array = new Uint8Array(raw.length)
        for (var i = 0; i < raw.length; ++i) {
          uInt8Array[i] = raw.charCodeAt(i)
        }
        const link = document.createElement('a')
        const blob = new Blob([uInt8Array], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `InvoiceReasonMasterData_${Date.now()}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setDownloadLoading(false)
      })
      .catch((error) => {
        Modal.error({
          centered: true,
          content: t('admin.uploadInvoiceReason.downloadFailedMsg'),
        });
        setDownloadLoading(false)
      })
  }

  return (
    <div className="uploadInvoiceReason-container">
      <Affix offsetTop={0}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
          ghost={false}
          title={t('admin.uploadInvoiceReason.title')}
          subTitle={t('admin.uploadInvoiceReason.subTitle')}
        ></PageHeader>
      </Affix>
      <Divider />
      <DropZone
        selectFileCallBack={(file) => handleSelectFile(file)}
        isUploading={uploading}
      />
      <Space direction="horizontal" align="end" className="action-buttons">
        <Button
          loading={downloadLoading}
          onClick={downloadTemplate}
          size="large"
          type="primary"
        >
          <DownloadOutlined /> {t('admin.uploadInvoiceReason.downloadTemplateBtn')}
        </Button>
      </Space>
      <PreviewModal
        visible={isFileUploaded}
        onOk={submitRequest}
        onCancel={() => setIsFileUploaded(false)}
        newInvoiceReasonData={newInvoiceReasonData}
        invoiceReasonForUpdatedData={invoiceReasonForUpdatedData}
        availableNewInvoiceReasonData={availableNewInvoiceReasonData}
        availableInvoiceReasonForUpdatedData={availableInvoiceReasonForUpdatedData}
      />
    </div>
  )
}

export default UploadInvoiceReason
